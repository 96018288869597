<template>
    <div v-if="processing" class="w-screen h-screen bg-darken-70 flex flex-col items-center justify-center fixed top-0 left-0 z-14">
        <icon class="text-indigo-300 fill-current" type="process" view-box="0 0 40 40" width="40" height="40" />
        <div class="mt-4 text-xs text-grey-400 uppercase">processing</div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
export default {

    /** Local State */
    computed: {
        /** State Getters */
        ...mapGetters(['processing'])
    },

    /** Lifecycle Events */
    mounted() {
        // Show app is processing.
        this.setProcessing(true)

        // Wait for page to load.
        window.addEventListener("load", event => {
            // Show page is loaded.
            this.setProcessing(false)
        })
    },

    /** Non Reactive Properties */
    methods: {
        /** Store Mutations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING'
        })
    }
}
</script>