// Vue model
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/translucent.css'

// Auth
import DealerCodeCard from './Auth/Cards/DealerCodeCard'
import ListCard from './Auth/Cards/ListCard'
import UserCard from './Auth/Cards/UserCard'
import MarketingCard from './Auth/Cards/MarketingCard'
import VendorSpecificationCard from './Auth/Cards/VendorSpecificationCard'
// Cards
import ArtCard from './Cards/ConnexionsArtCard'
import DesignCard from './Cards/DesignCard'
import BudgetCard from './Cards/BudgetCard'
import LeasingCard from './Cards/LeasingCard'
import MrlCard from './Cards/MrlCard'
import PricingCard from './Cards/PricingCard'
import FlooringCard from './Cards/FlooringCard'
import PromotionsCard from './Cards/PromotionsCard'
import ProspectsCard from './Cards/ProspectsCard'
import ResourcesCard from './Cards/ResourcesCard'
import ProfileCard from './Cards/ProfileCard'
import SpecificationsCard from './Cards/SpecificationsCard'
import SupportCard from './Cards/SupportCard'
import TrainingCard from './Cards/TrainingCard'

// Elements
import EmailLink from './Elements/EmailLink'
import PromotionImage from './Elements/PromotionImage'
import ProspectStatus from './Elements/ProspectStatus'
import ProspectTransfer from './Elements/ProspectTransfer'
import SubmitButton from './Elements/SubmitButton'
import TipTap from './Elements/TipTap'

// Structure
import DashboardHeader from './Structure/DashboardHeader'
import VendorHeader from './Structure/VendorHeader'

// Leasing
import LeasingForm from './Leasing/LeasingForm'

// Link
import LinkCategories from './Link/LinkCategories'
import DraggableLinks from './Link/DraggableLinks'
import ReorderingToggle from './Link/ReorderingToggle'
import LinkRow from './Link/LinkRow'

// Settings
import PricingSettings from './Settings/PricingSettings'

// Log
import LogSelect from './Log/LogSelect'

//Auth
Vue.component('dealer-code-card', DealerCodeCard)
Vue.component('list-card', ListCard)
Vue.component('user-card', UserCard)
Vue.component('marketing-card', MarketingCard)
Vue.component('vendor-specification-card', VendorSpecificationCard)

// Cards
Vue.component('art-card', ArtCard)
Vue.component('design-card', DesignCard)
Vue.component('budget-card', BudgetCard)
Vue.component('leasing-card', LeasingCard)
Vue.component('mrl-card', MrlCard)
Vue.component('pricing-card', PricingCard)
Vue.component('flooring-card', FlooringCard)
Vue.component('prospects-card', ProspectsCard)
Vue.component('promotions-card', PromotionsCard)
Vue.component('resources-card', ResourcesCard)
Vue.component('profile-card', ProfileCard)
Vue.component('specifications-card', SpecificationsCard)
Vue.component('support-card', SupportCard)
Vue.component('training-card', TrainingCard)

// Elements
Vue.component('email-link', EmailLink)
Vue.component('promotion-image', PromotionImage)
Vue.component('prospect-status', ProspectStatus)
Vue.component('prospect-transfer', ProspectTransfer)
Vue.component('submit-button', SubmitButton)
Vue.component('tip-tap', TipTap)

// Help
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

// Leasing
Vue.component('leasing-form', LeasingForm)

// Link
Vue.component('link-categories', LinkCategories)
Vue.component('draggable-links', DraggableLinks)
Vue.component('reordering-toggle', ReorderingToggle)
Vue.component('link-row', LinkRow)

// Log
Vue.component('log-select', LogSelect)

// Settings
Vue.component('pricing-settings', PricingSettings)

// Structure
Vue.component('dashboard-header', DashboardHeader)
Vue.component('vendor-header', VendorHeader)
