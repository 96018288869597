const GroupModule = {
    state: {
        /**
         * The current groups state.
         * 
         * @var {array}
         */
        groups: null,
    },
    getters: {
        /**
         * Get the current groups state. 
         * 
         * @param {array} groups The groups params from state module 
         */
        groups({ groups }) {
            return groups
        },
    },
    actions: {
        /**
         * Fetch all group instances from repository.
         * 
         * @param {object} commit The store commit module
         * @return {void} 
         */
        fetchGroups({ commit }) {
            axios 
                .get('/api/groups')
                .then(response => commit('SET_GROUPS', response.data))
                .catch(errors => console.warn({errors}))
        },
    },
    mutations: {
        /**
         * Mutate the groups state.
         * 
         * @param {object} state The state module from GroupModule
         * @param {array} groups The array of groups
         */
        SET_GROUPS(state, groups) {
            state.groups = groups
        }
    }
}

export default GroupModule