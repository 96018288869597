const ShareModule = {
    state: {

        /**
         * The visitor’s share recipients.
         * 
         * @var {array} 
         */
        shareRecipients: null,
        /**
         * The selected shareable type.
         * 
         * @var {string}
         */
        shareableType: null,
        /**
         * The shareables items.
         * 
         * @var {array} 
         */
        shareables: null,
        /**
         * The selected items to share.
         * 
         * @var {array} 
         */
        selected: [],
    },
    getters: {
        /**
         * Get the shareRecipients state.
         * 
         * @param {array} shareRecipients The shareRecipents state.
         * @return {array} 
         */
        shareRecipients({ shareRecipients }) {
            return shareRecipients
        },

        /**
         * Get the shareableType state.
         * 
         * @param {string} shareableType The shareableType state.
         * @return {string} 
         */
        shareableType({ shareableType }) {
            return shareableType
        },

        /**
         * Get the shareables state.
         * 
         * @param {array} shareables The shareables state.
         * @return {array} 
         */
        shareables({ shareables }) {
            return shareables
        },
        
        /**
         * Get the selected state.
         * 
         * @param {array} selected The selected state.
         * @return {array} 
         */
        selected({ selected }) {
            return selected
        },
    },
    actions: {
        /**
         * Persist a share to the database.
         * 
         * @param {object} obj The store object.
         * @param {object} params The share params.
         */
        storeShare({ commit }, params) {
            axios 
                .post('/shares', params)
                .then(() => {
                    let link = document.createElement('a')
                    link.setAttribute("href", `/favorites?share=true`)
                    link.classList.add("hidden")
                    link.click()
                })
                .catch(errors => {
                    commit('SET_PROCESSING', false)

                    let message = errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to Complete',
                        message, 
                        duration: 5000,
                    })
                })
        },
    },
    mutations: {
        /**
         * Mutate the shareRecipients state.
         * 
         * @param {object} state The store state module.
         * @param {array} shareRecipients The array of recipients.
         */
        SET_SHARE_RECIPIENTS(state, shareRecipients) {
            state.shareRecipients = shareRecipients
        },
        
        /**
         * Mutate the shareType state.
         * 
         * @param {object} state The store state module.
         * @param {string} shareableType The shareable type class.
         */
        SET_SHAREABLE_TYPE(state, shareableType) {
            state.shareableType = shareableType
        },

        /**
         * Mutate the shareables state.
         * 
         * @param {object} state The store state module.
         * @param {array} shareables The array of shareables items.
         */
        SET_SHAREABLES(state, shareables) {
            state.shareables = shareables
        },

        /**
         * Mutate the selected state.
         * 
         * @param {object} state The store state module.
         * @param {array} selected The array of selected items to share.
         */
        SET_SELECTED(state, selected) {
            state.selected = selected
        },
    },
}

export default ShareModule