<template>
    <span :class="classes" @click="sendEmail()">
        <slot></slot>
        <a target="_top" :ref="email" :href="`mailto:${email}?subject=${subject}`" class="hidden absolute bottom-0 right-0"></a>
    </span>
</template>
<script>
export default {
    props: {
        classes: {
            type: String,
            required: false,
            default: 'text-grey-100 hover:opacity-50 cursor-pointer',
        },
        email: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        subject: {
            type: String,
            required: false,
            default: null,
        }
    },

    methods: {
        sendEmail() {
            if (this.email) {
                let el = this.$refs[this.email]
                el.click()
            }
        }
    }
}
</script>