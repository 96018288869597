<template>
    <div class="w-full mb-2 pb-2 border-b border-grey-700 flex justify-center">
        <div    
            v-for="category in parsedCategories" 
            :key="category" 
            @click="sortByCategory(lower(category))" 
            :class="{
                'bg-grey-900 hover:opacity-50 cursor-pointer': slug(selected) !== slug(category),
                'bg-grey-900 opacity-50': slug(selected) === slug(category) 
            }"
            class="my-2 mr-2 text-grey-100 rounded"
        >
            <tip-widget v-if="slug(selected) !== slug(category)" :content="`<span class='text-blue-100'>View</span> ${title(category)} Resources`">
                <div class="px-4 py-2">{{title(category)}}</div>  
            </tip-widget>
            <div v-else class="px-4 py-2">{{title(category)}}</div>  
        </div>
        <form ref="get-category" :action="action" method="GET">
            <input type="hidden" name="category" id="category" v-model="category"/>
            <button name="get-category-submit" type="submit" class="hidden"></button>
        </form>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import TipWidget from '../../Public/Widgets/TipWidget.vue'
export default {
  components: { TipWidget },
    /**
     * Interface
     */
    props: ['categories', 'selected'],
    /**
     * Local State
     */
    data(){
        return {
            action: '/dashboard/links?category=breaking news',
            category: null,
        }
    },
    computed: {
        /**
         * Parse the json encoded categories from the blade view. 
         * 
         * @return {array}
         */
        parsedCategories() {
            if (this.categories) {
                return _.sortBy(JSON.parse(this.categories))
            }
        }
    },
    /**
     * Lifecycle Events
     */
    mounted() {
        /**
         * Mutate the link category state.
         * 
         * @return {void}
         */
        if (this.selected) {
            this.setLinkCategory(this.selected)
            this.category = this.selected
        }
    },
    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Perform the sort operation. 
         * 
         * @param {string} category The category. 
         * @return {void}
         */
        async sortByCategory(category) {
            this.category = category
            await this.setLinkCategory(category)
            await this.setAction(category)
            let form = this.$refs['get-category']
            await form.setAttribute('action', this.action)
            form.submit()
        },

        /**
         * Set the form action to reflect the selected category. 
         * 
         * @param {string} category The form action category. 
         * @return {void}
         */
        setAction(category) {
            this.action = '/dashboard/links?category=' + category
        },

        /**
         * Format the text to title case.
         * 
         * @param {string} value The text to be formatted. 
         * @return {string}
         */
        title(value) {
            return _.startCase(value)
        },

        /**
         * Format the text to lower case.
         * 
         * @param {string} value The text to be formatted. 
         * @return {string}
         */
        lower(value) {
            return value.toLowerCase()
        },

        /**
         * Format the text to lower case.
         * 
         * @param {string} value The text to be formatted. 
         * @return {string}
         */
        slug(value) {
            return _.kebabCase(value)
        },

        /**
         * Store Mutations
         */
        ...mapMutations({
            setLinkCategory: 'SET_LINK_CATEGORY'
        })
    }
}
</script>