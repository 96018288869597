<template>
    <div 
        @click="deleteResource(confirmation)" 
        :class="classes" 
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        :content="tip"
    > 
        <div
            v-if="button"
            class="ml-2 py-2 px-4 rounded"
        >Delete</div>
        <icon 
            v-else
            class="fill-current" 
            type="delete" 
            on="click" 
            mutation="setConfirmation" 
            :params="confirmation" 
            view-box="0 0 24 24" 
            :width="size" 
            :height="size"
        />
    </div>
</template>
<script>
// Store components.
import { mapMutations } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        params: {
            type: String,
            required: false,
            default: null,
        },
        button: {
            type: String|Boolean,
            required: false,
            default: false,
        },
        tip: {
            type: String,
            required: false,
            default: "<span class='text-red-200'>DELETE</span> this item"
        },
        classes: {
            type: String,
            required: false,
            default: "hover:opacity-50 cursor-pointer"
        },
        size: {
            type: String|Number,
            required: false,
            default: 24
        }
    },
    /**
     * Local State
     */
    computed: {
        confirmation() {
            if (this.params) {
                return JSON.parse(this.params)
            }
        },
    },
    /**
     * Non Reactive Properties
     */
    methods: {
        async deleteResource(confirmation) {
            await window.scrollTo(0, 0)
            await this.setConfirmation(confirmation)
        },

        /**
         * Store Mutations
         */
        ...mapMutations({setConfirmation:'SET_CONFIRMATION'})
    }
}
</script>