<template>
    <div class="w-full h-1/2 bg-grey-900 rounded">
        <div class="h-full p-6 text-grey-100 flex flex-col justify-end">
            <div class="w-full flex ">
                <div class="w-full flex flex-col justify-end">
                    <!-- <label for="status" class="text-xs text-grey-600">Select Dealer</label>  -->
                    <div class="w-full relative">
                        <select
                            v-model="list"
                            @change="downloadList()"
                            class="block appearance-none w-full px-2 py-1 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded"
                        >
                            <option value="select">Select&hellip;</option>
                            <option value="dealers">Dealer List (PDF)</option>
                            <option value="vendors">Vendor List (PDF)</option>
                        </select>
                        <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                            <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-100 relative z-4" >Lists</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100 flex">Download Member List</div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapMutations } from 'vuex'
export default {

    /** Local State */
    data() {
        return {
            list: 'select'
        }
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Handle list request.
         *
         * @return {void}
         */
        downloadList() {
            if (this.list !== 'select') {

                this.setNotification({
                    type: 'primary',
                    title: 'Downloading…',
                    message: 'Please wait while we process your request.',
                    duration: 30000
                })

                setTimeout(() => {
                    window.location = `/dashboard/downloads/pdf/members/${this.list}`
                })
            }
        },

        /** Store Mutations */
        ...mapMutations({setNotification: 'SET_NOTIFICATION'})
    }
}
</script>
