<template>
    <transition name="panel" mode="out-in">
        <div 
            v-show="panel === 'products'" 
            @click="setPanel(null)" 
            class="w-screen h-screen md:h-80 mt-24 leading-normal bg-neutral-10 border-t border-neutral-30 flex flex-col md:flex-row justify-start md:justify-between shadow-md absolute top-0 z-16"
        >
            <!-- Close button for mobile -->
            <div class="w-screen p-1 flex md:hidden justify-end">
                <div @click="setPanel(null)" class="w-10 h-10 flex justify-center items-center rounded-full cursor-pointer">
                    <icon type="close" on="click" mutator="SET_PANEL" :params="null" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>
            <!-- Panel columns -->
            <div id="environment-navigation" class="w-screen md:w-2/5 h-autp md:h-60 px-6 py-4 flex flex-wrap">
                <div id="environments-header" class="w-full text-lg md:text-xl text-grey-600 tracking-normal">Explore Environments</div>
                <div class="flex-shrink h-auto md:h-60 pl-2 pt-2 pb-6 flex flex-col flex-wrap leading-loose">
                    <a 
                        v-for="(category ,index) in environmentsNavigation" 
                        @click="setProcessing(true)"
                        :key="index"
                        v-text="category"
                        :href="`/environments/${kebab(category)}/list`" 
                        :alt="category"
                        target="_self"
                        class="ml-2 mr-4 lg:mr-6 xl:mr-12 text-sm xl:text-base text-grey-600 hover:opacity-50"
                    ></a>
                </div>
            </div>
            <div id="product-navigation" class="w-screen md:w-3/5 h-60 px-6 py-0 md:py-4 flex flex-wrap">
                <div id="products-header" class="w-full text-lg md:text-xl text-grey-600 tracking-normal">Explore Products</div>
                <div class="flex-shrink h-auto md:h-60 pl-2 pt-2 pb-6 flex flex-col flex-wrap leading-loose">
                    <a 
                        v-for="(category ,index) in productsNavigation" 
                        @click="setProcessing(true)"
                        :key="index"
                        v-text="title(category)"
                        :href="`/products/${kebab(category)}/list`" 
                        :alt="category"
                        target="_self"
                        class="ml-2 mr-4 lg:mr-6 xl:mr-12 text-sm xl:text-base text-grey-600 hover:opacity-50"
                    ></a>
                    <!-- <div 
                        v-for="(applications, category) in productsNavigation" 
                        :key="category"
                        class="mr-6 lg:mr-6 xl:mr-12"
                    >
                            <div 
                                v-for="(application ,index) in applications" 
                                :key="index"
                            >
                                <a 
                                    @click="setProcessing(true)"
                                    :href="`/products/${kebab(application)}/list`" 
                                    :alt="application"
                                    v-text="category == 'tables' && ! application.toLowerCase().includes('tables') ? title(`${application} Tables`) : title(application)"
                                    target="_self"
                                    class="text-sm xl:text-base text-grey-600 hover:opacity-50 leading-loose"
                                ></a>
                            </div>
                        <div v-if="! applications.length" class="w-full">
                            <a 
                                @click="setProcessing(true)"
                                :href="`/products/${kebab(category)}/list`" 
                                :alt="category"
                                v-text="title(category)"
                                target="_self"
                                class="text-sm xl:text-base text-grey-600 hover:opacity-50 leading-loose"
                            ></a>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="w-screen md:w-1/4 h-60 px-6 py-4">
                <div class="text-lg md:text-xl tracking-normal text-grey-600">Recently Added</div>
            </div> -->
        </div>
    </transition>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'

/** Composition */
import AppMixins from '../../mixins/app-mixins'

export default {

    /**  Composition */
    mixins: [AppMixins],

    /** Local State */
    computed: {

        /**
         * The product navigation.
         * 
         * @return {array}
         */
        productsNavigation() {
            // Navigation must be loaded.
            if (this.navigation) {
                // Assign parsed navigation to new variable.
                let navigation = JSON.parse(this.navigation)

                let productsNavigation = []

                _.each(navigation.products, (value, key) => {
                    return value.length > 0
                        ? _.each(value, (category) => productsNavigation.push(category))
                        : productsNavigation.push(key)
                })
                // Return product navigation array.
                return productsNavigation //navigation ? navigation.products : []
            }
            // Return empy array
            return []
        },

        /**
         * The environment navigation.
         * 
         * @return {array}
         */
        environmentsNavigation() {
            // Navigation must be loaded.
            if (this.navigation) {
                // Assign parsed navigation to new variable.
                let navigation = JSON.parse(this.navigation)
                // Return navigation array
                return navigation ? navigation.environments : []
            }
            // Return empy array
            return []
        },

        /** Store Getters */
        ...mapGetters(['panel', 'navigation'])
    },

    /** Non-Reactive Properties */
    methods: {

        /**
         * The category has sub categories/applications.
         * 
         * @param {object, string} category The product category from navigation.
         * @return {boolean}
         */
        isObject(category) {
            return category instanceof Object
        },

        /**
         * Extract the category heading when it has applications.
         * 
         * @param {object} category The product category from navigation.
         * @return {object, null}
         */
        withSubs(category) {
            if (Object.keys(category) && Object.values(category)) {
                return {
                    category: _.head(Object.keys(category)),
                    applications: _.head(Object.values(category)),
                }
            }
        },

        determineRoute(category) {
            if (this.navigation) {
                let navigation = JSON.parse(this.navigation)
    
                return navigation && navigation.duplicate && navigation.duplicate.includes(_.startCase(category))
                    ? `/products/${_.kebabCase(category)}/list`
                    : `/environments/${_.kebabCase(category)}/list`
            }
        },

        /** Store Mutations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING',
            setPanel:'SET_PANEL',
        })
    },
}
</script>