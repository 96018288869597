<template>
    <transition name="fade" mode="out-in">
        <div v-if="linksArray && linksArray.length">
            <draggable 
                dusk="draggable-component"
                v-if="reordering && manager == 1 && linkCategory != 'new'" 
                @end="endDragHandler($event)"
                @start="startDragHandler($event)"
                v-model="linksArray"
                ghost-class="ghost"
                drag-class="drag"
                forceFallback="true"
            >
                <link-row 
                    dusk="draggable-link-row-component"
                    v-for="(link, index) in linksArray" 
                    :key="index"
                    :link="link"
                    :media-url="link.media"
                    :index="index"
                    :links-count="linksArray.length"
                    :manager="manager"
                ></link-row>
            </draggable>
            <div v-else>
                <link-row 
                    dusk="fixed-link-row-component"
                    v-for="(link, index) in linksArray" 
                    :key="index"
                    :link="link"
                    :media-url="link.media"
                    :index="index"
                    :links-count="linksArray.length"
                    :manager="manager"
                ></link-row>
            </div>
        </div>
    </transition>
</template>
<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
export default {
    /**
     * Template Dependencies
     */
    components: {draggable},
    /**
     * Interface
     */
    props: {
        links: {
            type: String,
            required: true,
        },
        manager: {
            type: Boolean|String,
            required:false,
            default: false,
        },
    },
    /**
     * Local State
     */
    data() {
        return {
            linksArray: null
        }
    },
    computed: {
        /**
         * Parse the links prop.
         * 
         * @var array
         */
        parsedLinks() {
            if (this.links) {
                return JSON.parse(this.links)
            }
        },

        /**
         * Store Getters
         */
        ...mapGetters(['linkCategory', 'reordering'])
    },
    watch: {
        parsedLinks(links) {
            this.linksArray = links
        }
    },
    mounted() {
        if (this.parsedLinks) {
            this.linksArray = this.parsedLinks
        }
    },
    /**
     * Non-Reactive Properties
     */
    methods: {

        async startDragHandler(event) {
            await console.log({startDrag: event.target})
            await $(this).trigger('drag')
        },
        async endDragHandler(event) {
            await console.log({endDrag: event.target})
            await $(this).trigger('drop')
            await this.orderHandler()
        },
        /**
         * Update link display order.
         * 
         * @return void
         */
        async orderHandler () {
            // Reset the link order.
            this.linkOrder = []
            // Set and assign index variable.
            let i = 1
            // Set and assign Promise function.
            await _.each (this.linksArray, link => {
                // Assign id and order to array.
                this.linkOrder.push({
                    id: link.id, 
                    order: i
                })
                // Increment index.
                i++
            })

            // Process update.
            await axios
                .post('/dashboard/links/reorder', {links: this.linkOrder})
                .then(() => {
                    // let location = new URL(window.location.href)
                    window.location = window.location.href
                })
                .catch(errors => console.warn(errors))
        },
    }
}
</script>
<style lang="sass">
    .ghost 
        opacity: 0.5
        background-color: theme('colors.blue.300')
    .drag
        opacity: 0    
</style>