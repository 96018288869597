const VisitorModule = {
    state: {
        /**
         * The app visitor’s type.
         * 
         * @var {string|null} visitorType The type of visitor [guest|prospect|user]
        */
        visitorType: null,
        /**
         * The app visitor’s name.
         * 
         * @var {string|null} visitorName The name of visitor [$user->name|$prospect->name]
        */
        visitorName: null,
        /**
         * The app visitor’s email.
         * 
         * @var {string|null} visitorEmail The email of visitor [$user->email|$prospect->email]
        */
        visitorEmail: null,
        /**
         * The app visitor’s id.
         * 
         * @var {number|null} visitorId The id of visitor [$user->id|$prospect->id]
        */
        visitorId: null,
        /**
         * The visitor is on mobile device. 
         * 
         * @var {boolean} isMobile True or false.
         */
        isMobile: false,
        /**
         * The visitor is on desktop computer. 
         * 
         * @var {boolean} isDesktop True or false.
         */
        isDesktop: false,
    },

    getters: {
        /**
         * Get the current visitor’s type.
         * 
         * @return {string} visitorType
        */
        visitorType({ visitorType }) {
                return visitorType
        },
       /**
         * Get the current visitor’s name.
         * 
         * @return {string} visitorName
        */
        visitorName({ visitorName }) {
            return visitorName
        },
       /**
         * Get the current visitor’s email.
         * 
         * @return {string} visitorEmail
        */
        visitorEmail({ visitorEmail }) {
            return visitorEmail
        },
        /**
         * Get the app visitor’s id.
         * 
         * @return {number} visitorId 
        */
        visitorId({ visitorId }) {
            return visitorId
        },
        /**
         * Get the app’s isMobile state
         * 
         * @return {boolean} isMobile
         */
        isMobile({ isMobile }) {
            return isMobile
        },
        /**
         * Get the app’s isDesktop state
         * 
         * @return {boolean} isDesktop
         */
        isDesktop({ isDesktop }) {
            return isDesktop
        },
    },

    actions: {},

    mutations: {
        /**
         * Mutate the visitor’s type.
         * 
         * @param {object} state The store’s state module.
         * @param {string|null} visitorType The visitor’s type to mutate.
         */
        SET_VISITOR_TYPE(state, visitorType) {
            state.visitorType = visitorType
        },

        /**
         * Mutate the visitor’s name.
         * 
         * @param {object} state The store’s state module.
         * @param {string|null} visitorName The visitor’s name to mutate.
         */
        SET_VISITOR_NAME(state, visitorName) {
            state.visitorName = visitorName
        },

        /**
         * Mutate the visitor’s email.
         * 
         * @param {object} state The store’s state module.
         * @param {string|null} visitorEmail The visitor’s email to mutate.
         */
        SET_VISITOR_EMAIL(state, visitorEmail) {
            state.visitorEmail = visitorEmail
        },

        /**
         * Mutate the visitor’s id.
         * 
         * @param {object} state The store’s state module.
         * @param {number|null} visitorId The visitor’s id to mutate.
         */
        SET_VISITOR_ID(state, visitorId) {
            state.visitorId = visitorId
        },

        /**
         * Mutate the isMobile state.
         * 
         * @param {object} state The store state module.
         * @param {boolean} isMobile The isMobile state.
         */
        SET_IS_MOBILE(state, isMobile) {
            state.isMobile = isMobile
        },

        /**
         * Mutate the isDesktop state.
         * 
         * @param {object} state The store state module.
         * @param {boolean} isDesktop The isDesktop state.
         */
        SET_IS_DESKTOP(state, isDesktop) {
            state.isDesktop = isDesktop
        },
    }
}

export default VisitorModule
