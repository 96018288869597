<template>
    <div :class="{'mb-32': scrolling}" class="w-screen relative z-16">
        <action-bar dusk="action-bar-component" :phone="phone" :email="email" :favorites="favorites"></action-bar>
        <brand-bar dusk="brand-bar-component" :store="store" :location="location"></brand-bar>
        <indicator-bar dusk="indicator-bar-component" :title="title"></indicator-bar>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters } from 'vuex'
/** Template Dependencies */
import ActionBar from './dependencies/ActionBar'
import BrandBar from './dependencies/BrandBar'
import IndicatorBar from './dependencies/IndicatorBar'
export default {

    /** Interface */
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
    },
    /** Template Dependencies */
    components: {
        ActionBar, BrandBar, IndicatorBar,
    },

    /** Local State */
    computed: {
        /**
         * The dealer’s phone
         *
         * @return {string|null}
         */
        phone() {
            if (this.dealer && this.dealer.phone && this.dealer.phone != '0') {
                return `<a href="tel:${this.dealer.phone}">${this.dealer.phone}</a>`
            }
            return null
        },

        /**
         * The dealer’s email
         *
         * @return {string}
         */
        email() {
            if (this.dealer && this.dealer.email) {
                return `<a target="_top" href="mailto:${this.dealer.email}">Email Us</a>`
            }
            // Fallback.
            return `<a target="_top" href="mailto:info@connexionsai.com">Email Us</a>`
        },

        /**
         * The dealer’s store name.
         *
         * @return {string}
         */
        store() {
            if (this.dealer && this.dealer.store_name) {
                return this.dealer.store_name
            }
            return 'Connexions'
        },

        /**
         * The dealer’s store location.
         *
         * @return {string}
         */
        location() {
            if (this.dealer && this.dealer.store_location) {
                return this.dealer.store_location
            }
            return 'USA'
        },

        /** Store Getters */
        ...mapGetters([
            'favorites',
            'scrolling',
            'dealer',
            'panel',
        ])
    },
}
</script>
