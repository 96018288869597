const FlicketyOptions = {
    /** Behavior */
    isInitActivated:false,
    draggable: true,
    freeScroll: true,
    wrapAround: true,
    groupCells: true, //'80%' | true | 2,
    autoPlay: 10000, // 1500 | false
    fullscreen: false,
    // adaptiveHeight: true,
    // watchCSS: false,
    // dragThreshold: 10,
    // selectedAttraction: 0.2,
    // friction: 0.8,
    // freeScrollFriction: 0.04, // 0.075 default; lower friction, slides easier

    /** Images */
    lazyLoad: false,
    bgLazyLoad: false, // true (one at a time)
    initialIndex: 1,

    /** Setup */
    cellSelector: '.card',
    accessibility: true,
    setGallerySize: true, // https://flickity.metafizzy.co/options.html#setgallerysize
    resize: true,

    /** Cell Position */
    cellAlign: 'left',
    contain: true, // Has no effect if wrapAround: true
    percentPosition: true,
    rightToLeft: true,

    /** Ui */
    pageDots: true,
    prevNextButtons: true,
    arrowShape: { // https://flickity.metafizzy.co/options.html#arrowshape
        x0: 10,
        x1: 60, y1: 50,
        x2: 70, y2: 40,
        x3: 30
    }
}

export default FlicketyOptions
