import { render, staticRenderFns } from "./RequestPrice.vue?vue&type=template&id=18bef56b"
import script from "./RequestPrice.vue?vue&type=script&lang=js"
export * from "./RequestPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports