<template>
    <form ref="show-log" :action="action" method="GET">
        <select
            dusk="log-select"
            @change="selectLog($event)" 
            name="log" 
            id="log" 
            v-model="log"
            class="block appearance-none my-2 pl-4 pr-10 py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded cursor-pointer" 
        >
            <option 
                v-for="(log, index) in logNames" 
                :key="index" 
                :ref="`option-${log}`"
                :value="log"
            >
                {{entryName(log)}}
            </option>
        </select>
        <button name="select-log-submit" type="submit" class="hidden"></button>
    </form>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
    /**
     * Interface
     */
    props: ['logs', 'selected', 'entries'],
    /**
     * Local State
     */
    data() {
        return {
            action: '/logs/show/',
            log: null,
        }
    },
    computed: {
        /**
         * Parse the json encoded array of log names from the blade view. 
         * 
         * @return {array}
         */
        logNames() {
            if (this.logs) {
                let parsed = JSON.parse(this.logs)
                return _.map(parsed, (filename) => {
                    return filename.split('.')[0]
                });
            }
        },

        /**
         * Parse the json encoded array of log entries from the blade view. 
         * 
         * @return {array}
         */
        logEntries() {
            if (this.entries) {
                return JSON.parse(this.entries)
            }
        }
    },

    /**
     * Life-cycle Events
     */
    mounted() {
        if (this.selected) {
            this.log = _.lowerCase(this.selected)
        }
    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Perform the sort operation. 
         * 
         * @param {object} event The event object. 
         * @return {void}
         */
        async selectLog(event) {
            // Add the log param to the action url.
            // console.log({'this.setAction':event.target.value}) // Testing
            await this.setAction(event.target.value)
            // Fetch the form element.
            // console.log({'this.$refs':this.$refs['show-log']}) // Testing
            let form = this.$refs['show-log']
            // Set the form action.
            // console.log({'form.setAttribute(\'action\')':this.action}) // Testing
            await form.setAttribute('action', this.action)
            // Submit the form.
            form.submit()
        },

        /**
         * Set the form action to reflect the selected log name. 
         * 
         * @param {string} log The log name. 
         * @return {void}
         */
        setAction(log) {
            this.action = '/logs/show/' + _.lowerCase(log)
        },

        /**
         * Format log filename for select option.
         * 
         * @param {string} log The log filename. 
         * @return {string} Formatted log name.
         */
        entryName(log) {
            let array = _.split(log, '.')

            if (array.length) {
                return _.startCase(array[0])
            }
            return _.startCase(log)
        },
    }
}
</script>