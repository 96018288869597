<template>
    <div v-if="content && content.length" class="w-full flex flex-col relative overflow-x-hidden overflow-y-scroll">
        <div 
            v-for="hit in content" 
            :key="hit.id" 
            @click="setShowSearch(false)"
            class="w-full px-2 md:px-4 flex-stretch flex items-center justify-between hover:bg-darken-10"
        >
            <div class="my-4">
                <a 
                    :href="`/${modelIndex}/${hit.id}/show`"   
                    class="text-title text-grey-600 hover:text-grey-400 no-underline leading-tight" 
                >
                    <!-- Environments -->
                    <span v-if="modelIndex === 'environments'" >
                        <div class="text-grey-600 flex items-center">
                            <span v-html="hit.group"></span><span v-if="hit.vendor" v-html="`&nbsp;by&nbsp;${hit.vendor}`"></span>
                        </div>
                        <div v-if="hit.model_number" class="text-grey-600 flex">Model number:&nbsp;<span v-html="hit.model_number"></span></div>
                        <div v-html="hit.description" class="text-sm text-grey-600"></div>
                        <div class="my-1 text-sm text-grey-600">
                            <div v-if="hit.categories && hit.categories.length" class="flex">
                                <div class="hidden md:block">Environment Type:&nbsp;</div>
                                <div v-for="(category, index) in hit.categories" :key="index">
                                    <span v-html="`${category}`"></span><span v-if="hit.categories.length > 1 && index + 1 < hit.categories.length">,&nbsp;</span>
                                </div>
                            </div>
                        </div>
                      
                    </span>

                    <!-- Products -->
                    <span v-if="modelIndex === 'products'" >
                        <!-- <div class="text-grey-600 flex items-center">{{hit.group.name}}<span v-if="hit.vendor">&nbsp;by&nbsp;{{hit.vendor.company_name}}</span></div> -->
                        <div class="text-grey-600 flex items-center">
                            <span v-html="hit.group"></span><span v-if="hit.vendor" v-html="`&nbsp;by&nbsp;${hit.vendor}`"></span>
                        </div>
                        <div v-if="hit.model_number" class="text-grey-600 flex">Model number:&nbsp;<span v-html="hit.model_number"></span></div>
                        <div v-html="hit.description" class="text-sm text-grey-600"></div>
                        <div :class="{'mb-1': ! hit.applications || ! hit.applications.length}" class="mt-1 text-sm text-grey-600">
                            <div v-if="hit.categories && hit.categories.length" class="flex">
                                <div class="hidden md:block">Product Type:&nbsp;
                                    <span v-for="(category, index) in hit.categories" :key="index">
                                        <span v-html="category"></span><span v-if="hit.categories.length > 1 && index + 1 < hit.categories.length">,&nbsp;</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div :class="{'mt-1': ! hit.categories}" class="mb-1 text-sm text-grey-600">
                            <div v-if="hit.applications && hit.applications.length">
                                <div class="hidden md:block">Common Applications:&nbsp; 
                                    <span v-for="(application, index) in hit.applications" :key="index">
                                        <span v-html="application"></span><span v-if="hit.applications.length > 1 && index + 1 < hit.applications.length">,&nbsp;</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>

                    <!-- Groups -->
                    <span v-if="modelIndex === 'groups'" >
                        <div class="text-grey-600 flex items-center">
                            <span v-html="hit.name"></span><span v-if="hit.vendor">&nbsp;by&nbsp;<span v-html="hit.vendor"></span></span>
                        </div>
                        <div v-html="hit.description" class="text-sm text-grey-600"></div>
                    </span>

                    <!-- Vendors -->
                    <span v-if="modelIndex === 'vendors'" >
                        <div class="my-1 text-grey-600">
                            <span v-html="hit.company_name" class="text-md"></span>
                            <span v-if="hit.offerings" class="text-base text-grey-600">&nbsp;–&nbsp;<span v-html="hit.offerings"></span></span>
                        </div>
                        <div v-html="hit.description" class="my-1 text-sm text-grey-600"></div>
                    </span>
                    
                </a>
            </div>
        </div>
    </div>
</template>
<script>
// Vuex store
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    /**
     * Interface
     */
    props: {
        content: {
            required: false,
            default: []
        }, 
        modelIndex:{
            type: String,
            required: true,
        }
    },

    /**
     * Local State
     */
    computed: {
        /**
         * Sort the search results. 
         * 
         * @param {object} hits
         */
        sortedResults() {
            if (this.content && this.content.length) {
                switch(this.modelIndex) {
                    case 'environments': 
                        return _.sortBy(this.content, ['categories', 'updated_at'])
                    case 'products': 
                        return _.sortBy(this.content, ['categories', 'model_number', 'applications', 'updated_at'])
                    case 'groups': 
                        return _.sortBy(this.content, ['name', 'updated_at'])
                    case 'vendors': 
                        return _.sortBy(this.content, ['name', 'updated_at'])
                    default: 
                        return _.sortBy(this.content, ['updated_at'])
                }
            }  
            return null         
        },  

        /**
         * Store Getters
         */
        ...mapGetters(['searchResults'])  
    },

    /**
     * Events
     */
    watch: {
        content(content) {
            if (content && content.length) {
                this.setSearchResults(true)
            } else {
                this.setSearchResults(false)
            }            
        }
    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Store Mutations
         */
        ...mapMutations({
            setSearchResults: 'SET_SEARCH_RESULTS',
            setShowSearch: 'SETSHOW_SEARCH',
        })
    }

}
</script>