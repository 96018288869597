<template>
    <div>
        <div v-if="processing" class="w-screen h-screen fixed bg-grey-800/50 text-white text-sm flex justify-center items-center">Processing&hellip;</div>
        <div class="container mx-auto pt-6">
            <img :src="`/storage/${vendor.logo_path}`" :alt="vendor.company_name" class="w-32 whitespace-no-wrap">
        </div>

        <div class="container mx-auto py-4 border-b border-black">
            <div class="w-full">
                <div class="w-full flex flex-col md:flex-row items-start justify-between">
                    <div class="flex-1 w-1/4">
                        <div class="w-full py-3 px-3 bg-black text-white">Vendor</div>
                        <div class="w-full px-3 text-black">
                            <div class="text-2xl pt-0"><strong>{{specifications.vendor ?? null}}</strong></div>
                            <div class="mt-6 text-sm relative">
                                <strong>Contacts</strong>
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="creating = 'contact'"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Add new contact"
                                    class="absolute top-0.5 -left-5 hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <div v-for="(contact, index) in specifications.contacts" :key="`contact_${contact.id}`" :class="[index === 0 ? 'mt-0' : 'mt-6']">
                                <div class="discount-content relative">
                                    <div
                                        v-if="!hideForm && !creating && !editing"
                                        @click="editContact(contact)"
                                        v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                        content="Update this contact"
                                        :class="`absolute top-1 -left-8 hover:opacity-50 cursor-pointer flex items-center`"
                                    >
                                        <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                    </div>
                                    <div
                                        v-if="!hideForm && !creating && !editing"
                                        @click="deleteContact(contact);"
                                        v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                        content="Delete this contact"
                                        class="absolute top-1 -left-16 hover:opacity-50 cursor-pointer flex items-center"
                                    >
                                        <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                    </div>
                                    <div class="mt-2"><strong>{{contact.label}}</strong></div>
                                    <div>{{contact.name}}</div>
                                    <a v-if="contact.email" :href="`mailto:${contact.email}`" target="_blank" :title="`Email ${contact.email ?? null}`" class="text-blue-500 underline">
                                        {{contact.email ?? null}}
                                    </a>
                                    <div v-if="contact.phone">{{contact.phone}}</div>
                                    <div v-if="contact.details" class="text-sm discount-content-padded">{{contact.details}}</div>
                                    <div v-if="contact.details_url" >
                                        <a :href="downloadContact(contact.details_url)" target="_blank" title="Contact download" class="text-blue-500 underline text-sm"><em>Details&hellip;</em></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="flex-1 relative w-1/4">
                        <div class="w-full py-3 px-3 bg-black text-white">Pricing</div>
                        <div class="w-full px-3 text-black relative">
                            <div class="w-fit text-sm relative">
                                <div v-if="specifications.discounts.length === 1" class="w-fit relative">
                                    <strong> Standard Discount </strong>
                                </div>
                                <div v-else class="w-fit relative">
                                    <strong> Discount </strong>
                                </div>
                            </div>
                            <div v-for="(discount, index) in specifications.discounts" :key="`discount_${discount.id}`" class="relative">
                                <div
                                    @click="currentDiscount = discount; editing = 'discount';"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Update this discount"
                                    class="absolute top-1 -left-8 hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon v-if="!hideForm && !creating && !editing" @click="currentDiscount = discount; editing = 'discount';" class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div v-if="discount.base_factor !== 0">
                                    <div :class="[index > 0 ? 'pt-3' : 'w-full text-black text-base leading-6']">{{displayableDiscount(discount)}}</div>
                                    <div class="pb-6">
                                        <div v-html="discount.description"></div>
                                        <div class="text-sm" v-html="discount.details"></div>
<!--                                        <a v-if="discount.details_url" class="w-full mt-2 text-base leading-6 text-blue-500 underline" :href="discount.details_url" target="_blank" title="Discount Details">Details&hellip;</a>-->
                                    </div>
                                </div>
                            </div>
                            <div class="text-sm relative">
                                <strong>Price Lists</strong>
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="creating = 'pricer'"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Add new pricer"
                                    class="absolute top-0.5 -left-5 hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <div v-for="(pricer, index) in specifications.pricers" :key="`pricer_${pricer.id}`" :class="[index === 0 ? 'mt-0' : 'mt-6']">
                                <div class="discount-content relative">
                                    <div
                                        v-if="!hideForm && !creating && !editing"
                                        @click="editPricer(pricer)"
                                        v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                        content="Update this pricer"
                                        :class="`absolute top-1 -left-8 hover:opacity-50 cursor-pointer flex items-center`"
                                    >
                                        <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                    </div>
                                    <div
                                        v-if="!hideForm && !creating && !editing"
                                        @click="deletePricer(pricer);"
                                        v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                        content="Delete this pricer"
                                        class="absolute top-1 -left-16 hover:opacity-50 cursor-pointer flex items-center"
                                    >
                                        <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                    </div>
                                    <a v-if="pricer.file" :href="downloadPricer(pricer.id)" target="_blank" :title="`Download ${pricer.name ?? null}`" class="text-blue-500 underline">
                                        {{pricer.name ?? null}}
                                    </a>
                                    <div v-else>{{pricer.name ?? null}}</div>

                                    <div v-if="pricer.details">{{pricer.details}}</div>
                                    <a v-if="pricer.details_url" :href="pricerDetailsLink(pricer.details_url)" target="_blank" :title="`${pricer.details ?? null} Link`" class="text-blue-500 underline">
                                        Link…
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="flex-1 relative w-1/4">
                        <div class="w-full py-3 px-3 bg-black text-white">Charges</div>
                        <div
                            v-if="!hideForm && !creating && !editing"
                            @click="creating = 'charge'"
                            v-tippy="{
                                theme: 'translucent',
                                arrow: true,
                                touch: false,
                                placement: 'top',
                            }"
                            content="Add new charge"
                            class="absolute top-4 -left-3 hover:opacity-50 cursor-pointer flex items-center"
                        >
                            <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                        </div>
                        <div class="flex-1 flex flex-col px-3">
                            <div v-for="(charge, index) in specifications.charges" :key="`charge_${charge.id}`" :class="[index === 0 ? 'mt-0' : 'mt-6', 'relative']">
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="editCharge(charge);"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Update this charge"
                                    :class="`absolute top-1 -left-8 hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="deleteCharge(charge)"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Delete this charge"
                                    class="absolute top-1 -left-16 hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>

                                <div class="text-sm"><strong>{{charge.type ?? null}}</strong></div>
                                <div class="text-base" v-html="charge.description ?? null"></div>

                                <a v-if="charge.details_url" class="text-sm text-blue-500 underline" :href="downloadCharge(charge.details_url)" target="_blank" :title="`${charge.type} file`"><em>Details&hellip;</em></a>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 relative w-1/4">
                        <div class="w-full py-3 px-3 bg-black text-white">Incentives</div>
                        <div
                            v-if="!hideForm && !creating && !editing"
                            @click="creating = 'incentive'"
                            v-tippy="{
                                theme: 'translucent',
                                arrow: true,
                                touch: false,
                                placement: 'top',
                            }"
                            content="Add new incentive"
                            class="absolute top-4 -left-3 hover:opacity-50 cursor-pointer flex items-center"
                        >
                            <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                        </div>
                        <div class="flex-1 flex flex-col px-3">
                            <div v-for="(incentive, index) in specifications.incentives" :key="`incentive_${incentive.id}`" :class="[index === 0 ? 'mt-0' : 'mt-6', 'relative']">
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="editIncentive(incentive)"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Update this incentive"
                                    :class="`absolute top-1 -left-8 hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    v-if="!hideForm && !creating && !editing"
                                    @click="deleteIncentive(incentive)"
                                    v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                    content="Delete this incentive"
                                    class="absolute top-1 -left-16 hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>

                                <div class="text-sm"><strong>{{incentive.type ?? null}}</strong></div>
                                <div class="text-base" v-html="incentive.description ?? null"></div>

                                <a v-if="incentive.details_url" class="text-sm text-blue-500 underline" :href="downloadIncentive(incentive.details_url)" target="_blank" :title="`${incentive.type} file`"><em>Details&hellip;</em></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mx-auto flex">
            <div v-if="editing === 'discount' && currentDiscount" class="w-full flex">
                <div class="w-1/4">&nbsp;</div>
                <form @submit.prevent="updateDiscount" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Update discount</h2>
                    <div class="mb-4 flex items-baseline justify-between">
                        <input type="number" v-model="currentDiscount.base_factor" step="10" name="base_factor" id="base_factor" class="px-3 py-2 mt-1 block w-20" required :placeholder="currentDiscount.base_factor">
                        <div>/</div>
                        <input type="number" v-model="currentDiscount.first_tier" step="5" name="first_tier" id="first_tier" class="px-3 py-2 mt-1 block w-20" :placeholder="currentDiscount.first_tier">
                        <div>/</div>
                        <input type="number" v-model="currentDiscount.second_tier" step="1" name="second_tier" id="second_tier" class="px-3 py-2 mt-1 block w-20" :placeholder="currentDiscount.second_tier">
                        <div>/</div>
                        <input type="number" v-model="currentDiscount.third_tier" name="third_tier" id="third_tier" class="px-3 py-2 mt-1 block w-20" :placeholder="currentDiscount.third_tier">
                    </div>

                    <div class="mt-3 text-xs text-grey-800">Details</div>
                    <input type="text" v-model="currentDiscount.details" name="description" id="description" class="px-3 py-2 mt-1 block w-full" :placeholder="currentDiscount.details">

                    <div class="flex justify-end gap-x-2">
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                    </div>
                </form>
            </div>

            <div v-if="creating === 'pricer'" class="w-full flex">
                <div class="w-1/4">&nbsp;</div>
                <form @submit.prevent="storePricer" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Add new pricer</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div v-if="newPricerFilename" class="mt-3 text-xs text-grey-800">{{ newPricerFilename }}</div>
                            <div @click="browseForPricer" class="bg-blue-500 hover:opacity-50 text-white text-xs uppercase px-2 py-1 rounded-md cursor-pointer">
                                <span v-if="newPricer.pricer">Change file</span>
                                <span v-else >Add file (required)</span>
                            </div>
                            <input class="hidden" type="file" name="pricer" @change="uploadForNewPricer">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Name <span class="text-red-500 text-xs">(required)</span></div>
                        <input type="text" v-model="newPricer.name" name="name" id="name" class="px-3 py-2 mt-1 block w-full" required :placeholder="newPricer.name">

                        <div v-if="isOwner">
                            <div class="mt-3 text-xs text-grey-800">Description <span class="text-grey-500 text-xs">(optional)</span></div>
                            <input type="text" v-model="newPricer.description" name="description" id="description" class="px-3 py-2 mt-1 block w-full" :placeholder="newPricer.description">
                            <div class="mt-3 text-xs text-grey-800">Details <span class="text-grey-500 text-xs">(optional)</span></div>
                            <input type="text" v-model="newPricer.details" name="details" id="details" class="px-3 py-2 mt-1 block w-full" :placeholder="newPricer.details">
                            <div class="mt-3 text-xs text-grey-800">Details link <span class="text-grey-500 text-xs">(optional)</span></div>
                            <input type="text" v-model="newPricer.details_url" name="details_url" id="details_url" class="px-3 py-2 mt-1 block w-full" :placeholder="newPricer.details_url">
                        </div>

                    </div>
                    <div class="flex justify-end gap-x-2" >
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button :type="[newPricerReady ? 'submit' : 'button']" :class="[newPricerReady ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Add</button>
                    </div>
                </form>
            </div>
            <div v-if="editing === 'pricer' && currentPricer" class="w-full flex">
                <div class="w-1/4">&nbsp;</div>
                <form @submit.prevent="updatePricer" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Update pricer</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div>
                                <div class="mt-3 text-xs text-grey-800">File</div>
                                <a :href="downloadPricer(currentPricer.id)" target="_blank" class="text-blue-500">
                                    {{ pricerFilename ?? null }}
                                </a>
                            </div>

                            <div class="flex items-center gap-x-2">
                                <div
                                    @click="deleteMedia({type:'pricer', id:currentPricer.id})"
                                    v-tippy="{
                                                    theme: 'translucent',
                                                    arrow: true,
                                                    touch: false,
                                                    placement: 'top',
                                                }"
                                    content="Delete this file"
                                    class="hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    @click="browseForPricer"
                                    v-tippy="{
                                                    theme: 'translucent',
                                                    arrow: true,
                                                    touch: false,
                                                    placement: 'top',
                                                }"
                                    content="Change file"
                                    :class="`hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <input class="hidden" type="file" name="pricer" @change="uploadPricer">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Name <span class="text-red-500 text-xs">(required)</span></div>
                        <input type="text" v-model="currentPricer.name" name="name" id="name" class="px-3 py-2 mt-1 block w-full" required :placeholder="currentPricer.name">

                        <div v-if="isOwner">
                            <div class="mt-3 text-xs text-grey-800">Description</div>
                            <input type="text" v-model="currentPricer.description" name="description" id="description" class="px-3 py-2 mt-1 block w-full" :placeholder="currentPricer.description">
                            <div class="mt-3 text-xs text-grey-800">Details</div>
                            <input type="text" v-model="currentPricer.details" name="details" id="details" class="px-3 py-2 mt-1 block w-full" :placeholder="currentPricer.details">
                            <div class="mt-3 text-xs text-grey-800">Details link</div>
                            <input type="text" v-model="currentPricer.details_url" name="details_url" id="details_url" class="px-3 py-2 mt-1 block w-full" :placeholder="currentPricer.details_url">
                        </div>

                    </div>
                    <div class="flex justify-end gap-x-2">
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                    </div>
                </form>
            </div>

            <div v-if="creating === 'contact'" class="w-full flex">
                <form @submit.prevent="storeContact" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Add new contact</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div v-if="newContactFilename" class="mt-3 text-xs text-grey-800">{{ newContactFilename }}</div>
                            <div
                                v-if="newContact.contact"
                                @click="browseForContact"
                                v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                content="Change file"
                                class="hover:opacity-50 cursor-pointer flex items-center gap-x-2"
                            >
                                <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Change</div>
                            </div>
                            <div
                                v-else
                                @click="browseForContact"
                                v-tippy="{
                                        theme: 'translucent',
                                        arrow: true,
                                        touch: false,
                                        placement: 'top',
                                    }"
                                content="Add new file"
                                class="hover:opacity-50 cursor-pointer flex items-center gap-x-2"
                            >
                                <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Add file (email, phone, or file required)</div>
                            </div>
                            <input class="hidden" type="file" name="contact" @change="uploadForNewContact">
                        </div>

                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Contact type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="newContact.label" name="label" id="label" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="Admin">Admin</option>
                            <option value="Customer Care">Customer Care</option>
                            <option value="Factory">Factory</option>
                            <option value="Freight & Availability">Freight & Availability</option>
                            <option value="Lead Times">Lead Times</option>
                            <option value="Orders">Orders</option>
                            <option value="Pricing">Pricing</option>
                            <option value="Sales">Sales</option>
                            <option value="Specification">Specification</option>
                        </select>

                        <div class="text-xs text-grey-800">Name <span class="text-red-500 text-xs">(required)</span></div>
                        <input type="text" v-model="newContact.name" name="name" id="name" class="px-3 py-2 mt-1 block w-full" required :placeholder="newContact.name">
                        <div class="text-xs text-grey-800">Email <span class="text-red-500 text-xs">(email, phone, or file required)</span></div>
                        <input type="email" v-model="newContact.email" name="email" id="email" class="px-3 py-2 mt-1 block w-full" :placeholder="newContact.email">
                        <div class="text-xs text-grey-800">Phone <span class="text-red-500 text-xs">(email, phone, or file required)</span></div>
                        <input type="text" v-model="newContact.phone" name="phone" id="phone" class="px-3 py-2 mt-1 block w-full" :placeholder="newContact.phone">
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-grey-500 text-xs">(optional)</span></div>
                        <textarea v-model="newContact.details" name="details" id="details" rows="3" class="px-3 py-2 mt-1 block w-full" :placeholder="newContact.details" />

                    </div>
                    <div class="flex justify-end gap-x-2" >
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button :type="[newContactReady ? 'submit' : 'button']" :class="[newContactReady ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Add</button>
                    </div>
                </form>
            </div>
            <div v-if="editing === 'contact' && currentContact" class="w-full flex">
                <form @submit.prevent="updateContact" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Update contact</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div>
                                <div class="mt-3 text-xs text-grey-800">File</div>
                                <a :href="downloadContact(currentContact.details_url)" target="_blank" class="text-blue-500">
                                    {{ contactFilename ?? null }}
                                </a>
                            </div>
                            <div v-if="currentContact.details_url" class="flex justify-end items-end gap-x-2">
                                <div
                                    @click="deleteMedia({type:'contact', id:currentContact.id})"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Delete this file"
                                    class="hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    @click="browseForContact"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Change file"
                                    :class="`hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <div
                                v-else
                                @click="browseForContact"
                                v-tippy="{
                                        theme: 'translucent',
                                        arrow: true,
                                        touch: false,
                                        placement: 'top',
                                    }"
                                content="Add new file"
                                class="hover:opacity-50 cursor-pointer flex items-center"
                            >
                                <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Add file (email, phone, or file required)</div>
                            </div>
                            <input class="hidden" type="file" name="contact" @change="uploadContact">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="currentContact.label" name="label" id="label" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="Admin">Admin</option>
                            <option value="Customer Care">Customer Care</option>
                            <option value="Factory">Factory</option>
                            <option value="Freight & Availability">Freight & Availability</option>
                            <option value="Lead Times">Lead Times</option>
                            <option value="Orders">Orders</option>
                            <option value="Pricing">Pricing</option>
                            <option value="Sales">Sales</option>
                            <option value="Specification">Specification</option>
                        </select>
                        <div class="text-xs text-grey-800">Name <span class="text-red-500 text-xs">(required)</span></div>
                        <input type="text" v-model="currentContact.name" name="name" id="name" class="px-3 py-2 mt-1 block w-full" required :placeholder="currentContact.name">
                        <div class="text-xs text-grey-800">Email <span class="text-red-500 text-xs">(email, phone, or file required)</span></div>
                        <input type="email" v-model="currentContact.email" name="email" id="email" class="px-3 py-2 mt-1 block w-full" :placeholder="currentContact.email">
                        <div class="text-xs text-grey-800">Phone <span class="text-red-500 text-xs">(email, phone, or file required)</span></div>
                        <input type="text" v-model="currentContact.phone" name="phone" id="phone" class="px-3 py-2 mt-1 block w-full" :placeholder="currentContact.phone">
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-grey-500 text-xs">(optional)</span></div>
                        <textarea v-model="currentContact.details" name="details" id="details" rows="3" class="px-3 py-2 mt-1 block w-full" :placeholder="currentContact.details" />
                    </div>
                    <div class="flex justify-end gap-x-2">
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                    </div>
                </form>
            </div>

            <div v-if="creating === 'charge'" class="w-full flex">
                <div class="w-1/2">&nbsp;</div>
                <form @submit.prevent="storeCharge" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Add new charge</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div v-if="newChargeFilename" class="mt-3 text-xs text-grey-800">{{ newChargeFilename }}</div>
                            <div
                                v-if="newCharge.charge"
                                @click="browseForCharge"
                                v-tippy="{
                                            theme: 'translucent',
                                            arrow: true,
                                            touch: false,
                                            placement: 'top',
                                        }"
                                content="Change file"
                                class="hover:opacity-50 cursor-pointer flex items-center gap-x-2"
                            >
                                <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Change</div>
                            </div>
                            <div
                                v-else
                                @click="browseForCharge"
                                v-tippy="{
                                        theme: 'translucent',
                                        arrow: true,
                                        touch: false,
                                        placement: 'top',
                                    }"
                                content="Add new file"
                                class="hover:opacity-50 cursor-pointer flex items-center gap-x-2"
                            >
                                <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Add file (optional)</div>
                            </div>
                            <input class="hidden" type="file" name="charge" @change="uploadForNewCharge">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="newCharge.type" name="type" id="type" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="Additional">Additional</option>
                            <option value="Freight">Freight</option>
                            <option value="Small Order">Small Order</option>
                            <option value="Tariff">Tariff</option>
                        </select>
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-red-500 text-xs">(required)</span></div>
                        <textarea v-model="newCharge.description" name="description" id="description" rows="3" class="px-3 py-2 mt-1 block w-full" required :placeholder="newCharge.description" />

                    </div>
                    <div class="flex justify-end gap-x-2" >
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button :type="[newChargeReady ? 'submit' : 'button']" :class="[newChargeReady ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Add</button>
                    </div>
                </form>
            </div>
            <div v-if="editing === 'charge' && currentCharge" class="w-full flex">
                <div class="w-1/2">&nbsp;</div>
                <form @submit.prevent="updateCharge" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Update charge</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div>
                                <div class="mt-3 text-xs text-grey-800">File</div>
                                <a v-if="currentCharge.details_url" :href="downloadCharge(currentCharge.details_url)" target="_blank" class="text-blue-500">
                                    {{ chargeFilename ?? null }}
                                </a>
                            </div>
                            <div v-if="currentCharge.details_url" class="flex justify-end items-end gap-x-2">
                                <div
                                    @click="deleteMedia({type:'charge', id:currentCharge.id})"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Delete this file"
                                    class="hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    @click="browseForCharge"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Change file"
                                    :class="`hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <div
                                v-else
                                @click="browseForCharge"
                                v-tippy="{
                                        theme: 'translucent',
                                        arrow: true,
                                        touch: false,
                                        placement: 'top',
                                    }"
                                content="Add new file"
                                class="hover:opacity-50 cursor-pointer flex items-center"
                            >
                                <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Add file</div>
                            </div>
                            <input class="hidden" type="file" name="charge" @change="uploadCharge">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="currentCharge.type" name="type" id="type" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="Additional">Additional</option>
                            <option value="Freight">Freight</option>
                            <option value="Small Order">Small Order</option>
                            <option value="Tariff">Tariff</option>
                        </select>
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-red-500 text-xs">(required)</span></div>
                        <textarea v-model="currentCharge.description" name="description" id="description" rows="3" class="px-3 py-2 mt-1 block w-full" required :placeholder="currentCharge.description" />
                    </div>
                    <div class="flex justify-end gap-x-2">
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                    </div>
                </form>
            </div>
            <div v-if="creating === 'incentive'" class="w-full flex">
                <div class="w-3/4">&nbsp;</div>
                <form @submit.prevent="storeIncentive" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Add new incentive</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end">
                            <div v-if="newIncentiveFilename" class="mt-3 text-xs text-grey-800">{{ newIncentiveFilename }}</div>
                            <div @click="browseForIncentive" class="bg-blue-500 hover:opacity-50 text-white text-xs uppercase px-2 py-1 rounded-md cursor-pointer">
                                <span v-if="newIncentive.incentive">Change file</span>
                                <span v-else >Add file (optional)</span>
                            </div>
                            <input class="hidden" type="file" name="incentive" @change="uploadForNewIncentive">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="newIncentive.type" name="type" id="type" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="SPIFF">SPIFF</option>
                            <option value="Rebate">Rebate</option>
                            <option value="Promotion">Promotion</option>
                            <option value="Showroom Sample">Showroom Sample</option>
                            <option value="Working Sample">Working Sample</option>
                        </select>
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-red-500 text-xs">(required)</span></div>
                        <textarea v-model="newIncentive.description" name="description" id="description" rows="3" class="px-3 py-2 mt-1 block w-full" required :placeholder="newIncentive.description" />

                    </div>
                    <div class="flex justify-end gap-x-2" >
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button :type="[newIncentiveReady ? 'submit' : 'button']" :class="[newIncentiveReady ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Add</button>
                    </div>
                </form>
            </div>
            <div v-if="editing === 'incentive' && currentIncentive" class="w-full flex">
                <div class="w-3/4">&nbsp;</div>
                <form @submit.prevent="updateIncentive" class="w-1/4 py-6">
                    <h2 class="py-4 text-xl">Update incentive</h2>
                    <div class="mb-4">
                        <div class="flex justify-between items-end gap-x-2">
                            <div>
                                <div class="mt-3 text-xs text-grey-800">File</div>
                                <a v-if="currentIncentive.details_url" :href="downloadIncentive(currentIncentive.details_url)" target="_blank" class="text-blue-500">
                                    {{ incentiveFilename ?? null }}
                                </a>
                            </div>
                            <div v-if="currentIncentive.details_url" class="flex justify-end items-end gap-x-2">
                                <div
                                    @click="deleteMedia({type:'incentive', id:currentIncentive.id})"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Delete this file"
                                    class="hover:opacity-50 cursor-pointer flex items-center"
                                >
                                    <icon class="text-red-500 cursor-pointer" type="delete" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                                <div
                                    @click="browseForIncentive"
                                    v-tippy="{
                                                theme: 'translucent',
                                                arrow: true,
                                                touch: false,
                                                placement: 'top',
                                            }"
                                    content="Change file"
                                    :class="`hover:opacity-50 cursor-pointer flex items-center`"
                                >
                                    <icon class="text-blue-500 cursor-pointer" type="edit" view-box="0 0 24 24" width="20" height="20" />
                                </div>
                            </div>
                            <div
                                v-else
                                @click="browseForIncentive"
                                v-tippy="{
                                        theme: 'translucent',
                                        arrow: true,
                                        touch: false,
                                        placement: 'top',
                                    }"
                                content="Add new file"
                                class="hover:opacity-50 cursor-pointer flex items-center"
                            >
                                <icon class="text-green-300 cursor-pointer" type="add" view-box="0 0 24 24" width="20" height="20" />
                                <div class="whitespace-no-wrap">Add file</div>
                            </div>
                            <input class="hidden" type="file" name="incentive" @change="uploadIncentive">
                        </div>
                        <div class="my-4 bg-grey-500 h-px w-full">&nbsp;</div>
                        <div class="text-xs text-grey-800">Type <span class="text-red-500 text-xs">(required)</span></div>
                        <select v-model="currentIncentive.type" name="type" id="type" class="px-3 py-2 mt-1 block w-full" required>
                            <option :value="null">Select...</option>
                            <option value="SPIFF">SPIFF</option>
                            <option value="Rebate">Rebate</option>
                            <option value="Promotion">Promotion</option>
                            <option value="Showroom Sample">Showroom Sample</option>
                            <option value="Working Sample">Working Sample</option>
                        </select>
                        <div class="mt-3 text-xs text-grey-800">Description <span class="text-red-500 text-xs">(required)</span></div>
                        <textarea v-model="currentIncentive.description" name="description" id="description" rows="3" class="px-3 py-2 mt-1 block w-full" required :placeholder="currentIncentive.description" />
                    </div>
                    <div class="flex justify-end gap-x-2">
                        <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded">Update</button>
                    </div>
                </form>
            </div>
        </div>

        <form v-if="!hideForm && (!creating && !editing)" @submit.prevent="signoff" class="mt-6 px-4 container mx-auto">
            <textarea v-model="reviewerNotes" name="notes" id="notes" rows="3" class="px-3 py-2 block w-full" placeholder="Add any notes you would like to include here." />
            <div class="flex justify-end items-center my-4">
                <input id="reviewed" type="checkbox" v-model="reviewed" class="mr-2">
                <label for="reviewed" class="text-sm">I have reviewed all vendor information and agree that it is correct.</label>
            </div>

            <div class="mt-4 flex justify-end gap-x-2">
<!--                <button @click.prevent="reset" type="button" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>-->
                <a :href="`/vendors/${vendor.id}/specifications/pdf`" target="_blank" class="px-4 py-2 bg-grey-500 text-white rounded">PDF Preview</a>
                <button :type="[reviewed ? 'submit' : 'button']" :class="[reviewed ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Submit</button>
            </div>
        </form>

        <div v-else class="mt-6 px-4 container mx-auto flex justify-end">
            <a href="/dashboard/vendors" type="button" class="px-4 py-2 bg-blue-500 text-white rounded" >Done</a>
        </div>

        <div v-if="deleting" class="fixed inset-0 flex items-center justify-center bg-grey-800 bg-opacity-75">
            <div class="bg-white p-6 rounded shadow-lg">
                <h2 class="text-xl mb-4">Confirm Deletion</h2>
                <p>Are you sure you want to delete this item?</p>
                <div class="mt-4 flex justify-end gap-x-2">
                    <button @click="reset" class="px-4 py-2 bg-grey-500 text-white rounded">Cancel</button>
                    <button @click="confirmDelete" class="px-4 py-2 bg-red-500 text-white rounded">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapActions, mapGetters, mapMutations } from 'vuex'
/** Composition */
import ValidationMixins from '../mixins/validation-mixins'

export default {
    /** Composition */
    mixins: [ValidationMixins],
    /** Props */
    props: {
        user: {
            type: Object,
            required: true
        },
        vendor: {
            type: Object,
            required: true
        },
        specifications: {
            type: Object|Array,
            required: true
        }
    },
    /** Local State */
    data() {
        return {
            name: null,
            email: null,
            creating: null,
            editing: null,
            deleting: null,
            currentDiscount: null,
            currentPricer: null,
            currentContact: null,
            currentCharge: null,
            currentIncentive: null,
            newPricer: {
                vendor_id: this.vendor.id,
                name: null,
                description: null,
                details: null,
                details_url: null,
                pricer: null,
            },
            newContact: {
                vendor_id: this.vendor.id,
                label: 'Customer Care',
                name: null,
                email: null,
                phone: null,
                details: null,
                details_url: null,
                contact: null,
            },
            newCharge: {
                vendor_id: this.vendor.id,
                type: null,
                description: null,
                details: null,
                details_url: null,
                charge: null,
            },
            newIncentive: {
                vendor_id: this.vendor.id,
                type: null,
                description: null,
                details: null,
                details_url: null,
                incentive: null,
            },
            reviewed: false,
            reviewerNotes: null,
        }
    },

    computed: {
        isOwner() {
            return this.user.email === 'chris@connexionsai.com';
        },

        hideForm() {
            return window.location.pathname.split('/').pop() === 'show' && ! this.isOwner;
        },

        appDomain() {
            return window.location.origin;
        },

        storagePath() {
            return window.location.origin + '/storage/';
            // return "https://connexionsai.s3.us-east-2.amazonaws.com/";
        },

        newPricerFilename() {
            return this.newPricer.pricer ? this.newPricer.pricer.name : null;
        },

        newContactFilename() {
            return this.newContact.contact ? this.newContact.contact.name : null;
        },

        newChargeFilename() {
            return this.newCharge.charge ? this.newCharge.charge.name : null;
        },

        newIncentiveFilename() {
            return this.newIncentive.incentive ? this.newIncentive.incentive.name : null;
        },

        pricerFilename() {
            return this.currentPricer.file?.slice(23);
        },

        contactFilename() {
            return this.currentContact.details_url?.slice(24);
        },

        chargeFilename() {
            return this.currentCharge.details_url?.slice(23);
        },

        incentiveFilename() {
            return this.currentIncentive.details_url?.slice(26);
        },

        newPricerReady() {
            return this.newPricer.name && this.newPricer.pricer;
        },

        newContactReady() {
            return this.newContact.name && (this.newContact.email || this.newContact.phone || this.newContact.contact);
        },

        newChargeReady() {
            return this.newCharge.type && this.newCharge.description;
        },

        newIncentiveReady() {
            return this.newIncentive.type && this.newIncentive.description;
        },

        /** Store Getters */
        ...mapGetters([
            'processing',
        ])
    },

    /** Non-Reactive Properties */
    methods: {

        displayableDiscount(discount) {

            if (discount.base_factor == 100) {
                return "Net Priced";
            }

            let displayable = [discount.base_factor];

            [discount.first_tier, discount.second_tier, discount.third_tier].forEach(next => {
                if (next) {
                    displayable.push(`/${next}`);
                }
            });

            let factor = ` (${this.discountMultiplier(discount)})`;

            return `${displayable.join('')}${factor}`;
        },

        discountMultiplier(discount) {
            // Base multiplier.
            let multiplier = ((100 - discount.base_factor) / 100);
            // With first tier discount.
            multiplier = discount.first_tier
                ? multiplier * ((100 - discount.first_tier) / 100)
                : multiplier;
            // With second tier discount.
            multiplier = discount.second_tier
                ? multiplier * ((100 - discount.second_tier) / 100)
                : multiplier;
            // With third tier discount.
            multiplier = discount.third_tier
                ? multiplier * ((100 - discount.third_tier) / 100)
                : multiplier;
            // Return rounded multiplier with 4 decimal limit.
            return Math.round(multiplier * 10000) / 10000;
        },

        async editPricer(pricer) {
            this.currentPricer = await pricer;
            this.editing = 'pricer';
        },

        async deletePricer(pricer) {
            this.currentPricer = await pricer;
            this.deleting = 'pricer';
        },

        async editContact(contact) {
            this.currentContact = await contact;
            this.editing = 'contact';
        },

        async deleteContact(contact) {
            this.currentContact = await contact;
            this.deleting = 'contact';
        },

        async editCharge(charge) {
            this.currentCharge = await charge;
            this.editing = 'charge';
        },

        async deleteCharge(charge) {
            this.currentCharge = await charge;
            this.deleting = 'charge';
        },

        async editIncentive(incentive) {
            this.currentIncentive = await incentive;
            this.editing = 'incentive';
        },

        async deleteIncentive(incentive) {
            this.currentIncentive = await incentive;
            this.deleting = 'incentive';
        },

        deleteMedia(data) {
            this.deleteMediaRequest({data});
        },

        async signoff() {
            await this.signoffRequest({
                vendor_id: this.vendor.id,
                reviewed: this.reviewed,
                reviewerNotes: this.reviewerNotes ?? '',
            });
        },

        reset() {
            this.currentPricer = null;
            this.currentContact = null;
            this.currentCharge = null;
            this.currentIncentive = null;
            this.creating = null;
            this.editing = null;
            this.deleting = null;
            this.reviewed = false;
        },

        downloadPricer(id) {
            return this.appDomain + `/nova-api/pricers/${id}/download/file`;
        },

        pricerDetailsLink(path) {
            if (path.includes('@') && !path.startsWith('mailto:')) {
                return 'mailto:' + path;
            }
            if (path.startsWith('mailto:')) {
                return path;
            }
            return path.startsWith('http') ? path : `http://${path}`;
        },

        downloadContact(url) {
            return this.storagePath + url;
        },

        downloadCharge(url) {
            return this.storagePath + url;
        },

        downloadIncentive(url) {
            return this.storagePath + url;
        },

        async updateDiscount() {
            await this.discountUpdateRequest(this.currentDiscount);
        },

        async storePricer() {
            await this.pricerStoreRequest(this.newPricer);
        },

        async updatePricer() {
            await this.pricerUpdateRequest(this.currentPricer);
        },

        async storeContact() {
            await this.contactStoreRequest(this.newContact);
        },

        async updateContact() {
            await this.contactUpdateRequest(this.currentContact);
        },

        async storeCharge() {
            await this.chargeStoreRequest(this.newCharge);
        },

        async updateCharge() {
            await this.chargeUpdateRequest(this.currentCharge);
        },

        async storeIncentive() {
            await this.incentiveStoreRequest(this.newIncentive);
        },

        async updateIncentive() {
            await this.incentiveUpdateRequest(this.currentIncentive);
        },

        browseForPricer() {
            document.querySelector('input[name="pricer"]').click()
        },

        browseForContact() {
            document.querySelector('input[name="contact"]').click()
        },

        browseForCharge() {
            document.querySelector('input[name="charge"]').click()
        },

        browseForIncentive() {
            document.querySelector('input[name="incentive"]').click()
        },

        uploadPricer(event) {
            if (event.target.files.length > 0) {
                this.setProcessing(true);
                let formData = new FormData();
                formData.append('pricer', event.target.files[0]);
                axios
                    .post(`/api/specifications/pricers/${this.currentPricer.id}/upload`, formData)
                    .then(() => this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'Pricer uploaded successfully.',
                        duration: 5000,
                    }))
                    .then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
                    .catch(error => {
                        console.error('Pricer upload failed:', error);
                        this.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: 'Unable to upload pricer. Reload and try again.',
                            duration: 5000,
                        });
                        this.setProcessing(false);
                    });
            } else {
                console.warn('No file selected for upload.');
            }
        },

        uploadForNewPricer(event) {
            if (event.target.files.length > 0) {
                this.newPricer.pricer = event.target.files[0];
            } else {
                console.warn('Unable to add file. Reload page and try again.');
            }
        },

        uploadContact(event) {
            if (event.target.files.length > 0) {
                this.setProcessing(true);
                let formData = new FormData();
                formData.append('contact', event.target.files[0]);
                axios
                    .post(`/api/specifications/contacts/${this.currentContact.id}/upload`, formData)
                    .then(() => this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'Contact uploaded successfully.',
                        duration: 5000,
                    }))
                    .then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
                    .catch(error => {
                        console.error('Contact upload failed:', error);
                        this.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: 'Unable to upload contact. Reload and try again.',
                            duration: 5000,
                        });
                        this.setProcessing(false);
                    });
            } else {
                console.warn('No file selected for upload.');
            }
        },

        uploadForNewContact(event) {
            if (event.target.files.length > 0) {
                this.newContact.contact = event.target.files[0];
            } else {
                console.warn('Unable to add file. Reload page and try again.');
            }
        },

        uploadCharge(event) {
            if (event.target.files.length > 0) {
                this.setProcessing(true);
                let formData = new FormData();
                formData.append('charge', event.target.files[0]);
                axios
                    .post(`/api/specifications/charges/${this.currentCharge.id}/upload`, formData)
                    .then(() => this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'Charge uploaded successfully.',
                        duration: 5000,
                    }))
                    .then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
                    .catch(error => {
                        console.error('Charge upload failed:', error);
                        this.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: 'Unable to upload charge. Reload and try again.',
                            duration: 5000,
                        });
                        this.setProcessing(false);
                    });
            } else {
                console.warn('No file selected for upload.');
            }
        },

        uploadForNewCharge(event) {
            if (event.target.files.length > 0) {
                this.newCharge.charge = event.target.files[0];
            } else {
                console.warn('Unable to add file. Reload page and try again.');
            }
        },

        uploadIncentive(event) {
            if (event.target.files.length > 0) {
                this.setProcessing(true);
                let formData = new FormData();
                formData.append('incentive', event.target.files[0]);
                axios
                    .post(`/api/specifications/incentives/${this.currentIncentive.id}/upload`, formData)
                    .then(() => this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'Incentive uploaded successfully.',
                        duration: 5000,
                    }))
                    .then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
                    .catch(error => {
                        console.error('Incentive upload failed:', error);
                        this.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: 'Unable to upload incentive. Reload and try again.',
                            duration: 5000,
                        });
                        this.setProcessing(false);
                    });
            } else {
                console.warn('No file selected for upload.');
            }
        },

        uploadForNewIncentive(event) {
            if (event.target.files.length > 0) {
                this.newIncentive.incentive = event.target.files[0];
            } else {
                console.warn('Unable to add file. Reload page and try again.');
            }
        },

        async confirmDelete() {
            if (this.deleting === 'pricer') {
                await this.pricerDeleteRequest(this.currentPricer);
            }
            else if (this.deleting === 'contact') {
                await this.contactDeleteRequest(this.currentContact);
            }
            else if (this.deleting === 'discount') {
                window.location.reload();
            }
            else if (this.deleting === 'charge') {
                await this.chargeDeleteRequest(this.currentCharge);
            }
            else if (this.deleting === 'incentive') {
                await this.incentiveDeleteRequest(this.currentIncentive);
            }
            this.deleting = null;
        },

        /** Store Actions */
        ...mapActions([
            'signoffRequest',
            'deleteMediaRequest',
            'discountUpdateRequest',
            'pricerStoreRequest',
            'pricerUpdateRequest',
            'pricerDeleteRequest',
            'contactStoreRequest',
            'contactUpdateRequest',
            'contactDeleteRequest',
            'chargeStoreRequest',
            'chargeUpdateRequest',
            'chargeDeleteRequest',
            'incentiveStoreRequest',
            'incentiveUpdateRequest',
            'incentiveDeleteRequest',
        ]),

        /** Store Mutations */
        ...mapMutations({
            setBudgetPackages: 'SET_BUDGET_PACKAGES',
            setProcessing: 'SET_PROCESSING',
            setForm: 'SET_FORM',
            setNotification: 'SET_NOTIFICATION',
        })
    },
}
</script>
<style scoped>
@media print {
    .container {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    /* Add more print-specific styles as needed */
}
</style>
