<template>
    <div>
        <div 
            @click="toggle()"
            :dusk="`favorite-button-${id}`" 
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            :content="tip"
            :class="classes"
        >
            <icon class="fill-current" type="favorite" view-box="0 0 24 24" :width="width" :height="height" /> 
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    /** Interface */
    props: {
        /**
         * The resource id.
         * 
         * @var {string|number}
         */
        id: {
            type: String|Number,
            required: true,
        },
        /**
         * The resource type [products, environments]
         * 
         * @var {string}
         */
        // type: {
        //     type: String,
        //     required: false,
        //     default: null,
        // },
        /**
         * The widget active state. 
         * 
         * @var {string}
         */
        active: {
            type: String,
            required: false,
            default: 'bg-red-200 text-grey-100'
        },
        /**
         * The widget inactive state. 
         * 
         * @var {string}
         */
        inactive: {
            type: String,
            required: false,
            default: 'bg-transparent text-grey-600'
        },
        /**
         * The widget width state. 
         * 
         * @var {string}
         */
        width: {
            type: Number|String,
            required: false,
            default: 18
        },
        /**
         * The widget height state. 
         * 
         * @var {string}
         */
        height: {
            type: Number|String,
            required: false,
            default: 18
        },
    },
    /** Local State */
    data() {
        return {
            favoriteIds: [],
        }
    },
    computed: {
        classes() {
            let base = 'w-8 h-8 mr-1 rounded-full hover:opacity-50 flex items-center justify-center cursor-pointer '
            return this.canRemove
                ? base + this.active
                : base + this.inactive
        },
        /**
         * Determine the favoriteable_type for store actions.
         * 
         * @return {string|null} The favoriteable_type
         */
        // favoriteableType() {
        //     if (this.shareableType) {
        //         return this.shareableType === 'environments'
        //             ? 'App\\Services\\Product\\Models\\Environment'
        //             : 'App\\Services\\Product\\Models\\Product' 
        //     }
        // },

        /**
         * Show the resource as available to add as favorite.
         * 
         * @return {boolean} True of false.
         */
        canAdd() {
            if (this.id && this.favoriteIds && this.favoriteIds.length) {
                return ! this.favoriteIds.includes(parseInt(this.id))
            }
            return true
        },
        /**
         * Show the resource as available to remove from favorites.
         * 
         * @return {boolean} True of false.
         */
        canRemove() {
            if (this.id && this.favoriteIds && this.favoriteIds.length) {
                return this.favoriteIds.includes(parseInt(this.id))
            }
            return false
        },

        /**
         * The tip for the widget.
         * 
         * @return {string}
         */
        tip() {
            return this.canAdd
                ? '<span class="text-red-200">Favorite</span> this item'
                : '<span class="text-red-200">Remove</span> as a favorite'
        },

        /** Store Getters */
        ...mapGetters(['favorites', 'shareableType'])
    },

    /** Lifecycle Events */
    mounted() {
        this.setFavoriteIds()
    },
    /** Events */
    watch: {
        favorites: {
            deep: true,
            handler() {
                this.setFavoriteIds()
            }
        },
    },

    /** Non-Reactive Properties */
    methods: {

        /** The visitor’s favorite ids. */
        setFavoriteIds() {
            let ids = []
            if (this.favorites) {
                _.each(this.favorites, favorite => {
                    return favorite.favoriteable
                        ? ids.push(favorite.favoriteable.id)
                        : ids.push(favorite.favoriteable_id)
                })
            }
            this.favoriteIds = ids
        },

        /**
         * Toggle favorite selection.
         * 
         * @return {void}
         */
        toggle() {  
            if (this.id && /* this.favoriteableType */ this.shareableType) {
                let params = {
                    favoriteable_id: this.id, 
                    favoriteable_type: this.shareableType /* this.favoriteableType */
                }
                return this.canAdd
                    ? this.addFavorite(params)
                    : this.removeFavorite(params)
            }
        },

        /** Store Actions */
        ...mapActions(['removeFavorite', 'addFavorite']),
    }
}
</script>