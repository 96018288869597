<template>
    <!-- <transition name="fade" mode="out-in"> -->
        <div v-show=" ! scrolling" class="w-screen mt-8 flex justify-center items-center bg-white relative top-0 z-16">    
            <div dusk="header-brand" class="w-screen h-24 px-4 flex items-center justify-between">
                <div v-if="hasLogo || hasTagline" class="flex flex-col">
                    <a href="/" target="_self">
                        <tip-widget :content="`<span class='text-blue-100'>Go</span> to homepage`">
                            <img v-if="hasLogo" class="w-48" :src="`/storage/${dealer.logo_path}`"/>
                            <div v-else class="pt-4 md:pt-0 text-2xl md:text-3xl xl:text-4xl text-grey-600 hover:text-grey-600 leading-none tracking-tight font-regular flex items-baseline">
                                {{store}}
                            </div>
                        </tip-widget>
                        <div v-if="hasTagline" class="p-1 text-sm md:text-lg text-grey-600 leading-none tracking-normal">{{hasTagline}}</div>
                    </a>
                </div>
                <div v-else class="flex flex-col">
                    <a href="/" target="_self">
                        <tip-widget :content="`<span class='text-blue-100'>Go</span> to homepage`">
                            <div class="pt-4 md:pt-0 text-2xl md:text-3xl xl:text-4xl text-grey-600 hover:text-grey-600 leading-none tracking-tight font-regular flex items-baseline">
                                {{store}}<small v-if="location" class="hidden xl:flex">&nbsp;–&nbsp;{{location}}</small>
                            </div>
                        </tip-widget>
                        <div v-if="store !== 'Connexions'" class="p-1 text-sm md:text-lg text-grey-600 leading-none tracking-normal">A Connexions Dealer</div>
                    </a>
                </div>
                <div class="h-full text-lg text-grey-600 hover:text-grey-600 hidden md:flex justify-end items-center">
                    <div 
                        dusk="main-nav-products"
                        @click.stop="showProducts" 
                        @touchstart="showProducts" 
                        :class="{'border-grey-600': panel === 'products'}"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Explore</span> products`">Products</tip-widget>
                    </div>
                    <div 
                        dusk="main-nav-environments"
                        @click.stop="showEnvironments"
                        @touchstart="showEnvironments"
                        :class="{'border-grey-600': panel === 'environments'}"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Explore</span> environments`">Environments</tip-widget>
                    </div>
                    <a 
                        v-if=" ! isNationalAccount"
                        href="/catalog"
                        target="_blank"
                        dusk="main-nav-catalog"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Browse</span> ${currentIdeaBook} Idea Book`">Idea Book</tip-widget>
                    </a>
                    <div 
                        dusk="main-nav-brands"
                        v-if=" ! isNationalAccount"
                        @click.stop="showBrands" 
                        @touchstart="showBrands" 
                        :class="{'border-grey-600': panel === 'brands'}"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Explore</span> brands`">Brands</tip-widget>
                    </div>
                    <a 
                        v-if=" ! isNationalAccount"
                        href="/mrl"
                        target="_blank"
                        dusk="main-nav-mrl"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Go to</span> MRL`">MRL</tip-widget>
                    </a>
                    <!-- <div
                        dusk="main-nav-budget"
                        v-if=" ! isNationalAccount"
                        @click.stop="showBudget" 
                        @touchstart="showBudget" 
                        :class="{'border-grey-600': panel === 'budget'}"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Create</span> Instant Budget™`">Budget</tip-widget>
                    </div> -->
                    <!-- <div 
                        dusk="main-nav-resources"
                        @click.stop="showResources" 
                        @touchstart="showResources" 
                        :class="{'border-grey-600': panel === 'resources'}"
                        class="h-full px-4 pt-3 flex items-center border-transparent border-b-2 hover:border-grey-600 cursor-pointer"
                    >
                        <tip-widget :content="`<span class='text-blue-100'>Access</span> resources`">Resources</tip-widget>
                    </div> -->
                </div>
            </div>        
        </div>
    <!-- </transition> -->
</template>
<script>
/** Vuex Store */
import { mapGetters } from 'vuex'
/** Composition */
import NavigationMixins from '../../../mixins/navigation-mixins'
import CatalogMixins from '../../../mixins/catalog-mixins'

export default {
    /** Composition */
    mixins: [NavigationMixins, CatalogMixins],

    /** Template Dependencies */
    props: ['store', 'location'],

    /** Local State */
    computed: {
        /**
         * Determine if current dealer is a national account.
         * 
         * @return {boolean}
         */
        isNationalAccount() {
            if (this.dealer && this.dealer.custom_properties) {
                return this.dealer.custom_properties.national == 'true' || this.dealer.custom_properties.national == true
            }
            return false
        },
        
        /**
         * Determine if current dealer should show a logo graphic.
         * 
         * @return {boolean}
         */
        hasLogo() {
            if (this.dealer && this.dealer.custom_properties) {
                return this.dealer.custom_properties.logo == 'true' || this.dealer.custom_properties.logo == true
            }
            return false
        },
        
        /**
         * Determine if current dealer has a tagline.
         * 
         * @return {boolean}
         */
        hasTagline() {
            if (this.dealer && this.dealer.custom_properties && this.dealer.custom_properties.tagline) {
                return this.dealer.custom_properties.tagline
            }
            return false
        },

        /** Store Getters */
        ...mapGetters(['scrolling', 'dealer'])
    },
}
</script>