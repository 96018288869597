const SpecificationModule = {
    state: {},

    getters: {},

    actions: {
        reviewRequest({ commit }, params) {
            axios
                .post(`/api/specifications/vendor/${params.vendor_id}/review/request`, params)
                .then(() => {
                    commit('SET_NOTIFICATION', {
                        type: 'success',
                        title: 'Success',
                        message: 'Request sent.',
                        duration: 5000,
                    });
                })
                .catch(error => {
                    console.error('Signoff failed:', error);
                    commit('SET_NOTIFICATION', {
                        type: 'error',
                        title: 'Error',
                        message: 'Unable to complete. Reload and try again.',
                        duration: 10000,
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location = `/dashboard/vendors`;
                    }, 3000);
                });
        },

        signoffRequest({ commit }, params) {
            axios
                .post('/api/specifications/signoff', params)
                .then(() => {
                    window.location = `/vendors/${params.vendor_id}/specifications/complete`;
                })
                .catch(error => {
                    console.error('Signoff failed:', error);
                    commit('SET_NOTIFICATION', {
                        type: 'error',
                        title: 'Error',
                        message: 'Unable to complete. Reload and try again.',
                        duration: 10000,
                    });
                });
        },

        deleteMediaRequest({ commit }, params) {
            commit('SET_PROCESSING', true)
            axios
                .delete(`/api/specifications/media/${params.data.type}/${params.data.id}/delete`)
                .then(response => {
                    console.log('Media deleted:', response.data.message);
                    commit('SET_NOTIFICATION', {
                        type: 'success',
                        title: 'Success',
                        message: response.data.message,
                        duration: 5000,
                    });
                })
                .catch(error => {
                    console.error('Media delete failed:', error);
                    commit('SET_NOTIFICATION', {
                        type: 'error',
                        title: 'Error',
                        message: 'Unable to complete. Reload and try again.',
                        duration: 10000,
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist a discount update request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        discountUpdateRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .patch(`/api/specifications/discounts/${params.id}/update`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Discount updated',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."
                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });

        },

        /**
         * Persist a pricer store request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        pricerStoreRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            let formData = new FormData();
            formData.append('vendor_id', params.vendor_id);
            formData.append('name', params.name);
            params.description ? formData.append('description', params.description) : null;
            params.details ? formData.append('details', params.details) : null;
            params.details_url ? formData.append('details_url', params.details_url) : null;
            params.pricer ? formData.append('pricer', params.pricer) : null;

            if (params.pricer) {
                axios
                    .post(`/api/specifications/pricers/store`, formData, )
                    .then(() => commit('SET_NOTIFICATION', {
                        type: 'success',
                        title: 'Success',
                        message: 'Pricer added',
                        duration: 5000,
                    }))
                    .catch(errors => {
                        console.log(errors)
                        let message = errors.response && errors.response.status === 419
                            ? "Session Expired. Please reload the page and try again."
                            : "The system is down for a short period of maintenance. Please try again later."

                        commit('SET_NOTIFICATION', {
                            type: 'warning',
                            title: 'Unable to complete',
                            message,
                            duration: 5000,
                        })
                    })
                    .finally(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
            }
            else {
                commit('SET_PROCESSING', false);
                commit('SET_NOTIFICATION', {
                    type: 'warning',
                    title: 'Error.',
                    message: 'Please add a pricer file',
                    duration: 10000,
                });
            }

        },

        /**
         * Persist a pricer update request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        pricerUpdateRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .patch(`/api/specifications/pricers/${params.id}/update`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Pricer updated',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });

        },

        /**
         * Persist a pricer delete request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        pricerDeleteRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .delete(`/api/specifications/pricers/${params.id}/delete`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Pricer deleted',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist a contact store request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        contactStoreRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            let formData = new FormData();
            formData.append('vendor_id', params.vendor_id);
            formData.append('label', params.label);
            params.name ? formData.append('name', params.name) : null;
            params.email ? formData.append('email', params.email) : null;
            params.phone ? formData.append('phone', params.phone) : null;
            params.details ? formData.append('details', params.details) : null;
            params.details_url ? formData.append('details_url', params.details_url) : null;
            params.contact ? formData.append('contact', params.contact) : null;

            axios
                .post(`/api/specifications/contacts/store`, formData, )
                .then(() => {
                    commit('SET_NOTIFICATION', {
                        type: 'success',
                        title: 'Success',
                        message: 'Contact added',
                        duration: 5000,
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                })
                .catch(errors => {
                    console.log(errors)

                    commit('SET_NOTIFICATION', {
                        type: 'error',
                        title: 'Error',
                        message: errors.response.data.message,
                        duration: 60000,
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 60000);
                });
        },

        /**
         * Persist a contact update request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        contactUpdateRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .patch(`/api/specifications/contacts/${params.id}/update`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Contact updated',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });

        },

        /**
         * Persist a charge delete request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        contactDeleteRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .delete(`/api/specifications/contacts/${params.id}/delete`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Contact deleted',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist a charge store request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        chargeStoreRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            let formData = new FormData();
            formData.append('vendor_id', params.vendor_id);
            formData.append('type', params.type);
            params.description ? formData.append('description', params.description) : null;
            params.details ? formData.append('details', params.details) : null;
            params.details_url ? formData.append('details_url', params.details_url) : null;
            params.charge ? formData.append('charge', params.charge) : null;


            axios
                .post(`/api/specifications/charges/store`, formData, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Charge added',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist a charge update request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        chargeUpdateRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .patch(`/api/specifications/charges/${params.id}/update`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Charge updated',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });

        },

        /**
         * Persist a charge delete request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        chargeDeleteRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .delete(`/api/specifications/charges/${params.id}/delete`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Charge deleted',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist an incentive store request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        incentiveStoreRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            let formData = new FormData();
            formData.append('vendor_id', params.vendor_id);
            formData.append('type', params.type);
            params.description ? formData.append('description', params.description) : null;
            params.details ? formData.append('details', params.details) : null;
            params.details_url ? formData.append('details_url', params.details_url) : null;
            params.incentive ? formData.append('incentive', params.incentive) : null;

            axios
                .post(`/api/specifications/incentives/store`, formData, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Incentive added',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

        /**
         * Persist a incentive update request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        incentiveUpdateRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .patch(`/api/specifications/incentives/${params.id}/update`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Incentive updated',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                    let message = errors.response && errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to complete',
                        message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });

        },

        /**
         * Persist a incentive delete request to the database.
         *
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        incentiveDeleteRequest({ commit }, params) {
            commit('SET_PROCESSING', true)

            console.log({params});
            axios
                .delete(`/api/specifications/incentives/${params.id}/delete`, params, )
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Incentive deleted',
                    duration: 5000,
                }))
                .catch(errors => {
                    console.log(errors)
                })
                .finally(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },

    },

    mutations: {}
}

export default SpecificationModule
