// App
window.APP_URL = 'https://connexionsai.com'
// Services
window.ALGOLIA_APP_ID = "RV8W842V5O"
window.ALGOLIA_APP_KEY = "05c3c274266d6467959b7ec1fbf6df1d"

// Local environment settings
if (process.env.NODE_ENV !== 'production') {
    window.APP_URL = 'http://connexions.test'
    window.ALGOLIA_APP_ID = "BUV0LORF7P"
    window.ALGOLIA_APP_KEY = "c87d41b0152b09643e7cf9ac99ea0429"
}

const element = document.getElementsByName('version')
if (element && element.length) {
    window.VERSION = element[0].getAttribute('content')
} else {
    window.VERSION = 8
}
