<template>
    <div class="flex-1 h-12 px-2 flex items-center ">
        <div    
            v-if="noneSelected"
            @click="toggleSelectAll()"              
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            content="Select All"
            class="hover:opacity-50 cursor-pointer"
        >
            <icon type="check-off" height="24" view-box="0 0 24 24" width="24" class="fill-current text-grey-600" />
        </div>
        <div 
            v-if="someSelected"
            @click="toggleSelectAll()" 
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            content="Select All"
        >
            <icon type="check-mixed" height="24" view-box="0 0 24 24" width="24" class="fill-current text-blue-200" />
        </div>
        <div
            v-if="allSelected"
            @click="toggleSelectAll()" 
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            content="Deselect All" 
        >
            <icon type="check-on" height="24" view-box="0 0 24 24" width="24" class="fill-current text-blue-200" />
        </div>
        <div v-text="selectLabel" class="ml-2 text-sm text-grey-600"></div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Local State */
    computed: {
        /**
         * Show no favorites are currently selected.
         * 
         * @return {boolean}
         */
        noneSelected() {
            if (this.selected) {
                return this.selected.length < 1
            }
            return true
        },

        /**
         * Show some favorites are currently selected.
         * 
         * @return {boolean}
         */
        someSelected() {
            if (this.selected && this.favorites) {
                return this.selected.length > 0 && this.selected.length < this.favorites.length
            }
            return false
        },

        /**
         * Show all favorites are currently selected.
         * 
         * @return {boolean}
         */
        allSelected() {
            if (this.selected && this.favorites) {
                return this.selected.length === this.favorites.length
            }
            return false
        },

        /**
         * Return the select label state.
         * 
         * @return {string} The select label.
         */
        selectLabel() {
            return this.allSelected 
                ? 'Deselect All'
                : 'Select All'
        },

        /** Store Getters */
        ...mapGetters(['selected', 'favorites', 'shareables'])
    },
    /** Non-Reaxctive Properties */
    methods: {
        /**
         * Toggle selection of all favorites.
         * 
         * @return {void}
         */
        toggleSelectAll() {

            let selected = []

            if (this.allSelected) {
                this.setSelected(selected)
                return 
            } 

            _.each(this.favorites, favorite => {
                selected = _.concat(selected, favorite.id)
            })            

            this.setSelected(selected)
        },

        /** Store Mutators */
        ...mapMutations({
            setSelected: 'SET_SELECTED'
        })
    },
}
</script>