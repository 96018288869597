const MapModule = {
    state: {
        /**
         * The mappables array.
         * 
         * @var {array}
         */
        mappables: null,
    },

    getters: {
        /**
         * Get the current mappables state.
         * 
         * @return {array}
         */
        mappables({ mappables }) {
            return mappables
        },
    },

    actions: {
        /**
         * Fetch the mappable for a given map.
         * 
         * @param {object} dispatch The store dispatch module.
         * @param {number} id The map id.
         * @return {void}
         */
        // getMappable({ dispatch}, id) {
        //     axios 
        //         .get(`/api/maps/${id}/mappable`)
        //         .then(response => dispatch('pushToMappables', response.data))
        //         .catch(errors => console.warn(errors))
        // },

        fetchMappables({ commit }, id) {
            axios 
                .get(`/api/media/${id}/mappables`)
                .then(response => commit('SET_MAPPABLES', response.data.data))
                .catch(errors => console.warn(errors))
        },

        /**
         * Push a given mappable onto the mappables array.
         * 
         * @param {object} state The store state module.
         * @param {object} commit The store commit module.
         * @param {object} mappable The mappable to add to the array.
         * @return {void}
         */
        // pushToMappables({ state, commit }, mappable) {
        //     let mappables = state.mappables.push(mappable)
        //     commit('SET_MAPPABLES', mappables)
        // },
    },
    mutations: {
        /**
         * Set the mappables state. 
         * 
         * @param {object} state The store state module.
         * @param {array} mappables The array of mappables.
         * @return {void}
         */
        SET_MAPPABLES(state, mappables) {
            state.mappables = mappables
        },
    }
}

export default MapModule