<template>
<div class="w-screen min-h-screen relative"><!-- :class="{'z-16 fixed': form === 'share', 'z-1': form !== 'share'}"  -->
    <!-- <share-form 
        dusk="share-form-component"
        :selected="selected"
        :shareables="favorites"
        :type="type"
        :sender-name="senderName"
        :sender-email="senderEmail"
        :sender-id="senderId"
        :recipients="recipients"
    ></share-form> -->

    <div class="container mx-auto py-4">
        <div class="w-full flex items-center justify-between">
            <select-toggle dusk="select-toggle-component"></select-toggle>
            <favorite-actions dusk="favorite-actions-component"></favorite-actions>
        </div>
        <div 
            v-for="(favorite, index) in favorites"
            @click.self.stop="toggleSelect(favorite.id)"
            :key="index"
            :class="{
                'bg-grey-100, text-grey-400': selected && selected.includes(favorite.id),
                'text-grey-600': ! selected || ! selected.includes(favorite.id),
            }"
            class="w-full h-48 my-2 p-0 bg-white rounded shadow-sm flex justify-between items-center"
        >
            <div
                @click.stop="toggleSelect(favorite.id)"
                v-tippy="{ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }"
                content="Deselect" 
                v-if="selected && selected.includes(favorite.id)"
                class="px-2 h-full bg-neutral-20 rounded-l flex items-center justify-center relative left-0 inset-y-auto"
            >
                <icon type="check-on" view-box="0 0 24 24" width="24" height="24" class="fill-current text-blue-200 bg-neutral-20 hover:opacity-50 " />
            </div>
            <div
                @click.stop="toggleSelect(favorite.id)"
                v-tippy="{ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }"
                content="Select" 
                v-if="! selected || ! selected.includes(favorite.id)"
                class="px-2 h-full bg-neutral-20 rounded-l flex items-center justify-center relative left-0 inset-y-auto"
            >
                <icon type="check-off" view-box="0 0 24 24" width="24" height="24" class="fill-current bg-neutral-20 hover:opacity-50" />
            </div>
            <div 
                :style="`background-image: url('${favorite.url}')`" 
                :class="{'opacity-50':selected && selected.includes(favorite.id)}" 
                class="w-64 h-full px-6 py-3 rounded-l bg-grey-400 bg-cover bg-center bg-no-repeat relative" 
            ></div>
            <div class="flex-grow h-full px-6 flex justify-between items-center">
                <div class="w-5/6 h-full flex">
                    <div class="flex-1 p-4 flex flex-col justify-center">
                        <div v-if="! applications(favorite.favoriteable).length" class="mb-3 text-sm font-medium leading-tight opacity-100">{{categories(favorite.favoriteable)}}</div> 
                        <div v-if="applications(favorite.favoriteable).length" class="mb-3 text-sm font-medium leading-tight opacity-100">{{applications(favorite.favoriteable)}}</div> 
                        <div class="text-xs font-medium leading-tight opacity-50">{{vendorName(favorite.favoriteable)}}</div>
                        <div class="text-base  font-medium leading-tight">{{groupName(favorite.favoriteable)}}</div>
                    </div>
                    <div class="flex-1 p-4 flex flex-col justify-center">
                        <div v-if="modelNumber(favorite.favoriteable)" class="text-xs font-medium leading-tight">Model: {{modelNumber(favorite.favoriteable)}}</div>
                        <div v-if="listPrice(favorite.favoriteable)" class="text-xs font-medium leading-tight">List Price: {{listPrice(favorite.favoriteable)}}</div>
                        <div v-if="rating(favorite.favoriteable)" class="text-xs font-medium leading-tight">Rating: {{rating(favorite.favoriteable)}}</div>
                        <div v-if="description(favorite.favoriteable)" class="w-64 mt-2 text-xs leading-tight truncate">{{description(favorite.favoriteable)}}</div>
                    </div>
                </div>
                
                <div class="w-1/6 flex justify-end">
                    <div 
                        @click.stop="routeTo(previewRoute(favorite.favoriteable))"
                        v-tippy="{ 
                            theme: 'translucent',  
                            arrow: true,
                            touch: 'false',
                            placement: 'top',
                        }"
                        content="<span class='text-blue-100'>Preview</span> Favorite"
                        class="mx-1 flex items-center cursor-pointer hover:opacity-50"
                    >
                        <icon type="preview" action="routeTo" :params="previewRoute(favorite)" width="36" height="36" view-box="0 0 24 24" class="fill-current" />
                    </div>
                </div>
            </div>
            <div class="px-6 py-3"></div>
        </div>
    </div>
</div>
</template>
<script>
// Store
import { mapActions, mapGetters, mapMutations } from 'vuex'
// Dependencies
import SelectToggle from './dependencies/SelectToggle'
import FavoriteActions from './dependencies/FavoriteActions'
// Composition
import ProductMixins from '../../mixins/product-mixins'

export default {
    /** Template Dependencies */
    components: {SelectToggle, FavoriteActions},
    /** Composition */
    mixins: [ProductMixins],
    /** Local State */
    computed: {
        /** Store Getters */
        ...mapGetters([
            'favorites', 
            'selected', 
            'form', 
        ])
    },

    watch: {
        favorites(favorites) {
            if (favorites && favorites.length) {
                this.setShareables(favorites)
            }
        }
    },

    /** Non-Reactive Properties */
    methods: {
        
        /**
         * Return the favoriteable preview route.
         * 
         * @param {object} favoriteable The favoriteable object.
         * @return {string} The preview route. 
         */
        previewRoute(favoriteable) {
            if (favoriteable.group && favoriteable.group.name) {
                return `/products/${favoriteable.id}/show`
            }
            return `/environments/${favoriteable.id}/show`
        },

        /**
         * Return delete params for favorite removal.
         * 
         * @param {object} favorite The favorite to be removed.
         * @return {object} The delete params.
         */
        deleteParams(favorite) {
            return JSON.stringify({
                favoriteable_id: favorite.favoriteable_id, 
                favoriteable_type: favorite.favoriteable_type
            })
        },

        /**
         * Select a single favorite item. 
         * 
         * @param {number} id The favorite id to select.
         * @return {void}
         */
        toggleSelect(id) {

            if (id) {
                let selected = []
    
                selected = this.selected && this.selected.includes(id)
                    ? _.without(this.selected, id)
                    : _.concat(this.selected, id)
                
                this.setSelected(selected)
            }
        },

        /** Store Actions */
        ...mapActions(['routeTo']),

        /** Store Mutations */
        ...mapMutations({
            setSelected: 'SET_SELECTED',
            setShareables: 'SET_SHAREABLES',
        })
    },
}
</script>