<template>
<div v-if="dealer" id="page-footer" class="mt-6 relative bottom-0 z-6">
    <div class="px-4 py-4 md:py-6 bg-grey-700">
        <div class="container mx-auto mt-6 text-grey-100 flex justify-between flex-wrap">
            <div class="mt-2 md:mt-0 w-full md:w-2/5">
                <div v-text="dealer.store_name" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white leading-none"></div>
                <p class="py-4 pr-12 text-sm md:text-base leading-normal" style="max-width: 45ch">
                    We are workplace environment experts that can save you time and headaches.
                    Our friendly, down-to-earth folks strive to make your experience top-notch.
                    You&rsquo;ll never feel alone. <a v-if="dealer.email" target="_top" :href="`mailto:${dealer.email}`" class="text-blue-100 hover:opacity-50">Contact {{dealer.store_name}}</a><span v-else>Contact {{dealer.store_name}}</span> today and let us help you get started.
                </p>
                <p class="mt-2 leading-relaxed">
                    <span v-if="dealerStreet" v-html="dealerStreet" class="text-sm md:text-base lg:text-lg leading-tight"></span><br v-if="dealerStreet">
                    <span v-if="dealerCity && dealerState" class="mb-2 text-sm md:text-base leading-tight flex">{{dealerCity}}, {{dealerState}} {{dealerZip}}</span>
                    <a
                        v-if="dealer.phone && dealer.phone != 0"
                        v-text="dealer.phone"
                        :href="`tel:${dealer.phone}`"
                        class="text-sm md:text-base lg:text-lg text-grey-100 leading-tight hover:opacity-50"
                    ></a>
                    <br v-if="dealer.phone && dealer.phone != 0">
                    <a
                        v-text="dealer.email"
                        target="_top"
                        :href="`mailto:${dealer.email}`"
                        class="text-sm md:text-base lg:text-lg text-grey-100 leading-tight hover:opacity-50"
                    ></a>
                </p>
            </div>

            <!-- Column 2 -->
            <div class="w-full sm:w-1/3 md:w-1/5 mt-6 md:mt-0">
                <!-- Environments -->
                <div id="environments-header" class="mb-1 md:mb-2 text-lg sm:text-xl md:text-2xl text-white tracking-normal">Environments</div>
                <div
                    v-for="(category ,index) in environmentsNavigation"
                    :key="index"
                >
                    <a
                        v-text="category"
                        :href="`/environments/${kebab(category)}/list`"
                        :alt="category"
                        target="_self"
                        class="ml-2 text-sm md:text-base leading-relaxed text-grey-100 hover:opacity-50"
                    ></a>
                </div>
            </div>

            <!-- Column 3 -->
            <div class="mt-6 md:mt-0 w-full sm:w-1/3 md:w-1/5">
                <!-- Products -->
                <div id="products-header" class="mb-1 md:mb-2 text-lg sm:text-xl md:text-2xl text-white tracking-normal">Products</div>
                <div
                    v-for="(applications, category) in productsNavigation"
                    :key="category"
                    class="ml-2 text-sm md:text-base text-grey-100"
                >
                    <div v-if="applications && applications.length">
                        <div class="text-sm md:text-base">{{title(category)}}</div>
                        <div
                            v-for="(application ,index) in applications"
                            :key="index"
                            class="ml-4 leading-tight"
                        >
                            <a
                                v-text="title(application)"
                                :href="`/products/${kebab(application)}/list`"
                                :alt="application"
                                target="_self"
                                class="text-sm text-grey-100 hover:opacity-50"
                            ></a>
                        </div>
                    </div>
                    <div v-else >
                        <a
                            v-text="title(category)"
                            :href="`/products/${kebab(category)}/list`"
                            :alt="category"
                            target="_self"
                            class="text-base md:text-base text-grey-100 hover:opacity-50"
                        ></a>
                    </div>
                </div>
            </div>

            <!-- Column 4 -->
            <div v-if=" ! isNationalAccount" class="mt-6 md:mt-0 w-full sm:w-1/3 md:w-1/5">
                <!-- Information -->
                <!-- <div class="text-lg sm:text-xl md:text-2xl text-white tracking-normal">Information</div>
                <div class="mt-1 md:mt-2 text-sm md:text-base text-grey-100 cursor-pointer">
                    <div id="footer-enviromental-certifications">
                        <a :href="`/green`">
                            <div class="ml-2 mt-1 md:mt-2 text-sm md:text-base text-grey-100 hover:opacity-50">Environmental Certifications</div>
                        </a>
                    </div>
                </div> -->

                <!-- Tools -->
                <!-- <div class="mt-6 md:mt-10 text-lg sm:text-xl md:text-2xl text-white tracking-normal">Tools</div> -->
                <div class="text-lg sm:text-xl md:text-2xl text-white tracking-normal">Tools</div>
                <div class="mt-1 md:mt-2 text-lg text-grey-100 cursor-pointer">
                    <div id="footer-eview-catalog" class="ml-2 leading-relaxed text-sm md:text-base text-grey-100">
                        <a href="/catalog" target="_blank">
                            <tip-widget :content="`<span class='text-blue-100'>Browse</span> ${currentIdeaBook} Idea Book`">Idea Book</tip-widget>
                            <!-- <div class="text-sm md:text-base text-grey-100 hover:opacity-50 cursor-pointer">eView Catalog</div> -->
                        </a>
                    </div>
                    <div id="footer-simple-search" class="ml-2 leading-relaxed">
                        <div
                            @click.stop="setPanel('search')"
                            class="text-sm md:text-base text-grey-100 hover:opacity-50 cursor-pointer"
                        >Simple Search</div>
                    </div>
                </div>

                <!-- Divider -->
                <div class="w-full md:w-3/4 my-6 bg-lighten-20 relative" style="height:1px;">
                    <icon class="w-6 h-6 -mt-3 -mr-1 absolute right-0 top-0" type="secure" view-box="0 0 24 24" width="24" height="24" />
                </div>

                <!-- Member Tools -->
                <div class="ml-2 text-sm md:text-base text-grey-100 leading-relaxed cursor-pointer">
                    <div
                        @click.prevent.stop="routeTo('/login')"
                        id="footer-member-login"
                        class="hover:opacity-50"
                    >Dealer Dashboard</div>
                    <div
                        @click.prevent.stop="redirectTo('/liftoffbudget.com/login')"
                        id="footer-liftoff-login"
                        class="hover:opacity-50"
                    >Liftoff Budget</div>
                    <div
                        @click="routeTo('/mrl')"
                        id="footer-mrl-login"
                        class="hover:opacity-50"
                    >My Resource Library</div>
                </div>
            </div>
        </div>

        <!-- Divider -->
        <div class="w-full my-6 bg-lighten-20" style="height:1px;"></div>

        <!-- Legal -->
        <div class="w-full mt-2 mb-8 text-grey-600 text-xs flex justify-center">
            <div>&copy;{{year}} Connexions Holdings, LCC</div>
            <div class="mx-6">&bullet;</div>
            <div>Build v{{version}}</div>
        </div>
    </div>
</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, } from 'vuex'
/** Dependencies */
import AppMixins from '../../mixins/app-mixins'
import CatalogMixins from '../../mixins/catalog-mixins'
import moment from 'moment'

export default {

    /** Composition */
    mixins: [AppMixins, CatalogMixins],

    /** Local State */
    computed: {

        /**
         * Determine if current dealer is a national account.
         *
         * @return {boolean}
         */
        isNationalAccount() {
            if (this.dealer && this.dealer.custom_properties) {
                return this.dealer.custom_properties.national == 'true' || this.dealer.custom_properties.national == true
            }
            return false
        },

        /**
         * The current year.
         *
         * @return {string}
         */
        year() {
            return moment().format('YYYY')
        },

        /**
         * The laravel version.
         *
         * @return {string}
         */
        version() {
            return window.VERSION
        },


        /**
         * The app uri.
         *
         * @return {string}
         */
        appUri() {
            return window.APP_URL
        },

        /**
         * The product navigation.
         *
         * @return {array}
         */
        productsNavigation() {
            // Navigation must be loaded.
            if (this.navigation) {
                // Assign parsed navigation to new variable.
                let navigation = JSON.parse(this.navigation)
                // Return product navigation array.
                return navigation ? navigation.products : []
            }
            // Return empy array
            return []
        },

        /**
         * The environment navigation.
         *
         * @return {array}
         */
        environmentsNavigation() {
            // Navigation must be loaded.
            if (this.navigation) {
                // Assign parsed navigation to new variable.
                let navigation = JSON.parse(this.navigation)

                // Return navigation array
                return navigation ? navigation.environments : []
            }
            // Return empy array
            return []
        },

        /**
         * The dealer full street address.
         *
         * @return {string}
         */
        dealerStreet() {
            // Dealer has address.
            if (this.dealer && this.dealer.address) {
                // Handle dealers with suite/unit.
                let ste = this.dealer.address.ste_unit
                    ? '<br>' +this.dealer.address.ste_unit
                    : ''
                // Return full street address.
                return this.dealer.address.street + ste
            }
        },

        /**
         * The dealer city.
         *
         * @return {string}
         */
        dealerCity() {
            return this.dealer && this.dealer.address
                ? this.dealer.address.city
                : ''
        },

        /**
         * The dealer state.
         *
         * @return {string}
         */
        dealerState() {
            return this.dealer && this.dealer.address
                ? this.dealer.address.state_province
                : ''
        },

        /**
         * The dealer zip.
         *
         * @return {string}
         */
        dealerZip() {
            return this.dealer && this.dealer.address
                ? this.dealer.address.postal_code
                : ''
        },

        /** Store Getters */
        ...mapGetters(['navigation', 'dealer'])
    },

    /** Non-Reactive Properties */
    methods: {
        /** Store Mutators */
        ...mapMutations({
            setPanel: 'SET_PANEL',
            setForm: 'SET_FORM',
        }),

        /** Store Actions */
        ...mapActions(['routeTo', 'redirectTo']),
    }
}
</script>
