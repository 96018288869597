<template>
    <div>
        <div v-if="freeze" class="w-screen h-screen mt-12 md:mt-8 bg-black opacity-75 fixed top-0 z-14"></div>
    </div>
</template>
<script>
// Store params
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {

    /** Interface */
    props: [
        'navigation',
        'direction',
        'resource',
        'dealer',
        'order',
        'user',
        'auth',
        'uuid',
    ],

    /** Local State */
    computed: {
        /** Store Getters */
        ...mapGetters(['freeze', 'panel', 'scrolling'])
    },

    /** Events */
    watch: {
        panel(panel, oldPanel) {
            if (panel === 'search') {
                this.setFreeze(true)
            } else {
                this.setFreeze(false)
            }
        },
    },
    /** Lifecycle Events */
    mounted() {
        // Reset the scroll position.
        window.scrollTo(0,0)

        // Initialize the user interface.
        this.initialize()

        if (localStorage.getItem('notification')) {
            this.setNotification(localStorage.getItem('notification'));
        }
    },
    /**
     * Unbind the keydown even listener when the component is destroyed
     */
    async destroyed() {
        await document.removeEventListener('click', this.handleClick)
        await document.removeEventListener('keydown', this.handleKeydown)
        await window.removeEventListener('scroll', this.handleScrolling)
    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        async initialize() {
            await this.setNavigation(this.navigation)
            await this.setDealer(this.dealer ? JSON.parse(this.dealer) : null)
            await this.setUuid(this.uuid)
            await this.setResource(this.resource ? JSON.parse(this.resource) : null)
            await this.setSortDirection(this.direction)
            await this.setSortOrder(this.order)
            await this.fetchVendors()
            await this.fetchGroups()
            await document.addEventListener('click', this.handleClick)
            await document.addEventListener('keydown', this.handleKeydown)
            await window.addEventListener('scroll', this.handleScrolling)

            if (this.user) {
                this.setUser(JSON.parse(this.user))
            }
            this.setAuth(!!this.auth)
        },

        /**
         * Handle document click event.
         *
         * @param {object} event The click event.
         * @return void
         */
        handleClick(event) {
            if ( ! event.target.classList.length || ! event.target.classList.contains('panel-element')) {
                return this.setPanel(null)
            }

        },

        /**
         * Handle global keydown events
         *
         * @return void
         */
        handleKeydown(event) {
            if (event.keyCode === 27) {
                this.resetUi()
            }
        },

        /**
         * Handle display when user is scrolling.
         */
        handleScrolling(event) {
            if (this.scrolling && window.scrollY < 96) {
                this.setScrolling(false)
            }
            if ( ! this.scrolling && window.scrollY > 96) {
                this.setScrolling(true)
            }
        },

        /** Store Actions */
        ...mapActions([
            'resetUi',
            'fetchGroups',
            'fetchVendors',
        ]),

        /** Store Mutations */
        ...mapMutations({
            setSortDirection: 'SET_SORT_DIRECTION',
            setNotification: 'SET_NOTIFICATION',
            setNavigation: 'SET_NAVIGATION',
            setSortOrder: 'SET_SORT_ORDER',
            setScrolling: 'SET_SCROLLING',
            setResource: 'SET_RESOURCE',
            setFreeze: 'SET_FREEZE',
            setDealer: 'SET_DEALER',
            setPanel:'SET_PANEL',
            setUser: 'SET_USER',
            setAuth: 'SET_AUTH',
            setUuid: 'SET_UUID',
        })
    },
}
</script>
