<template>
    <div 
        v-if="local"
        :class="{'bg-grey-300': show, 'bg-grey-700': ! show}"
        class="w-1/3 ml-6 text-xs text-grey-600 rounded-t shadow-lg fixed bottom-0 left-0 z-24" 
    >
        <!-- style="background-color: rgba(255, 255, 255, 0.75)" -->
        <div v-if="show" @click="show = false" class="w-full px-4 pt-2 flex justify-end text-grey-700 hover:opacity-50">
            <icon type="close" width="18" height="18" view-box="0 0 24 24" class="cursor-pointer"/>
        </div>
        <div v-if=" ! show" @click="show = true" class="w-full px-4 py-1 flex justify-between text-grey-100 hover:opacity-50">
            <div class="px-2">Info</div>
            <icon type="up" width="18" height="18" view-box="0 0 24 24" class="cursor-pointer"/>
        </div>
        <slot v-if="show"></slot>                    
    </div>
</template>
<script>
export default {
    /** Interface */
    props: {
        /**
         * The component is in local environment.
         * 
         * @var {boolean}
         */
        local: {
            type: Boolean|String,
            required: false,
            default: false,
        }
    },
    /** Local State */
    data() {
        return {
            show: false,
        }
    },

    /** Non-Reactive Properties */
    method: {

    }
}
</script>