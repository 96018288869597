<template>
    <div class="flex-shrink mt-6 pl-0 md:pl-2 flex flex-col">
        <label for="user_id" class="text-xs text-grey-600">Transfer Ownership</label> 
        <div class="relative">    
            <select
                v-if="manager && parsedDealers" 
                @change="confirmTransfer($event)"
                name="user_id" 
                id="user_id" 
                class="block appearance-none my-2 px-4 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                v-model="input.user_id"
            >
                <option :value="null">Select&hellip;</option>
                <optgroup 
                    v-for="(dealer, storeNumber) in parsedDealers" 
                    :key="storeNumber"
                    :label="getGroupLabel(dealer)"
                >
                    <option
                        v-for="(employee, index) in dealer" 
                        :key="index"
                        :value="employee.id" 
                    >
                        {{employee.name}}<!--  at {{user.employer.store_name}} -->
                    </option>
                </optgroup>
            </select>
            <select
                v-if=" ! manager && parsedEmployees"
                @change="confirmTransfer($event)"
                name="user_id" 
                id="user_id" 
                class="block appearance-none my-2 pl-4 pr-10 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                v-model="input.user_id"
            >
                <option :value="null">Select&hellip;</option>
                <option 
                    v-for="(employee, index) in parsedEmployees" 
                    :key="index"
                    v-if="user && employee && (employee.id != user.id)"
                    :value="employee.id" 
                    v-text="employee.name"
                ></option>
            </select>
            <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
            </div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Interface */
    props: {
        manager: {
            type: Boolean|String,
            required: false,
            default: null,
        },
        prospect: {
            type: String,
            required: false,
            default: null,
        },
        dealers: {
            type: String,
            required: false,
            default: null,
        },
        employees: {
            type: String,
            required: false,
            default: null,
        },
    },

    /** Local State */
    data() {
        return {
            input: {user_id:null}
        }
    },
    computed: {
        parsedEmployees() {
            if (this.employees && this.employees.length) {
                return JSON.parse(this.employees)
            }
        },
        parsedDealers() {
            if (this.dealers) {
                return JSON.parse(this.dealers)
            }
        },
        parsedProspect() {
            if (this.prospect) {
                return JSON.parse(this.prospect)
            }
        },

        /** Store Getters */
        ...mapGetters(['user'])
    },

    /** Non Reactive Properties */
    methods: {
        // getStoreName(employees) {
        //     if (employees && employees.length && employees[0].employer) {
        //         return employees[0].employer.store_name
        //     }
        // },

        getGroupLabel(employees) {
            if (employees && employees.length && employees[0].employer) {
                return `${employees[0].employer.store_name} (${employees[0].employer.store_number})`
            }
        },

        confirmTransfer(event) {
            let confirmation = this.confirmation(event.target.value)
            this.setConfirmation(confirmation)
        },

        confirmation(userId) { 
            if (this.parsedProspect && this.parsedProspect.id && this.parsedProspect.status) {
                console.log({
                    from_id: this.user.id,
                    to_id: this.input.user_id,
                    event_id: userId,
                    id: this.parsedProspect.id,
                })
                return {
                    level: 'danger',
                    message: 'Are you sure you want to transfer this prospect? This action cannot be undone.',
                    action: 'updateProspect',
                    params: {
                        user_id: this.input.user_id,
                        id: this.parsedProspect.id,
                    }
                }
            }
        },

        /** Store Mutations */
        ...mapMutations({
            setConfirmation: 'SET_CONFIRMATION'
        })
    },

}
</script>