<template>
    <div
        @mouseover="active = true"
        @mouseout="active = false"
        @click="routeTo('/dashboard/vendors')"
        v-tippy="{
            theme: 'translucent',
            arrow: true,
            touch: 'false',
            placement: 'top'
        }"
        content="<span class='text-teal-200'>Review</span> Vendor Specification Reviews"
        class="flex-1 w-full bg-grey-900 hover:bg-teal-300 rounded cursor-pointer"
    >
        <div class="h-full px-6 flex items-center">
            <div class="h-full flex flex-col justify-center">
                <span :class="{'text-grey-100': active, 'text-teal-300': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-tight">Vendors</span>
                <span class="text-xs md:text-sm lg:text-base xl:text-xl text-grey-100 leading-tight">Vendor Specification Reviews</span>
            </div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions } from 'vuex'
export default {

    /** Local State */
    data() {
        return {
            active: false,
        }
    },
    /** Non-Reactive Properties */
    methods: {
        /** Store Actions */
        ...mapActions(['routeTo']),
    },
}
</script>
