<template>
    <transition name="panel" mode="out-in">
        <div 
            v-show="panel === 'search'" 
            class="panel-element w-screen h-screen mt-12 md:mt-8 flex flex-col items-center bg-neutral-10 fixed top-0 right-0 z-16"
        ><!-- md:w-1/3 min-h-48  mr-0 md:mr-6 rounded-b shadow -->
            <div class="w-full pt-3 pr-3 flex justify-end">
                <div @click="setPanel(null)" class="w-8 h-8 flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer">
                    <icon type="close" on="click" mutator="SET_PANEL" :params="null" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>

            <div class="panel-element mx-auto min-w-max flex flex-col">
                <!-- <search-tabs 
                    dusk="search-tabs-component"
                    @setSearchTab="setSearchTab"
                ></search-tabs> -->
                <!-- Site Search -->
                <algolia-search
                    :paginationBatchCount="searchResultsCount"
                    :queryStrategy="{advancedSyntax: true}"
                    :pagination="{hitsPerPage}"
                    :model-index="searchTab"
                    id="algolia-search"
                ></algolia-search>
            </div>
            
        </div>
    </transition>
</template>
<script>
// Store params
import { mapGetters, mapMutations } from 'vuex'

import SearchTabs from '../../services/Search/snippets/SearchTabs'

export default {

    /**
     * Template Dependencies
     */
    components: {SearchTabs},

    /**
     * Local State
     */
    data() {
        return {
            searchTab: 'products'
        }
    },
    computed: {

        /**
         * Get the batch count for Algolia results
         *
         * @return Number
         */
        searchResultsCount() {
            return (window.innerWidth > 480 && window.innerHeight > 480) ? 15 : 5
        },

        /**
         * Get the hits per page count for Algolia results
         *
         * @return Number
         */
        hitsPerPage() {
            let count = (window.innerHeight > 768) ? 6 : 5

            return (window.innerHeight > 412) ? count : 3
        },

         /**
          * Store Getters
          */
          ...mapGetters(['panel'])
     },

     /**
      * Non-Reactive Properties
      */
     methods: {

        /**
         * Set the search tab.
         * 
         * @param {string} tab
         * @return void
         */
        setSearchTab(tab) {
            this.searchTab = tab
        },

        /* || event.target.id == 'algolia-search-input'search-element  */
        /**
         * Close the panel when user clicks any other element.
         * 
         * @param {object} event The click event.
         * @return void
         */
        closePanel(event) {
            console.log({classList: event.target.classList})
            // if (event.target.classList == 'brand-panel' || event.target.parentNode.id == 'brand-panel') {
            //     return 
            // }
            // return this.setPanel(null)
        },
        /**
         * Store Mutators
         */
        ...mapMutations({setPanel: 'SET_PANEL'})
     },
}
</script>