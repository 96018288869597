const ValidationMixins = {
    methods: {
        /**
         * Validate a given email address.
         * 
         * @param {string} email 
         * @return {boolean} True or false
         */
        validateEmail(email) {
            // var regex = 
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },
        /**
         * Validate a given name.
         * 
         * @param {string} name 
         * @return {boolean} True or false
         */
        validateName(name) {
            return /^([a-zA-Z ]){2,30}$/.test(name)
        },
        /**
         * Validate that a file exists.
         * 
         * @param {string} url 
         */
        urlExists(path) {
            let url = window.location.origin + path
            let http = new XMLHttpRequest()
            http.open('HEAD', url, false)
            http.send()
            return http.status !== 404
        }
    }
}

export default ValidationMixins