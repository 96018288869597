<template>
    <div v-if="selected && selected.length" class="flex-1 px-6 flex items-center justify-end">
        <div v-html="selectedText" class="text-sm text-grey-600"></div>
        <div 
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            :content="shareTipText"
            @click='shareFavorites({ids:selected})'
            class="ml-2 flex items-center cursor-pointer hover:opacity-50"
        >
            <icon type="share" width="24" height="24" view-box="0 0 24 24" class="fill-current text-grey-600 cursor-pointer" />
        </div><!-- action="shareFavorites" :params="{ids:selected}"  -->
        <delete-widget
            id="delete-favorites"
            dusk="delete-favorites"
            :params="deleteParams"   
            :tip="deleteTipText"
            classes="ml-1 hover:opacity-50 text-grey-600 cursor-pointer"
        ></delete-widget>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    /** Local State */
    computed: {
        /**
         * Return the text for selected indicator.
         * 
         * @return {string}
         */
        selectedText() {
            if (this.selected && this.selected.length > 0) {
                return this.selected.length == 1
                    ? '1 favorite selected.'
                    : this.selected.length + ' favorites selected.'
            }
        },

        /**
         * Return the text for delete tool tip.
         * 
         * @return {string}
         */
        deleteTipText() {
            if (this.selected && this.selected.length > 0) {
                let favorites = this.selected.length == 1
                    ? 'this favorite.'
                    : 'selected favorites.'

                return `<span class='text-red-200'>Delete</span> ${favorites}`
            }
        },

        /**
         * Return the text for share tool tip.
         * 
         * @return {string}
         */
        shareTipText() {
            if (this.selected && this.selected.length > 0) {
                let favorites = this.selected.length == 1
                    ? 'this favorite.'
                    : 'selected favorites.'

                return `<span class='text-blue-100'>Share</span> ${favorites}`
            }
        },

        /**
         * The params for the delete action. 
         * 
         * @return {object} The params object.
         */
        deleteParams() {
            if (this.selected) {
                return JSON.stringify({
                    level: 'danger',
                    message: 'Are you sure you want to remove favorites? This action cannot be undone.',
                    action: 'batchRemoveFavorites',
                    params: {
                        ids: this.selected
                    }
                })
            }
        },

        /** Store Getters */
        ...mapGetters([
            'selected', 
            'favorites', 
            'form',
        ]),
    },

    /** Non-Reactive Properties */
    methods: {

        /**
         * Set the selected ids.
         * Show the share form.
         * 
         * @param {array} ids
         * @return {void}
         */
        async shareFavorites(ids) {
            // console.log({ids})
            // await this.setSelected(ids)            
            await this.setForm('share')
        },

        /** Store Mutations */
        ...mapMutations({
            setSelected: 'SET_SELECTED',
            setForm: 'SET_FORM',
        })
    }
}
</script>