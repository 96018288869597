var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.confirmation)?_c('div',{staticClass:"w-screen h-screen flex justify-center items-center relative z-20"},[_c('div',{staticClass:"w-screen h-screen fixed top-0 left-0 z-20"}),_vm._v(" "),_c('div',{staticClass:"w-128 h-64 bg-grey-900 p-6 flex flex-col justify-between relative z-24"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"text-xl",class:{
                        'text-red-300': _vm.confirmation.level == 'danger',
                        'text-yellow-300': _vm.confirmation.level == 'warning',
                        'text-grey-300': _vm.confirmation.level == 'info',
                    }},[_vm._v("Confirmation")]),_vm._v(" "),_c('div',{staticClass:"mt-6 text-base text-grey-100",domProps:{"innerHTML":_vm._s(_vm.confirmation.message)}})]),_vm._v(" "),_c('div',{staticClass:"w-full mr-6 flex items-center justify-end"},[_c('div',{staticClass:"p-4 text-grey-600 hover:opacity-50 cursor-pointer",on:{"click":function($event){return _vm.rejectConfirmation()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('div',{staticClass:"p-4 hover:opacity-50 cursor-pointer",class:{
                        'text-red-300': _vm.confirmation.level == 'danger',
                        'text-yellow-300': _vm.confirmation.level == 'warning',
                        'text-grey-300': _vm.confirmation.level == 'info',
                    },attrs:{"dusk":"accept-confirmation"},on:{"click":function($event){return _vm.acceptConfirmation(_vm.confirmation)}}},[_vm._v("Confirm")])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }