<template>
    <div class="panel-element w-full flex justify-center">
        <div 
            dusk="environments-search-tab"
            @click="searchTab = 'environments'" 
            :class="{
                'border-grey-600 cursor-default': searchTab === 'environments',
                'border-transparent cursor-pointer': searchTab !== 'environments'
            }"
            class="panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2"
        >Environments</div>
        <div 
            dusk="product-search-tab"
            @click="searchTab = 'products'" 
            :class="{
                'border-grey-600 cursor-default': searchTab === 'products',
                'border-transparent cursor-pointer': searchTab !== 'products'
            }"
            class="panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2"
        >Products</div>
        <div 
            dusk="collection-search-tab"
            @click="searchTab = 'groups'" 
            :class="{
                'border-grey-600 cursor-default': searchTab === 'groups',
                'border-transparent cursor-pointer': searchTab !== 'groups'
            }"
            class="panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2"
        >Collections</div>
        <div 
            dusk="brand-search-tab"
            @click="searchTab = 'vendors'" 
            :class="{
                'border-grey-600 cursor-default': searchTab === 'vendors',
                'border-transparent cursor-pointer': searchTab !== 'vendors'
            }"
            class="panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2"
        >Brands</div>
    </div>
</template>
<script>
export default {
    /**
     * Local State
     */
    data() {
        return {
            searchTab: 'environments'
        }
    },

    /**
     * 
     */
    watch: {
        /**
         * Handle search tab changes. 
         * 
         * @param {string} tab
         * @return void
         */
        searchTab(tab) {
            this.$emit('setSearchTab', tab)
        }
    }
}
</script>