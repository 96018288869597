var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
        theme: 'translucent',  
        arrow: true,
        touch: false,
        maxWidth: _vm.maxWidth,
        placement: _vm.placement,
        trigger: _vm.trigger,
        triggerTarget: _vm.triggerTarget,
    }),expression:"{ \n        theme: 'translucent',  \n        arrow: true,\n        touch: false,\n        maxWidth,\n        placement,\n        trigger,\n        triggerTarget,\n    }"}],attrs:{"content":_vm.content}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }