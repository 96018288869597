<template>
    <div
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: false,
            maxWidth,
            placement,
            trigger,
            triggerTarget,
        }"
        :content="content" 
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    /** Interface */
    props: {
        content: {
            type: String,
            required: true,
        },
        placement: {
            type: String,
            required: false,
            default: 'top',
        },
        maxWidth: {
            type: String|Number,
            required: false,
            default: 640,
        },

        trigger: {
            type: String,
            required: false,
            default: 'mouseenter focus',
        }, 

        triggerTarget: {
            type: Object|String,
            required: false,
            default: null,
        }
    }
}
</script>