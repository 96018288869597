// Vue model
import Vue from 'vue'

import AlgoliaSearch from './Search/AlgoliaSearch'
import FavoriteService from './FavoriteService'
import ShareService from './ShareService'
import UiService from './UiService'
import VisitorService from './VisitorService'

Vue.component('algolia-search', AlgoliaSearch)
Vue.component('favorite-service', FavoriteService)
Vue.component('share-service', ShareService)
Vue.component('ui-service', UiService)
Vue.component('visitor-service', VisitorService)
