<template>
    <div class="w-full">
        <editor-menu-bar class="w-full bg-grey-800 flex rounded-t shadow relative z-12" :editor="editor" v-slot="{ commands, isActive }">
            <div class="flex">
                <div 
                    @click="commands.bold" :class="{ 'bg-grey-900': isActive.bold(), 'bg-grey-700': ! isActive.bold() }" 
                    class="w-8 h-8 rounded-none ico-button inline-flex items-center justify-center px-2 text-sm text-grey-100 border-r border-grey-400"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" class="fill-current w-editor-icon h-editor-icon cursor-pointer">
                        <path d="M3 19V1h8a5 5 0 0 1 3.88 8.16A5.5 5.5 0 0 1 11.5 19H3zm7.5-8H7v5h3.5a2.5 2.5 0 1 0 0-5zM7 4v4h3a2 2 0 1 0 0-4H7z"/>
                    </svg>
                </div>
                <div
                    @click="commands.italic" :class="{ 'bg-grey-900': isActive.italic(), 'bg-grey-700': ! isActive.italic() }" 
                    class="w-8 h-8 rounded-none ico-button inline-flex items-center justify-center px-2 text-sm text-grey-100 border-r border-grey-400"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" class="fill-current w-editor-icon h-editor-icon cursor-pointer">
                        <path d="M8 1h9v2H8V1zm3 2h3L8 17H5l6-14zM2 17h9v2H2v-2z"/>
                    </svg>
                </div>
                <div
                    @click="commands.heading({level:1})" :class="{ 'bg-grey-900': isActive.heading({level:1}), 'bg-grey-700': ! isActive.heading({level:1}) }" 
                    class="w-8 h-8 rounded-none ico-button inline-flex items-center justify-center px-2 text-sm text-grey-100 border-r border-grey-400 cursor-pointer"
                >H1</div>
                <div
                    @click="commands.heading({level:2})" :class="{ 'bg-grey-900': isActive.heading({level:2}), 'bg-grey-700': ! isActive.heading({level:2}) }" 
                    class="w-8 h-8 rounded-none ico-button inline-flex items-center justify-center px-2 text-sm text-grey-100 border-r border-grey-400 cursor-pointer"
                >H2</div>
                <div
                    @click="commands.heading({level:3})" :class="{ 'bg-grey-900': isActive.heading({level:3}), 'bg-grey-700': ! isActive.heading({level:3}) }" 
                    class="w-8 h-8 rounded-none ico-button inline-flex items-center justify-center px-2 text-sm text-grey-100 border-r border-grey-400 cursor-pointer"
                >H3</div>
            </div>
        </editor-menu-bar>
        <editor-content class="w-full p-8 text-grey-600 bg-grey-400 rounded-b" :editor="editor" />
    </div>
</template>
<script>
// Tip tap editor
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
// Tip tap menu bar extensions 
import { Bold, Italic, Heading } from 'tiptap-extensions'
export default {
    /**
     * Template dependencies
     */
    components: {
        EditorMenuBar,
        EditorContent,
    },

/**
     * Interface
     */
    props: {
        /**
         * The default message. 
         * 
         * @var {string}
         */
        message: {
            type: String,
            required: true,
        },
    },

    /**
     * Local State
     */
    data() {
        return {
            editor: new Editor({
                extensions: [ 
                    new Bold(), 
                    new Italic(), 
                    new Heading({
                        levels: [1, 2, 3, 4, 5],
                    }),
                ],
                content: this.message,
            }),
        }
    },

    /**
     * Lifecycle Events
     */
    async mounted() {
        /**
         * Add event listener to editor update event to 
         * sync the input body to the editor content.
         * 
         * @return {void}
         */
        await this.editor.on('update', ({ getHTML }) => {
            // Get new content on update
            const newContent = getHTML()
            /* Blade component update */
            this.setBladeInput(newContent)
            
            /* Vue component update */
            this.$emit('update-body', newContent)
        })
        
        await this.$nextTick(() => this.setBladeInput(this.message))
        
    },
    beforeDestroy() {
        /**
         * Tear down the tip tap editor.
         */
        // if (this.editor) {
            this.editor.destroy()
        // }
    },

    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Set the message content. 
         * 
         * @param {string} message
         * @return {void}
         */
        setBladeInput(message) {
            const element = document.querySelector('input[name="body"]')
            element ? element.value = message : null
        },
    }
}
</script>