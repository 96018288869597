<template>
    <transition name="notification" mode="out-in">
        <div v-show="show" class="w-screen flex justify-center fixed bottom-0 z-24">
            <div class="w-full md:w-1/2 lg:w-1/4 xl:w-1/5 text-grey-100 leading-normal tracking-normal flex flex-col items-center bg-darken-80 rounded-t">
                <div class="w-full pt-2 px-2 flex justify-end">
                    <div dusk="close-notification" @click="setDuration(0)" class="w-10 h-10 flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer">
                        <icon type="close" on="click" mutator="SET_NOTIFICATION" :params="{type: null, title:null, message:null, duration:0}" view-box="0 0 24 24" width="24" height="24" />
                    </div>
                </div>
                <div 
                    v-if="notification && notification.title" 
                    v-html="notification.title" 
                    :class="{
                        'text-grey-900': notification && notification.type == 'info',
                        'text-blue-200': notification && notification.type == 'primary',
                        'text-green-300': notification && notification.type == 'success',
                        'text-yellow-400': notification && notification.type == 'warning',
                        'text-red-400': notification && notification.type == 'danger',
                    }"
                    class="w-full px-4 py-2 text-xl"
                ></div>
                <div 
                    v-if="notification && notification.message" 
                    v-html="notification.message" 
                    class="w-full px-4"
                ></div>
                <div 
                    :class="{
                        'bg-grey-900': notification && notification.type == 'info',
                        'bg-blue-300': notification && notification.type == 'primary',
                        'bg-green-300': notification && notification.type == 'success',
                        'bg-yellow-400': notification && notification.type == 'warning',
                        'bg-red-400': notification && notification.type == 'danger',
                    }" 
                    class="w-full h-2 mt-12 relative bottom-0">
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
// Store params
import { mapGetters, mapMutations } from 'vuex'

export default {
    /**
     * Interface
     */
    props: {
        session: {
            type: Object|null,
            required: false,
            default: null,
        },
    },

    /**
     * Local State
     */
    data() {
        return {
            type: null,
            title: null,
            message: null,
            duration: 0,
        }
    },
    computed: {
        /**
         * Show notification while duration is greater tahn zero.
         * 
         * @return {boolean}
         */
        show() {
            return this.duration > 0
        },

        /**
         * Store Getters
         */
        ...mapGetters(['notification'])
    },

    /**
     * Lifecycle Events
     */
    watch: {
        /**
         * Handle session object sent to the view.
         * 
         * @param {string} session The session object passed to the view.
         * @return {void} 
         */
        session (session) {
            this.sessionHandler(JSON.parse(session))
        },

        /**
         * Handle notification content.
         * 
         * @param {object} notification The current notification state.
         * @return {void}
         */
        notification: {
            deep: true,
            handler(notification) {
                console.log({watch:notification})
                if (notification) {
                    this.type = notification.type
                    this.title = notification.title
                    this.message = notification.message
                    this.duration = notification.duration
                }
            }
        },

        /**
         * Handle notification duration.
         * 
         * @param {number} duration The amount of time in milliseconds to show the notification.
         */
        duration(duration) {
            if (duration > 0) {
                setTimeout(async () => {
                    await this.setDuration(0)
                    await this.setNotification(null)
                }, duration)
            }
        }
    },
    /**
     * Lifecycle Events
     */
    mounted() {

        /**
         * Handle existing session messages when mounted. 
         * 
         * @return {void}
         */
        this.$nextTick(() => {    
            if (this.session) {
                this.sessionHandler(JSON.parse(this.session))
            }
        })
    },
    destroyed() {

    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Fire a notification for session messages. 
         * 
         * @param {object} notification The session notification.
         * @return {void}
         */
        sessionHandler(notification) {

            if (notification) {
                console.log({type:notification.type})
                this.setNotification({
                    type: notification && notification.type ? notification.type : 'primary',
                    title: notification && notification.title ? notification.title : null,
                    message: notification && notification.message ? notification.message : null,
                    duration: notification && notification.duration ? notification.duration : 0,
                })
            }
        },

        /**
         * The the local duration state. 
         * 
         * @param {number} duration The time in milliseconds. 
         * @return {void}
         */
        setDuration(duration) {
            this.duration = duration
        },

        /**
         * Store Mutators
         */
        ...mapMutations({setNotification: 'SET_NOTIFICATION'})
     },
}
</script>