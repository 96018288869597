<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Interface */
    props: [
        'shareRecipients',
        'shareableItems',
        'shareableType',
    ],

    /** Local State */
    computed: {
        /** Store Getters  */
        ...mapGetters(['freeze'])
    },

    /** Lifecycle Events */
    mounted() {
        // Initialize the service.
        this.initialize()
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Initialize the service.
         * 
         * @return {void}
         */
        async initialize() {
            await this.setShareRecipients(this.shareRecipients)
            await this.setShareableType(this.shareableType)
            if (this.shareableItems) {
                this.setSelected(JSON.parse(this.shareableItems))
            }
        },

        /** Store Mutations */
        ...mapMutations({
            setShareRecipients: 'SET_SHARE_RECIPIENTS',
            setShareableType: 'SET_SHAREABLE_TYPE',
            setSelected: 'SET_SELECTED',
        })
    },
}
</script>