<template>
    <div class="w-screen h-screen p-0 lg:p-24 flex items-center justify-center fixed top-0 z-16">
        <div class="w-screen h-screen bg-grey-900 opacity-75 absolute top-0 z-16"></div>
        <div class="w-screen md:w-1/2 min-h-64 bg-grey-100 rounded shadow relative top-0 z-16 flex flex-wrap">
            <div class="w-full p-6 text-2xl text-grey-600 flex justify-between">
                <div>Request Purchase Price</div>
                <div @click="close()" class="cursor-pointer">
                    <icon type="close" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>

            <div class="w-full flex flex-col">
                <div v-if="item && type == 'products'" class="w-full px-6 py-2">
                    <div class="w-full text-lg text-grey-600">Product Information</div>
                    <div class="w-full flex items-center">
                        <div v-if="item.url" class="w-24 h-24 mr-6 rounded-full bg-contain bg-center bg-no-repeat shadow" :style="`background-image: url('${item.url}'); background-color: white`"></div>
                        <div>
                            <div v-if="item.group && item.group.name" class="mb-1 text-sm text-grey-600"><strong>{{item.group.name}}</strong> <span v-if="item.group.vendor && item.group.vendor.company_name"><small>by</small> {{item.group.vendor.company_name}}</span></div>
                            <div v-if="item.model_number" class="text-base text-grey-600"><small><strong>Model Number:</strong></small> {{item.model_number}}</div>
                            <div v-if="item.list_price" class="text-base text-grey-600"><small><strong>List Price:</strong></small> ${{item.list_price}}</div>
                        </div>
                    </div>
                    <!-- <div v-if="item.description" class="text-base text-grey-600"><small><strong>Description:</strong></small> {{item.description}}</div> -->
                </div>
                <div v-if="item && type == 'environments'" class="w-full px-6 py-2">
                    <div class="w-full text-lg text-grey-600">Environment Information</div>
                    <div class="w-full flex items-center">
                        <div v-if="item.thumb" class="w-24 h-24 mr-6 rounded-full bg-cover bg-center bg-no-repeat shadow" :style="`background-image: url('${item.thumb}'); background-color: white`"></div>
                        <div>
                            <div v-if="item.group" class="mb-1 text-sm text-grey-600"><strong>{{item.group}}</strong> <span v-if="item.vendor"><small>by</small> {{item.vendor}}</span></div>
                            <div v-if="item.model_number" class="text-base text-grey-600"><small><strong>Model Number:</strong></small> {{item.model_number}}</div>
                            <div v-if="item.list_price" class="text-base text-grey-600"><small><strong>List Price:</strong></small> ${{item.list_price}}</div>
                        </div>
                    </div>
                    <!-- <div v-if="item.description" class="text-base text-grey-600"><small><strong>Description:</strong></small> {{item.description}}</div> -->
                </div>
                <div class="w-full px-6 py-2 ">
                    <div class="w-full text-lg text-grey-600">Reminder</div>
                    <div v-if="item" class="text-base text-grey-600">
                        <div class="text-sm mb-1">In some cases, services such as Space Planning or Installation may be required for the products you are requesting prices for. {{dealer ? dealer.store_name : 'We'}} will provide any necessary services required. This may involve clarifying your desired service options in order to provide accurate pricing.</div>
                    </div>
                </div>
                
                <div class="w-full px-6 pt-4 text-sm mb-2 text-grey-600">Let us know where to send the pricing information by submitting the form below.</div>

                <div class="w-full px-6 flex flex-col">
                    <div class="w-full flex">
                        <label for="name" class="text-xs text-grey-600">Name</label> 
                        <div v-if=" ! input.name" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                        <div v-if="input.name && ! validateName(input.name)" class="ml-1 text-xs text-red-200"><small>Names must use letters only.</small></div>
                    </div>
                    <input 
                        v-model="input.name"
                        id="name" 
                        type="text" 
                        class="w-full py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                        name="name"
                        placeholder="Your name" 
                        required
                    >
                </div>

                <div class="w-full px-6 py-2 flex flex-col">
                    <div class="w-full flex">
                        <label for="email" class="text-xs text-grey-600">Email</label> 
                        <div v-if="! input.email" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                        <div v-if="input.email && ! validateEmail(input.email)" class="ml-1 text-xs text-red-200"><small>This does not appear to be a valide email address.</small></div>
                    </div>
                    <input 
                        v-model="input.email"
                        id="email" 
                        type="email" 
                        class="w-full mr-2 py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                        name="email"
                        placeholder="Your email" 
                        required
                    >
                </div>                                  
            </div>

            <div class="w-full px-8 py-6 flex justify-end items-center">
                <div @click="close" class="py-2 px-4 text-grey-600 hover:opacity-50 rounded cursor-pointer">Cancel</div>
                <div 
                    @click="readyToSubmit ? submitRequestPrice() : null" 
                    :class="{
                        'opacity-50 cursor-default': ! readyToSubmit, 
                        'hover:opacity-50 cursor-pointer': readyToSubmit, 
                    }"
                    class="py-2 px-4 text-blue-200 " 
                >Request Price</div>
            </div>
        </div>        
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapActions, mapMutations } from 'vuex' 
/** Composition */
import ValidationMixins from '../../mixins/validation-mixins'
export default {
    /** Composition */
    mixins: [ValidationMixins],

    /** Interface */
    props: {
        item: {
            type: Object,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: false,
            default: null,
        },
    },

    /** Local State */
    data() {
        return {
            input: {
                name: null,
                email: null,
                store_number: null,
                inquiry_type: 'price',
                path: null,
                model_id: null,
                model_type: null,
            }
        }
    },
    computed: {
        /**
         * Show that the form is ready to submit.
         * 
         * @return {boolean} True of false
        */
        readyToSubmit() {
            return this.validateEmail(this.input.email) 
                && this.validateName(this.input.name)
        },

        /** Store Getters */
        ...mapGetters(['dealer', 'user'])
    },

    /** LifeCycle Events */
    mounted() {
        this.initializeInput()
    },
    destroyed() {
        this.resetInput()
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Initialize the input for the inquiry.
         * 
         * @return {void}
         */
        initializeInput() {
            this.$nextTick(() => {
                this.input.name = this.user ? this.user.name : null
                this.input.email = this.user ? this.user.email : null
                this.input.store_number = this.dealer.store_number
                this.input.path = window.location.pathname
                this.input.model_id = this.item.id
                this.input.model_type = this.getModel(this.type)
            })            
        },

        /**
         * CLear the input.
         * 
         * @return {void}
         */
        resetInput() {
            this.input.name = null
            this.input.email = null
            this.input.store_number = null
            this.input.path = null
            this.input.model_id = null
            this.input.model_type = null          
        },

        /**
         * Return the model type for the input.
         * 
         * @param {string} type The item type [products|environments]
         * @return {string}
         */
        getModel(type) {
            return type === 'environments'
                ? 'App\\Services\\Product\\Models\\Environment'
                : 'App\\Services\\Product\\Models\\Product'
        },
        /**
         * Process the request form.
         * 
         * @return {void}
         */
        async submitRequestPrice() {
            await this.setProcessing(true)
            await this.submitInquiry(this.input)
            this.close()            
        }, 

        /**
         * Close the form.
         * 
         * @return {void}
         */
        close() {
            this.$emit('close')
        },

        /** Store Actions */
        ...mapActions(['submitInquiry']),

        /** Store Mutrations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING',
        })
    },
}
</script>