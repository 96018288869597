var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-auto relative top-0",attrs:{"id":"indicator-bar"}},[_c('select-element',{attrs:{"dusk":"resource-options-component","options":_vm.selectOptions,"fixed":false,"show":_vm.select === 'resource-options',"trigger-id":"resource-options"}}),_vm._v(" "),_c('div',{staticClass:"w-full bg-white border-t border-b border-neutral-30 flex justify-between opacity-75",class:{'mt-12 md:mt-8 fixed top-0 ': _vm.scrolling}},[_c('div',{staticClass:"flex-1 flex items-center"},[(_vm.isDetail)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }),expression:"{ \n                    theme: 'translucent',  \n                    arrow: true,\n                    touch: 'false',\n                    placement: 'top',\n                }"}],staticClass:"ml-4 text-grey-600 hover:opacity-50 cursor-pointer",attrs:{"dusk":"back-icon","content":"<span class='text-blue-100'>Go</span> back"},on:{"click":_vm.back}},[_c('icon',{attrs:{"type":"back","params":true,"view-box":"0 0 24 24","width":"24","height":"24"}})],1):_vm._e(),_vm._v(" "),(_vm.title)?_c('div',{staticClass:"px-4 py-2 text-2xl text-grey-600 tracking-normal",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.isDetail)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }),expression:"{ \n                    theme: 'translucent',  \n                    arrow: true,\n                    touch: 'false',\n                    placement: 'top',\n                }"}],staticClass:"text-grey-600 flex items-center justify-center hover:opacity-50 cursor-pointer",attrs:{"content":"More Information","id":"resource-options"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelect('resource-options')}}},[_c('icon',{attrs:{"dusk":"resource-options-trigger","type":"down","on":"click","mutator":"SET_SELECT","params":"resource-options","view-box":"0 0 24 24","width":"24","height":"24"}}),_vm._v(" "),_c('div',{staticClass:"text-xs font-medium leading-tight text-grey-600"},[_vm._v("Information")])],1):_vm._e()]),_vm._v(" "),_c('sort-widget',{attrs:{"dusk":"sort-widget-component"}}),_vm._v(" "),_c('div',{staticClass:"px-8 flex-1 flex items-center justify-end"},[_c('per-page-widget',{attrs:{"dusk":"per-page-widget-component"}}),_vm._v(" "),(_vm.inCurrentCatalog(_vm.resource))?_c('a',{staticClass:"w-10 h-10 text-grey-700 flex items-center justify-center hover:opacity-50 cursor-pointer",attrs:{"href":"/catalog","target":"_blank"}},[_c('icon',{staticClass:"fill-current",attrs:{"type":"catalog","view-box":"0 0 24 24","width":"20","height":"20"}})],1):_vm._e(),_vm._v(" "),(_vm.isDetail)?_c('favorite-widget',{attrs:{"dusk":"favorite-widget-component","id":_vm.favoriteId,"inactive":"text-grey-700 bg-transparent","active":"text-grey-100 bg-red-200"}}):_vm._e(),_vm._v(" "),(_vm.isDetail)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }),expression:"{ \n                    theme: 'translucent',  \n                    arrow: true,\n                    touch: 'false',\n                    placement: 'top',\n                }"}],staticClass:"w-10 h-10 text-grey-700 flex items-center justify-center hover:opacity-50 cursor-pointer",attrs:{"content":"<span class='text-blue-100'>Share</span> this item","id":"resource-options"},on:{"click":function($event){$event.stopPropagation();return _vm.setForm('share')}}},[_c('icon',{staticClass:"fill-current",attrs:{"dusk":"share-trigger","type":"share","view-box":"0 0 24 24","width":"24","height":"24"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }