const VendorModule = {
    state: {
        /**
         * The app’s vendors state.
         * 
         * @var object
         */
        vendors: null,
    },

    getters: {
        /**
         * Get the current vendors state. 
         * 
         * @param {object} vendors The vendors param from store.state object 
         */
        vendors({ vendors }) {
            return vendors
        },
    },

    actions: {
        /**
         * Fetch all vendors instances from repository.
         * 
         * @param {object} commit The store commit module
         * @return {void} 
         */
        fetchVendors({ commit }) {
            axios 
                .get('/api/vendors')
                .then(response => commit('SET_VENDORS', response.data))
                .catch(errors => console.warn({errors}))
        },
    },

    mutations: {
        /**
         * Set the current vendors state. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {bool} vendors The vendors object.
         */
        SET_VENDORS(state, vendors) {
            state.vendors = vendors
        },
    },
}

export default VendorModule
