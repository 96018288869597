const PricingMixins = {
    /** Local State */
    data() {
        return {
            /**
             * The request for price element.
             * 
             * @var {element}
             */
            element: null,
            /**
             * The state of the request price form. 
             * 
             * @var {boolean}
             */
            requestingPrice: false,
            /**
             * The requested product type.
             * 
             * @var {string}
             */
            type: null,
            /**
             * The requested product id. 
             * 
             * @var {id}
             */
            id: null,
        }
    },

    /** Life-Cycle Events */
    destroyed() {
        /** If request for price event was added… */
        if (this.element) {
            /** Remove event listener. */
            this.element.removeEventListener('click', this.showRequestForPrice)
        }
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Show request for price link when no sell 
         * price is available for mappable item.
         * 
         * @param {number} id The element id to query select.
         * @return {void}
         */
        handleSellPricing(id) {
            /** Allow tippy instance to load. */
            this.$nextTick(() => {
                /** Fetch the request price element */
                let element = document.querySelector(id);
                
                /** Sell price unavailable */
                if (element) {
                    this.element = element
                    /** Assign event listener to the element. */
                    this.element.addEventListener('click', this.showRequestForPrice)
                }
            })
        },

        /**
         * Prepare and show the request for price form for given product.
         * 
         * @param {string} type The type of product [Environment|Product]
         * @param {number} id The id of the product instance.
         * @return {void}
         */
        showRequestForPrice(event) {
            console.log({showRequestForPrice:event.target.dataset.type})
            /** Fetch the dataset from request price element. */
            this.type = event.target.dataset.type
            this.id = event.target.dataset.id
            this.requestingPrice = true
        },
    }
}

export default PricingMixins