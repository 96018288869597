const UiModule = {
    state: {

        /**
         * The app’s navigation. 
         * 
         * @var {object}
         */
        navigation: null,

        /**
         * The app’s side drawer state.
         * 
         * @var {boolean}
         */
        sideDrawer: false,

        /**
         * The app panel that is active
         * 
         * @var {string}
         */
        panel: null,

        /**
         * The app form that is active
         * 
         * @var {string}
         */
        form: null,

        /**
         * The app select element that is active.
         * 
         * @var {string}
         */
        select: null,

        /**
         * The current select options.
         * 
         * @var {object}
         */
        selectOptions: null,

        /**
         * The current page is being scrolled.
         * 
         * @var {boolean} 
         */
        scrolling: false,

        /**
         * The app is processing a request.
         * 
         * @var {boolean} 
         */
        processing: false,

        /**
         * The app ui is frozen. 
         * 
         * @var {boolean} 
         */
        freeze: false,

        /**
         * The app notification state. 
         * 
         * @var {object}
         */
        notification: null,

        /**
         * The app confirmation state. 
         * 
         * @var {object}
         */
        confirmation: null,

        /**
         * The app’s sort direction state.
         * 
         * @var {string}
         */
        sortDirection: null,

        /**
         * The app’s sort order state.
         * 
         * @var {string}
         */
        sortOrder: null,
    },

    getters: {

        /**
         * Get the app’s navigation state. 
         * 
         * @param {object} store.param The navigation state param
         * @return {object} navigation
         */
        navigation({ navigation }) {
            return navigation
        },

        /**
         * Get the app’s sideDrawer state.
         * 
         * @param {object} store.param The sideDrawer state param
         * @return {bool} sideDrawer
         */
        sideDrawer({ sideDrawer }) {
            return sideDrawer
        },

        /**
         * Get the app’s panel state.
         * 
         * @param {object} store.param The panel state param
         * @return {string} panel
         */
        panel({ panel }) {
            return panel
        },

        /**
         * Get the app’s form state.
         * 
         * @param {object} store.param The form state param
         * @return {string} form
         */
        form({ form }) {
            return form
        },

        /**
         * Get the app’s select state.
         * 
         * @param {object} store.param The menu state param
         * @return {string} select
         */
        select({ select }) {
            return select
        },

        /**
         * Get the app’s select options state.
         * 
         * @param {object} store.param The menu state param
         * @return {object} selectOptions
         */
        selectOptions({ selectOptions }) {
            return selectOptions
        },

        /**
         * Get the app’s scrolling state.
         * 
         * @param {object} store.param The scrolling state param
         * @return {bool} scrolling
         */
        scrolling({ scrolling }) {
            return scrolling
        },

        /**
         * Get the app’s processing state.
         * 
         * @param {object} store.param The processing state param
         * @return {bool} processing
         */
        processing({ processing }) {
            return processing
        },

        /**
         * Get the app’s freeze state. 
         * 
         * @param {object} store.param The freeze state param
         * @return {bool} freeze
         */
        freeze({ freeze }) {
            return freeze
        },

        /**
         * Get the app’s notification state.
         * 
         * @param {object} store.param The notification state param. 
         * @return {string} 
         */
        notification({ notification }) {
            return notification
        },

        /**
         * Get the app’s confirmation state.
         * 
         * @param {object} store.param The confirmation state param. 
         * @return {string} 
         */
        confirmation({ confirmation }) {
            return confirmation
        },

        /**
         * Get the app’s sort direction state.
         * 
         * @param {object} store.param The sort direction state param. 
         * @return {string} 
         */
        sortDirection({ sortDirection }) {
            return sortDirection
        },

        /**
         * Get the app’s sort order state.
         * 
         * @param {object} store.param The sort order state param. 
         * @return {string} 
         */
        sortOrder({ sortOrder }) {
            return sortOrder
        },

    },

    actions: {
        /**
         * Handle accepted confirmation.
         *
         * @param {object} store The store components.
         * @return {void}
         */
        async acceptConfirmation({ commit, dispatch }, confirmation) {
            // Dispatch action.
            if (confirmation.action) {
                await dispatch(confirmation.action, confirmation.params)
            }
            // Commit mutation 
            if (confirmation.mutation) {
                await commit(confirmation.mutation, confirmation.params)
            }
            // Hide confirmation.
            await commit('SET_CONFIRMATION', null)
        },

        /**
         * Handle rejected confirmation.
         *
         * @param {object} store The store components.
         * @return {void}
         */
        rejectConfirmation({ commit }) {
            // Hide confirmation.
            commit('SET_CONFIRMATION', null)
        },

        /**
         * Route to given app path. 
         * 
         * @param {object} store.params
         * @param {string} path 
         * @return {void}
         */
        routeTo({}, path) { 
            // Ensure path has leading forward slash.
            if (path instanceof String && path.charAt(0) !== '/') {
                path = '/' + path
            }
            // Route intended path.
            window.location = window.location.origin + path 
        },

        /**
         * Route to given app path. 
         * 
         * @param {object} store.params
         * @param {string} url 
         * @return {void}
         */
        redirectTo({}, url) { 
            if (url) {
                // Ensure path has leading forward slash.
                if (url instanceof String && url.charAt(0) !== '/') {
                    url = '/' + url
                }
             
                // Open url in a new window.
                window.open(`http:/${url}`)
            }
        },

        /**
         * Reset the ui elements. 
         * 
         * @param {object} store.params
         * @return void
         */
        resetUi({ commit }) {
            commit('SET_NOTIFICATION', null)
            commit('SET_CONFIRMATION', null)
            commit('SET_SIDE_DRAWER', false)
            commit('SET_PROCESSING', false)
            commit('SET_FREEZE', false)
            commit('SET_SELECT', null)
            commit('SET_PANEL', null)
        },

        /**
         * Scroll to given anchor tag.
         */
        scrollTo({}, id) {
            // Fetch the element.
            let element = document.getElementById(id)
            // Element must be in DOM.
            if (element) {
                // Set y position to bounding top minus header margin plus current y-axis scroll.
                let y = element.getBoundingClientRect().y - 148 + window.scrollY
                // Scroll the element into view.
                // @url https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll
                window.scroll({
                    top: y,
                    left: 0,
                    behavior: 'smooth' // Not supported in Safari
                });
            }            
        },

        /**
         * Clear session storage and relaod current page.
         * 
         * @return {void}
         */
        async clearSession () {
            await sessionStorage.clear()
            window.location = window.location.origin + window.location.pathname
        },
    },

    mutations: {

        /**
         * Set the app’s navigation state. 
         * 
         * @param {object} state the store.state object.
         * @param {object} navigation The navigation state. 
         */
        SET_NAVIGATION(state, navigation) {
            state.navigation = navigation
        },

        /**
         * Set the app’s side drawer state. 
         * 
         * @param {object} state   The store.state object. 
         * @param {bool} sideDrawer  The side drawer state.
         */
        SET_SIDE_DRAWER(state, sideDrawer) {
            state.sideDrawer = sideDrawer
        },

        /**
         * Set the app’s panel state. 
         * 
         * @param {object} state 
         * @param {sting} panel 
         */
        SET_PANEL(state, panel) {
            state.panel = panel
        },

        /**
         * Set the app’s form state. 
         * 
         * @param {object} state 
         * @param {sting} form 
         */
        SET_FORM(state, form) {
            state.form = form
        },

        /**
         * Set the app’s select state. 
         * 
         * @param {object} state 
         * @param {sting} select 
         */
        SET_SELECT(state, select) {
            state.select = select
        },

        /**
         * Set the app’s select options state. 
         * 
         * @param {object} state 
         * @param {object} selectOptions 
         */
        SET_SELECT_OPTIONS(state, selectOptions) {
            state.selectOptions = selectOptions
        },

        /**
         * Set the app’s scrolling status
         * 
         * @param {object} state The store.state object.
         * @param {bool} scrolling The scrolling state.
         * @return void
         */
        SET_SCROLLING(state, scrolling) {
            state.scrolling = scrolling
        },

        /**
         * Set the app’s processing status
         * 
         * @param {object} state The store.state object.
         * @param {bool} processing The processing state.
         * @return void
         */
        SET_PROCESSING(state, processing) {
            state.processing = processing
        },

        /**
         * Set the app’s freeze state.
         * 
         * @param {object} state The store.state object.
         * @param {bool} freeze The freeze state.
         */
        SET_FREEZE(state, freeze) {
            state.freeze = freeze
        },

        /**
         * Set the app’s notification state.
         * 
         * @param {object} state The store.state object.
         * @param {string} notification The notification state.
         */
        SET_NOTIFICATION(state, notification) {
            state.notification = notification
        },

        /**
         * Set the app’s confirmation state.
         * 
         * @param {object} state The store.state object.
         * @param {string} confirmation The confirmation state.
         */
        SET_CONFIRMATION(state, confirmation) {
            state.confirmation = confirmation
        },

        /**
         * Set the app’s sort direction state.
         * 
         * @param {object} state The store.state object.
         * @param {string} sortDirection The sort direction state.
         */
        SET_SORT_DIRECTION(state, sortDirection) {
            state.sortDirection = sortDirection
        },

        /**
         * Set the app’s sort order state.
         * 
         * @param {object} state The store.state object.
         * @param {string} sortOrder The sort order state.
         */
        SET_SORT_ORDER(state, sortOrder) {
            state.sortOrder = sortOrder
        },
    }
}

export default UiModule
