const PricingModule = {

    state: {
        /**
         * The margin state.
         * 
         * @var {array}
         */
        margins: null,
    },

    getters: {
        /**
         * Get the current margins state.
         * 
         * @param {array} margins 
         */
        margins({ margins }) {
            return margins
        },
    },

    actions: {
        /**
         * Retireve all dealer’s custom margins from repository.
         * 
         * @param {object} commit The store commit module.
         */
        fetchMargins({ commit }, id) {
            axios 
                .get(`/api/margins/${id}`)
                .then(response => commit('SET_MARGINS', response.data))
                .catch(errors => console.log({errors}))
        },
        /**
         * Persist a new margin to the database.
         * 
         * @param {object} commit The store commit module. 
         * @param {object} params The margin params.
         */
        storeMargin({ commit }, params) {
            commit('SET_PROCESSING', true)

            axios 
                .post('/api/margins', params)
                .then(response => commit('SET_MARGINS', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Your custom margin has been added.', 
                    duration: 5000,
                }))
                .catch(errors => console.warn({errors}))
        },
        /**
         * Remove a margin from the database.
         * 
         * @param {object} commit The store commit module. 
         * @param {number} id The margin id.
         */
        deleteMargin({ commit }, id) {
            commit('SET_PROCESSING', true)

            axios 
                .delete(`/api/margins/${id}`)
                .then(response => commit('SET_MARGINS', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'The margin has been removed.', 
                    duration: 5000,
                }))
                .catch(errors => console.warn({errors}))
        }
    },
    mutations: {
        /**
         * Mutate the margins state.
         * 
         * @param {object} state The PricingModule state object.
         * @param {array} margins The current state of the margins.
         */
        SET_MARGINS(state, margins) {
            state.margins = margins
        },
    }
}

export default PricingModule