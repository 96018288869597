<template>
    <div class="w-full my-2 px-6">
        <div class="my-6 w-full flex items-center">
            <div>
                <div class="flex items-center">
                    <div class="text-3xl text-grey-100">Pricing</div>
                    <div v-if=" ! admin" class="mx-2 text-3xl text-grey-100"> for {{storeName(dealer)}}</div>
                </div>
                <div v-if=" ! admin" class="text-grey-100" style="max-width: 60ch">Instantly customize the GP/Sale Price for every non Idea Book item on your website. Thousands of choices… for the consumer to see immediately!</div>
            </div>
            <div v-if="dealers && admin" class="relative flex items-center">
                <div class="mx-2 text-3xl text-grey-100">for</div>
                <select 
                    dusk="pricing-dealer-select"
                    v-model="dealer_id" 
                    @change="fetchDealer(dealer_id)"
                    class="pl-2 pr-8 block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                >
                    <option v-for="(dealer, index) in dealers" :key="index" :value="dealer.id">{{storeName(dealer)}}</option>
                </select>
                <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                    <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                </div>
            </div>
        </div>
        <div :class="{'border-b border-grey-700': sellPricing }" class="w-full py-6 mb-3">
            <div class="text-grey-500 text-2xl">Default Website Settings</div>
            <div class="mt-3 text-grey-100">Catalog sell prices are always shown.</div>
            <div class="w-full flex items-center">
                <div class="text-grey-100">Special order sell prices on {{storeName(dealer)}} website are &nbsp;</div>
                <div class="relative">
                    <select 
                        v-model="sellPricing" 
                        @change="toggleShowPricing(dealer_id)"
                        :disabled="! manager"
                        :class="{'disabled px-2': ! manager, 'pl-2 pr-8': manager}"
                        class="block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                        name="sell_pricing" 
                        id="sell_pricing" 
                    >
                        <option :value="true">Visible</option>
                        <option :value="false">Hidden</option>
                    </select>
                    <div v-if="manager" class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                        <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                    </div>
                </div>
            </div>
            <div v-if="sellPricing" class="w-full mt-2 flex items-center">    
                <div class="text-grey-100">Special order sell prices include a default margin of&nbsp;</div>
                <div class="relative">
                    <select 
                        v-model="defaultMargin" 
                        @change="updateDefaultMargin(dealer_id)"
                        :disabled="! manager"
                        :class="{'disabled px-2': ! manager, 'pl-2 pr-8': manager}"
                        class="block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                        name="default_margin" 
                        id="default_margin" 
                    >
                        <option :value="0.00">0% (At Cost)</option>
                        <option :value="0.05">{{humanReadablePercentage(0.05)}}</option>
                        <option :value="0.10">{{humanReadablePercentage(0.10)}}</option>
                        <option :value="0.15">{{humanReadablePercentage(0.15)}}</option>
                        <option :value="0.20">{{humanReadablePercentage(0.20)}}</option>
                        <option :value="0.25">{{humanReadablePercentage(0.25)}}</option>
                        <option :value="0.30">{{humanReadablePercentage(0.30)}}</option>
                        <option :value="0.35">{{humanReadablePercentage(0.35)}}</option>
                        <option :value="0.40">{{humanReadablePercentage(0.40)}}</option>
                        <option :value="0.45">{{humanReadablePercentage(0.45)}}</option>
                        <option :value="0.50">{{humanReadablePercentage(0.50)}}</option>
                    </select>
                    <div v-if="manager" class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                        <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="manager && sellPricing" class="w-full py-6 mb-3">
            <div class="text-grey-600 text-2xl">Add Custom Margin&nbsp;</div>
            <div class="flex items-center">
                <select 
                    v-model="marginable_type" 
                    class="pl-2 pr-8 block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                >
                    <option :value="null">Select…</option>
                    <option value="App\Services\Vendor\Models\Vendor">Vendor</option>
                    <option value="App\Services\Product\Models\Group">Product Series</option>
                </select>

                <div v-if="marginable_type === 'App\\Services\\Vendor\\Models\\Vendor'" class="mx-3 flex items-center">
                    <select 
                        v-model="marginable_id" 
                        class="pl-2 pr-8 block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                    >
                        <option :value="null">Select…</option>
                        <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.id">{{vendor.company_name}}</option>
                    </select>
                </div>
                <div v-if="marginable_type === 'App\\Services\\Product\\Models\\Group'" class="mx-3 flex items-center">
                    <select 
                        v-model="marginable_id" 
                        class="pl-2 pr-8 block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                    >
                        <option :value="null">Select…</option>
                        <option 
                            v-for="(group, index) in groups" 
                            :key="index" 
                            :value="group.id">
                                {{group.name}} by {{group.vendor ? group.vendor.company_name : null}}
                        </option>
                    </select>
                </div>

                <div v-if="marginable_type !== null" class="text-grey-100">Margin:</div>
                <div v-if="marginable_type !== null" class="mx-3 flex items-center">
                    <select 
                        v-model="percentage" 
                        class="pl-2 pr-8 block appearance-none py-2 bg-grey-900 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                    >
                        <option :value="0.00">0% (At Cost)</option>
                        <option :value="0.05">{{humanReadablePercentage(0.05)}}</option>
                        <option :value="0.10">{{humanReadablePercentage(0.10)}}</option>
                        <option :value="0.15">{{humanReadablePercentage(0.15)}}</option>
                        <option :value="0.20">{{humanReadablePercentage(0.20)}}</option>
                        <option :value="0.25">{{humanReadablePercentage(0.25)}}</option>
                        <option :value="0.30">{{humanReadablePercentage(0.30)}}</option>
                        <option :value="0.35">{{humanReadablePercentage(0.35)}}</option>
                        <option :value="0.40">{{humanReadablePercentage(0.40)}}</option>
                        <option :value="0.45">{{humanReadablePercentage(0.45)}}</option>
                        <option :value="0.50">{{humanReadablePercentage(0.50)}}</option>
                    </select>
                </div>
                <div v-if="marginable_id" @click="addMargin()" class="ml-4 py-2 px-4 text-grey-100 bg-blue-300 hover:opacity-50 rounded cursor-pointer">Add Margin</div>
            </div>
        </div>

        <div v-if="sellPricing && (vendorMargins && vendorMargins.length) || sellPricing && (groupMargins && groupMargins.length)" class="w-full py-6 mb-12 flex">
            <div v-if="vendorMargins && vendorMargins.length" class="flex-1">
                <div class="text-grey-600 text-2xl">Custom Vendor Margins</div>
                <div v-for="(margin, index) in vendorMargins" :key="index" class="w-full flex items-center text-grey-100 leading-loose">
                    <div
                        v-if="manager" 
                        @click="deleteMargin(margin.id)"
                        v-tippy="{
                            theme: 'translucent',
                            arrow: true,
                            touch: 'false',
                            placement: 'top',
                        }"
                        content="<span class='text-red-100'>Remove</span> this vendor margin." 
                        class="mx-2 text-grey-600 hover:opacity-50 cursor-pointer"
                    >
                        <icon type="delete" view-box="0 0 24 24" width="18" height="18" class="fill-current"/>
                    </div>
                    <div :class="{'text-grey-600': margin.percentage == defaultMargin }" class="ml-2 p-1 flex items-center">
                        <div> {{marginableName(margin)}}: {{margin.percentage == 0 ? '0% (At Cost)' : humanReadablePercentage(margin.percentage) }}</div> 
                    </div>
                </div>
            </div>
            <div v-if="groupMargins && groupMargins.length" class="flex-1">
                <div class="text-grey-600 text-2xl">Product Series Margins</div>
                <div v-for="(margin, index) in groupMargins" :key="index" class="w-full flex items-center text-grey-100 leading-loose">
                    <div
                        v-if="manager" 
                        @click="deleteMargin(margin.id)"
                        v-tippy="{
                            theme: 'translucent',
                            arrow: true,
                            touch: 'false',
                            placement: 'top',
                        }"
                        content="<span class='text-red-100'>Remove</span> this product series margin." 
                        class="mx-2 text-grey-600 hover:opacity-50 cursor-pointer"
                    >
                        <icon type="delete" view-box="0 0 24 24" width="18" height="18" class="fill-current"/>
                    </div>
                    <div :class="{'text-grey-600': margin.percentage == defaultMargin }" class="ml-2 p-1 flex items-center">
                        <div> {{marginableName(margin)}}: {{margin.percentage == 0 ? '0% (At Cost)' : humanReadablePercentage(margin.percentage) }}</div> 
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        isManager: {
            type: Boolean|String,
            required: false,
            default: false,
        },
        isAdmin: {
            type: Boolean|String,
            required: false,
            default: false,
        },
    },

    /**
     * Local State
     */
    data() {
        return {
            sellPricing: true,
            defaultMargin: 0.30,
            marginable_type: null,
            marginable_id: null,
            percentage: 0.30,
            start_at: null,
            expires_at: null,
            dealer_id: null,
        }
    },
    computed: {
        /**
         * Return the parsed vendor margins objects array.
         * 
         * @return {array}
         */
        vendorMargins() {
            if (this.margins) {
                return _.filter(this.margins, margin => {
                    return margin.type === 'Vendor'
                })
            }
            return []
        },

        /**
         * Return the parsed group margins objects array.
         * 
         * @return {array}
         */
        groupMargins() {
            if (this.margins) {
                return _.filter(this.margins, margin => {
                    return margin.type === 'Group'
                })
            }
            return []
        },

        /**
         * The current user is a manager.
         * 
         * @var {boolean}
         */
        manager() {
            if (this.isManager) {
                return this.isManager == 1
            }
            return false
        },


        /**
         * The current user is an admin.
         * 
         * @var {boolean}
         */
        admin() {
            if (this.isAdmin) {
                return this.isAdmin == 1
            }
            return false
        },

        /** Store Getters */
        ...mapGetters([
            'dealer',
            'dealers',
            'margins', 
            'vendors', 
            'groups',
        ])
    },

    /**
     * Events
     */
    watch: {
        dealer: {
            deep: true,
            handler(dealer) {
                this.$nextTick(async () => {
                    if (this.admin) {
                        this.dealer_id = this.dealer.id
                        await this.fetchDealers()
                    }
                    await this.fetchMargins(this.dealer.id)
                    await this.setPricingSettings()  
                })
            }
        },
    },

    /**
     * Lifecycle Events
     */
    mounted() {
        this.$nextTick(async () => {
            if (this.dealer) {                
                if (this.admin) {
                    this.dealer_id = this.dealer.id
                    await this.fetchDealers()
                }
                await this.fetchMargins(this.dealer.id)
                await this.setPricingSettings()   
            }
        })
    },

    /** 
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Return the current dealer’s store name.
         * 
         * @param {object} dealer The current dealer instance.
         * @return {string}
         */
        storeName(dealer) {
            if (dealer && dealer.store_name) {
                return dealer.store_name
            }
            return 'Connexions'
        },
        
        /**
         * Fetch and set the pricing settings from dealer custom properties.
         * 
         * @return {void}
         */
        setPricingSettings() {
            if (this.dealer && this.dealer.custom_properties) {
                this.sellPricing = this.dealer.custom_properties.pricing
                this.defaultMargin = this.dealer.custom_properties.margin
            }
        },

        /**
         * Toggle show / hide pricing for a given dealer id.
         * 
         * @param {number} dealer_id The dealer id to update.
         * @return {void}
         */
        toggleShowPricing(dealer_id) {
            this.updateDealerSettings({
                dealer_id: dealer_id,
                pricing: this.sellPricing
            })
        },

        updateDefaultMargin(dealer_id) {
            this.updateDealerSettings({
                dealer_id: dealer_id,
                margin: this.defaultMargin
            })
        },

        /**
         * Return human readable version of margin.
         * 
         * @param {string} percentage The margin percentage as decimal.
         * @return {string|null}
         */
        humanReadablePercentage(percentage) {
            if (percentage) {
                return (percentage * 100) + '%'
            }
            return null
        },

        /**
         * Return the name of the marginable.
         * 
         * @param {object} margin
         * @return {string|null}
         */
        marginableName(margin) {
            if (margin.marginable) {
                return margin.marginable.company_name ?? margin.marginable.name
            }
            return null
        },

        /**
         * Process the form submission.
         * 
         * @return {void}
         */
        async addMargin() {
            if (this.dealer) {
                await this.setProcessing(true)
    
                let params = {
                    dealer_id: this.dealer.id,
                    marginable_type: this.marginable_type,
                    marginable_id: this.marginable_id,
                    percentage: this.percentage,
                }
    
                await this.storeMargin(params)

                await this.resetMarginForm()
            }
        },

        /**
         * Reset the custom margin form.
         * 
         * @return {void}
         */
        resetMarginForm() {
            this.marginable_type = null
            this.marginable_id = null
            this.percentage = 0.30
            this.start_at = null
            this.expires_at = null
        },

        /** Store Actions */
        ...mapActions([
            'updateDealerSettings',
            'fetchMargins', 
            'fetchDealers',
            'deleteMargin',
            'storeMargin', 
            'fetchDealer',
        ]),


        /** Store Mutations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING'
        })
    },
}
</script>