<template>
    <div v-if="user" class="relative">
        <select-element 
            :options="userOptions"
            :fixed="false"
            :show="select === 'dashboard-options'"
            trigger-id="dashboard-options"
        ></select-element> 
        <transition name="fade" mode="out-in">
            <div v-if="name" class="w-screen p-4 text-grey-100 bg-grey-900 flex items-center justify-between">
                <div class="flex items-center">
                    <div 
                        @click="routeTo('/dashboard')" 
                        v-tippy="{ 
                            theme: 'translucent',  
                            arrow: true,
                            touch: false,
                            placement: 'top',
                        }"
                        content="<span class='text-blue-200'>Go</span> to the dashboard"
                        class="hover:opacity-50 cursor-pointer flex items-center"
                    >
                        <icon text-grey-100  type="home" view-box="0 0 24 24" width="20" height="20" class="fill-current mr-2"/>
                        <div class="text-lg hidden md:block">Dashboard</div> 
                    </div>
                    <div 
                        @click="routeTo('/')" 
                        v-tippy="{ 
                            theme: 'translucent',  
                            arrow: true,
                            touch: false,
                            placement: 'bottom',
                        }"
                        content="<span class='text-blue-100'>Go</span> to Public Website" 
                        class="mx-6 hover:opacity-50 cursor-pointer flex items-center"
                    >
                        <icon type="website" viewBox="0 0 24 24" width="20" height="20" class="fill-current mr-2"/>
                        <div class="text-lg hidden md:block">Website</div> 
                    </div>
                    <div 
                        @click="routeTo(profileUrl)" 
                        v-tippy="{ 
                            theme: 'translucent',  
                            arrow: true,
                            touch: false,
                            placement: 'bottom',
                        }"
                        content="<span class='text-blue-100'>Update</span> Your Profile" 
                        class="hover:opacity-50 cursor-pointer flex items-center"
                    >
                        <icon type="profile" viewBox="0 0 24 24" width="20" height="20" class="fill-current mr-2"/>
                        <div class="text-lg hidden md:block">Profile</div> 
                    </div>
                </div>
                <div class="flex items-center text-grey-100">
                    <div 
                        @click.stop="setSelect('dashboard-options')"
                        v-tippy="{ 
                            theme: 'translucent',  
                            arrow: true,
                            touch: false,
                            placement: 'bottom',
                        }"
                        content="User options" 
                        id="dashboard-options" 
                        class="flex items-center text-lg hover:opacity-50 cursor-pointer"
                    >
                        {{name}}
                        <icon class="fill-current " type="down" view-box="0 0 24 24" width="24" height="24" />
                    </div>
                </div>
                
            </div>
        </transition>
    </div>
</template>
<script>
// Store components
import { mapGetters, mapMutations, mapActions } from 'vuex'
/** Composition */
import SelectElement from '../../Public/Elements/SelectElement'
export default {
    /** Composition */
    components: {SelectElement},

    /** Local State */
    computed: {
        /**
         * The user’s name.
         * 
         * @return {string}
         */
        name() {
            if (this.user) {
                return this.user.name
            }
        },
        
        /**
         * The user’s current profile url.
         * 
         * @return {string}
         */
        profileUrl() {
            if (this.user) {
                return this.user.profile 
                    ? `/dashboard/profiles/${this.user.profile.id}/edit` 
                    : '/dashboard/profiles/create'
            }
        },

        /**
         * The user’s current profile url.
         * 
         * @return {string}
         */
        passwordUrl() {
            if (this.user) {
                return `/dashboard/users/${this.user.id}/security` 
            }
        },
        /**
         * The user’s options.
        * 
        * @return {object}
        */
        userOptions() {
            console.log()
            if (this.user) {                        
                return {
                    homepage: {
                        label:'Public Site', type:'dispatch', action:'routeTo', params: '/',
                    },
                    profile: {
                        label:'Profile', type:'dispatch', action:'routeTo', params: this.profileUrl,
                    },
                    password: {
                        label:'Password', type:'dispatch', action:'routeTo', params: this.passwordUrl,
                    },
                    logout: {
                        label:'Logout', type:'dispatch', action:'routeTo', params:'/logout',
                    },
                }      
            }      
        },

        /** Store Getters */
        ...mapGetters(['user', 'select'])
    },

    /** Non Reactive Properties */
    methods: {
        /** Store Actions */
        ...mapActions(['routeTo']),
        /** Store Mutations */
        ...mapMutations({setSelect:'SET_SELECT'})
    },
}
</script>