<template>
    <a 
        @mouseover="active = true" 
        @mouseout="active = false" 
        href="https://www.interface.com/US/en-US/campaign/quickship/quickship-en_US"
        target="_blank"
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>View</span> flooring offerings"
        class="w-full h-1/2 mt-2 bg-grey-900 hover:bg-blue-300 rounded"
        style="min-height: 160px"
    >
        <div class="h-full p-6 flex flex-col justify-end cursor-pointer">
            <div :class="{'text-grey-100': active, 'text-blue-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">Flooring</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">by Interface Services</div>
        </div>
    </a>
</template>
<script>
/** Store Components */ 
import { mapActions, mapGetters } from 'vuex'
export default {    
    /**
     * Local State
     */
    data() {
        return {
            active: false,
        }
    },
    computed: {
        /** Store Getters */
        ...mapGetters(['dealer'])
    },

    /**
     * Non-Reactive Properties
     */
    methods: {

        /** Store Actions */
        ...mapActions(['routeTo'])
    },
}
</script>