<template>
<div class="w-full">
    <!-- Image options -->
    <div ref="crop-options" class="w-full flex justify-center items-center text-grey-100 relative z-2" style="margin-top: 0">
        <div class="w-full my-4 flex justify-center items-center">
            <div v-if="uploadPreview || previewUrl" class="mr-2 flex justify-end items-center">
                <div class="mr-2 text-xs text-grey-600 truncate">Cropping</div>
                <select v-model="cropPosition" name="custom_properties[crop_position]" id="crop_position" class="block appearance-none my-2 pl-4 pr-10 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" >
                    <option>Select…</option>
                    <option 
                        v-for="(position, index) in ['top', 'center', 'bottom']"
                        :key="index"
                        :value="position"  
                        v-text="title(position)"
                    ></option>
                </select>
            </div>
            <div v-if="validBrowser" class="ml-2 flex justify-start items-center">
                <div class="flex-shrink flex flex-col">
                    <!-- <label for="image" class="mb-2 text-xs text-grey-600 truncate">Image <small><em class="text-red-200">(Required)</em></small></label> -->
                    <div @click="promptBrowse()">
                        <div class="py-2 px-4 text-grey-100 bg-blue-300 hover:opacity-50 rounded flex items-center cursor-pointer">
                            <div v-if="! uploadPreview && previewUrl == ''">Add Image</div>
                            <div v-else>Change Image</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Image Preview -->
    <div class="w-full flex justify-center">
        <div ref="preview-wrapper" class="preview-wrapper flex flex-col justify-center">
            <div 
                v-show="uploadPreview || previewUrl" 
                ref="crop-indicator-top"
                class="w-full mt-6 bg-black opacity-75" 
                style="height: 0; margin-bottom: 0"
            ></div>
            <div v-show="previewUrl && ! uploadPreview">
                <img :src="previewUrl" ref="previewUrl" class="preview-image" name="preview-image" />
            </div>
            <div v-show="uploadPreview" ref="uploadPreview"></div>
            <div 
                v-show="uploadPreview || previewUrl" 
                ref="crop-indicator-bottom"
                class="w-full bg-black opacity-75" 
                style="height: 0; margin-bottom: 0; margin-top: 0"
            ></div>
        </div>
    </div>    

    <!-- Image input -->
    <input 
        @change="uploadPreviewHandler()" 
        dusk="imageInput" 
        ref="imageInput" 
        type="file" 
        accept="image/jpeg"
        name="image" 
        id="image" 
        class="hidden" 
    >
</div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {

    /**
     * Interface
     */
    props: {
        previewUrl: {
            type: String,
            required: false,
            default: null,
        },
        promotion: {
            type: String,
            required: false,
            default: null,
        }
    },

    /**
     * Local State
     */
    data() {
        return {
            cropPosition: 'center',
            regex: /^([a-zA-Z0-9\s_\\.\-:])+(.jpg|.jpeg)$/,
            uploadPreview: false,
            validBrowser: false,
            previewHeight: 480
        }
    },

    /**
     * Events
     */
    watch: {
        /**
         * Whenever the preview image is changed, resize and 
         * reposition crop options and crop indicators.
         * 
         * @param {number} height The pixel height of the preview image.
         * @return {void}
         */
        async previewHeight(height) {
            // Only images require cropping.
            // if (height > 480) {
                // Show app is processing.
                await this.setProcessing(true)
                // Set the crop options state.
                await this.setCropOptions()
                // Set the crop indicator state.
                await this.setCropIndicators()
                // Set the preview wrapper state.
                await this.setPreviewWrapper()
                // Show process completed.
                await this.setProcessing(false)
            // }
        },

        /**
         * Whenever the crop position is changed, resize and 
         * reposition crop options and crop indicators.
         * 
         * @return {void}
         */
        async cropPosition() {
            // Show app is processing.
            await this.setProcessing(true)
            // Set the crop options state.
            await this.setCropOptions()
            // Set the crop indicator state.
            await this.setCropIndicators()
            // Set the preview wrapper state.
            await this.setPreviewWrapper()
            // Show app is processing.
            await this.setProcessing(false)
        }
    },
    /**
     * Life-Cycle Events
     */
    mounted () {
        this.browserCheck()

        this.$nextTick(() => {
            if (this.previewUrl) {
                this.previewUrlHandler()
            }
            if (this.promotion) {
                let parsedPromotion = JSON.parse(this.promotion)
                this.cropPosition = parsedPromotion.crop_position
            } else 
                console.log({hasPromotionAtMounted: false})
        })
    },
    
    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Return the title case of given value.
         * 
         * @param {string} value The text to be formatted.
         * @return {string} The formatted text.
         */
        title(value) {
            return _.startCase(value)
        },

        /**
         * Prompt the user to browse for an image. 
         * 
         * @return {void}
         */
        promptBrowse() {
            this.$refs["imageInput"].click()
        },

        /**
         * Ensure the user’s browser supports FileReader capability.
         * 
         * @return {void}
         */
        browserCheck() {
            // HTML5 compatible browser.
            if (typeof (FileReader) != "undefined") {
                this.validBrowser = true
                this.setProcessing(false)
            } else {
                this.setProcessing(true)
                this.setNotification({
                    type: 'warning',
                    title: 'Incompatible',
                    message: 'Your browser does not support file upload function. <a href="/promotions" class="text-blue-200 hover:opacity-50">Return to Promotions list.</a>',
                    duration: 9999999
                })
            }
        },

        /**
         * Trigger preview events when promotion image is loaded.
         * 
         * @return {void}
         */
        previewUrlHandler() {
            // Clear all html from preview DOM element.
            let previewImage = this.$refs["previewUrl"]
            setTimeout(() => {
                this.setPreviewHeight(previewImage.clientHeight)
            }, 1000)
        },

        /**
         * Generate a preview for the selected image.
         * 
         * @return {boolean}
         */
        uploadPreviewHandler() {
            // Reset the preview state.
            this.uploadPreview = false
            // Clear all html from preview DOM element.
            this.$refs["uploadPreview"].innerHTML = ""
            // Fetch the image file.
            let file = this.$refs["imageInput"] ? _.head(this.$refs["imageInput"].files) : null
            // Validate the file name is sanitized.
            if (file && this.regex.test(_.lowerCase(file.name))) {
                // Assign new FileReader object to variable.
                let reader = new FileReader()
                // Generate the preview image when onload event fires.
                this.generatePreview(reader)
                // Render the contents of the selected file. 
                reader.readAsDataURL(file)
                // Update preview state.
                this.uploadPreview = true
            } else {
                // Notify user of inavlid image file.
                this.setNotification({
                    type: 'danger',
                    title: 'Invalid image file',
                    message: 'You may have invalid characters in the filename. Please check and retry.',
                    duration: 8000,
                })
                return false
            }
            return true
        },

        /**
         * Generate a preview image from FileReader event.
         * 
         * @param {object} reader The FileReader reader object.
         * @return {void}
         */
        generatePreview(reader) {
            reader.onload = (event) => {
                // Fetch and assign preview DOM element.
                let uploadPreview = this.$refs["uploadPreview"]
                // Declare and assign newly created img tag.
                let img = document.createElement("IMG")
                // Add class to img tag.
                img.classList.add('generated-image')
                // Add src from FileReader event.
                img.src = event.target.result
                // Add name to img tag.
                img.setAttribute('name', 'generated-image')
                // Append into the DOM.
                uploadPreview.appendChild(img)
                // Set the preview height state. 
                setTimeout(() => this.setPreviewHeight(img.clientHeight) , 1000)
                
            }
        },

        /**
         * Set the current state of the crop indicators.
         * 
         * @return {void}
         */
        setPreviewWrapper() {            
            let verticalCrop = Math.round(this.previewHeight - 480)
            let halfVerticalCrop = Math.round(verticalCrop / 2)
            let previewWrapper = this.$refs['preview-wrapper']
            let promotionsForm = this.$refs['promotion-wrapper']

            if (previewWrapper) {
                switch (this.cropPosition) {
                    case ('center'):
                        previewWrapper.setAttribute('style', 'margin-top: -' + halfVerticalCrop + 'px; margin-bottom: -' + halfVerticalCrop + 'px')
                        break
                    case ('top'):
                        previewWrapper.setAttribute('style', 'margin-bottom: -' + verticalCrop + 'px')
                        break
                    case ('bottom'):
                        previewWrapper.setAttribute('style', 'margin-top: -' + verticalCrop + 'px')
                        break
                    default: 
                        break   
                }
            }   
        },

        /**
         * Set the current state of the crop indicators.
         * 
         * @return {void}
         */
        setCropIndicators() {            
            let cropOptions = this.$refs['crop-options']
            let verticalCrop = Math.round(this.previewHeight - 480)
            let halfVerticalCrop = Math.round(verticalCrop / 2)
            let topIndicator = this.$refs['crop-indicator-top']
            let bottomIndicator = this.$refs['crop-indicator-bottom']

            if (topIndicator, bottomIndicator) {
                switch (this.cropPosition) {
                    case ('center'):
                        topIndicator.setAttribute('style', 'margin-top: ' + halfVerticalCrop + 'px; margin-bottom: -' + halfVerticalCrop + 'px; height: ' + halfVerticalCrop + 'px')
                        bottomIndicator.setAttribute('style', 'margin-bottom: ' + halfVerticalCrop + 'px; margin-top: -' + halfVerticalCrop + 'px; height: ' + halfVerticalCrop + 'px')
                        break
                    case ('top'):
                        topIndicator.setAttribute('style', 'margin-top: 0px; margin-bottom: 0px; height: 0px')
                        bottomIndicator.setAttribute('style', 'margin-bottom: ' + verticalCrop + 'px; margin-top: -' + verticalCrop + 'px; height: ' + verticalCrop + 'px')
                        break
                    case ('bottom'):
                        topIndicator.setAttribute('style', 'margin-top: ' + verticalCrop + 'px; margin-bottom: -' + verticalCrop + 'px; height: ' + verticalCrop + 'px')
                        bottomIndicator.setAttribute('style', 'margin-bottom: 0px; margin-top: 0px; height: 0px')
                        break
                    default: 
                        break   
                }
            }   
        },

        /**
         * Set crop options select element top margin to accommodate preview height. 
         * 
         * @param {number} previewHeight The height of the preview image generated by FileReader.
         * @return {void}
         */
        setCropOptions(previewHeight) {
            let cropOptions = this.$refs['crop-options']
            let verticalCrop = Math.round(previewHeight - 480)
            let halfVerticalCrop = Math.round(verticalCrop / 2)

            switch (this.cropPosition) {
                case ('center'):
                    cropOptions.setAttribute('style', 'margin-top: -' + halfVerticalCrop +'px')
                    break
                case ('bottom'):
                    cropOptions.setAttribute('style', 'margin-top: -' + verticalCrop +'px')
                    break
                default: 
                    break   
            }
        },
        /**
         * Set the current state of the preview height.
         * 
         * @param {number} height The image height.
         * @return {void}
         */
        async setPreviewHeight(height) {
            this.previewHeight = height
        },

        /**
         * Store Mutations
         */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING',
            setNotification:'SET_NOTIFICATION',
        }),
    }
}
</script>
<style lang="sass">
    .preview-wrapper, .generated-image, .preview-image
        width: 960px
        height: auto
</style>