<template>
    <div class="h-1/2 bg-grey-900 rounded">
        <div class="h-full p-6 flex flex-col justify-end">
            <div class="w-full flex text-grey-100">
                <div class="w-full flex flex-col">
                    <!-- <label for="status" class="text-xs text-grey-600">Select Dealer</label>  -->
                    <div class="w-full relative">
                        <select v-model="dealerId" class="block appearance-none w-full px-2 py-1 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded">
                            <option value="select">Select&hellip;</option>
                            <option
                                v-for="dealer in parsedDealers"
                                :key="dealer.id"
                                :value="dealer.id">[{{dealer.store_location}}] {{dealer.store_name}} ({{dealer.store_number}})
                            </option>
                        </select>
                        <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                            <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-purple-100 relative z-4" >Register Codes</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100 flex">Get Dealer Registration Code</div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
/** Interface */
    props: {
        dealers: {
            type: String,
            required: false,
            default: null,
        }
    },

    /** Local State */
    data() {
        return{
            dealerId: 'select',
        }
    },
    computed: {
        /**
         * Return the collection of dealer objects.
         *
         * @return {collection}
         */
        parsedDealers() {
            if (this.dealers) {
                let dealers = JSON.parse(this.dealers)

                return _.sortBy(dealers, ['store_location'])
            }
        },

        /** Store Getters */
        ...mapGetters(['dealerCode'])
    },

    /** Events */
    watch: {
        dealerId(id) {
            this.fetchDealerCode(id)
        },

        dealerCode(code) {
            this.setNotification({
                type: 'primary',
                title: 'Registration Code',
                message: `${code}`,
                duration: 999999
            })
        }
    },

    /** non Reactive Properties */
    methods: {
        /** Store Actions */
        ...mapActions(['fetchDealerCode']),
        /** Store Mutations */
        ...mapMutations({setNotification: 'SET_NOTIFICATION'}),
    },
}
</script>
