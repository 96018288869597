<template>
    <div class="w-screen py-6 bg-neutral-20 relative z-0 overflow-hidden">
        <div class="w-full px-4 py-2 text-grey-600 text-xl md:text-2xl tracking-tight">Featured Solutions</div>
        <div class="w-auto flex bg-neutral-20 justify-around items-center ">
            <home-carousel 
                dusk="home-carousel-component" 
                :environments="parsedEnvironments"
            ></home-carousel>
        </div>
    </div>
</template>
<script>
import HomeCarousel from '../Modules/dependencies/HomeCarousel'
export default {

    /** Composition */
    components: {HomeCarousel},

    /** Interface */
    props: {
        environments: {
            type: String,
            required: false,
            default: null
        }
    },

    /** Local State */
    computed: {
        parsedEnvironments() {
            if (this.environments) {
                return JSON.parse(this.environments)
            }
            return [];
        }
    }
    
}
</script>