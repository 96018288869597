<template>
    <div v-if="parsedPromotion" @click="touch()" class="w-screen relative z-0 cursor-pointer">
        <div class="relative bg-grey-500">
            <img class="w-full h-auto bg-grey-800" :src="parsedPromotion.url" />
            <div 
                v-if="parsedPromotion.body && isDesktop"
                v-html="parsedPromotion.body"
                class="tip-tap w-1/4 mt-12 p-6 absolute top-0 left-0 text-grey-100 rounded-r" 
                style="background-color: rgba(26, 32, 44, 0.5)"
            ></div>
            <div 
                v-else
                v-html="parsedPromotion.body"
                class="w-full mb-6 p-4 text-grey-100 bg-grey-700"
            ></div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        promotion: {
            type: String,
            required: true,
        },
    },

    /**
     * Local State
     */
    computed: {
        /**
         * Parse the json string into promotion object.
         */
        parsedPromotion() {
            if (this.promotion) {
                return JSON.parse(this.promotion)
            }
        },

        /** Store Getters */
        ...mapGetters(['isDesktop'])
    },

    methods: {
        /**
         * Increment the touches count when promotion is clicked.
         */
        touch() {
            if (this.parsedPromotion && this.parsedPromotion.link_to) {
                axios 
                    .patch(`/promotions/${this.parsedPromotion.id}/touch`)
                    .then(response => window.location = response.data)
                    .catch(errors => console.warn(errors))
            }
        },

        /**
         * The Store Actions
         */
        ...mapActions(['routeTo'])
    }
}
</script>
<style lang="sass">
    .tip-tap > h1
        line-height: 1

    .tip-tap > h2
        line-height: 1.375

    .tip-tap > h3
        margin-top: 0.25rem
        line-height: 1.375
        color: theme('colors.grey.400')
        
    .tip-tap > p
        margin-top: 0.5rem
        line-height: 1.375
        color: theme('colors.grey.400')

</style>