<template>
    <button @click="setProcessing(true)" type="submit" class="py-2 px-4 text-blue-300 hover:opacity-50 rounded">{{label}}</button>
</template>
<script>
// Store components.
import { mapMutations } from 'vuex'

export default {
    /**
     * Interface
     */
    props: {
        /**
         * The button label.
         * 
         * @var {string, null}
         */
        label: {
            type: String,
            required: false,
            default: 'Submit',
        },
    },

    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Store Mutations
         */
        ...mapMutations({setProcessing: 'SET_PROCESSING'})
    }
}
</script>