const BudgetModule = {
    state: {
        /**
         * The budget packages state.
         * 
         * @var {array|null}
         */
        budgetPackages: null,
    },

    getters: {
        /**
         * Get the current budget packages state.
         * 
         * @var {array|null}
         */
        budgetPackages({ budgetPackages }) {
            return budgetPackages
        }
    },

    actions: {
        /**
         * Fetch the budget packages fro a given occupancy.
         * 
         * @param {object} commit The store commit module.
         * @param {number} occupancy The occupancy to base the packages on.
         * @return {void}
         */
        getBudgetPackages({commit}, occupancy) {

            commit('SET_PROCESSING', true)

            axios 
                .get(`/api/budgets/${occupancy}`)
                .then(response => commit('SET_BUDGET_PACKAGES', response.data))
                .then(commit('SET_PROCESSING', false))
                .catch(errors => console.warn(errors))
                .then(commit('SET_PROCESSING', false))
        },

        submitBudgetInquiry({}, input) {
            axios 
                .post
        },
    },

    mutations: {
        /**
         * Mutate the budget packages state.
         * 
         * @param {object} state The store state module.
         * @param {array|null} budgetPackages The mutation value.
         */
        SET_BUDGET_PACKAGES(state, budgetPackages) {
            state.budgetPackages = budgetPackages
        }
    },
}

export default BudgetModule