import store from '../../store'

const DealerModule = {
    state: {
        /**
         * The app’s dealer state.
         * 
         * @var {object}
         */
        dealer: null,

        /**
         * The app’s dealers state.
         * 
         * @var {array}
         */
        dealers: null,
        /**
         * The app’s dealer code state.
         * 
         * @var {string}
         */
        dealerCode: null,
    },

    getters: {
        /**
         * Get the current dealer state. 
         * 
         * @param {object} dealer The dealer param from store.state object 
         */
        dealer({ dealer }) {
            return dealer
        },

        /**
         * Get the current dealers state. 
         * 
         * @param {array} dealers The dealers param from store.state object 
         */
        dealers({ dealers }) {
            return dealers
        },

        /**
         * Get the current dealer code state. 
         * 
         * @param {string} dealerCode The dealer code param from store.state object 
         */
        dealerCode({ dealerCode }) {
            return dealerCode
        },
    },

    actions: {
        /**
         * Fetch dealer from repository.
         * 
         * @param {object} commit The store commit module. 
         * @param {number} id The dealer id.
         * @return {void}
         */
        fetchDealer({ commit }, id) {
            commit('SET_PROCESSING', true)
            axios 
                .get(`/api/dealers/${id}`)
                .then(response => commit('SET_DEALER', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn({errors}))
                .then(() => commit('SET_PROCESSING', false))
        },

        /**
         * Fetch all dealers from repository.
         * 
         * @param {object} commit The store commit module. 
         * @return {void}
         */
        fetchDealers({ commit }) {
            commit('SET_PROCESSING', true)
            axios 
                .get(`/api/dealers`)
                .then(response => commit('SET_DEALERS', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn({errors}))
                .then(() => commit('SET_PROCESSING', false))
        },

        /**
         * Update a given dealer’s pricing settings in repository.
         * 
         * @param {object} commit The store commit module. 
         * @param {object} params The settings params.
         * @return {void}
         */
        updateDealerSettings({ commit }, params) {
            commit('SET_PROCESSING', true)
            axios 
                .patch(`/api/dealers/${params.dealer_id}/settings`, params)
                .then(response => commit('SET_DEALER', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn({errors}))
                .then(() => commit('SET_PROCESSING', false))
        },

        /**
         * Get abd set the dealer code for a given dealer_id.
         * 
         * @param {object} commit The store commit module.
         * @param {number} id The dealer id.
         * @return {void}
         */
        fetchDealerCode({ commit }, id) {
            console.log({fetchDealerCode: id})
            commit('SET_PROCESSING', true)
            axios 
                .get(`/api/dealers/${id}/registration-code`)
                .then(response => {
                    console.log({SET_DEALER_CODE: response.data})
                    commit('SET_DEALER_CODE', response.data)
                })
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn({errors}))
                .then(() => commit('SET_PROCESSING', false))
        },
    },

    mutations: {
        /**
         * Set the current dealer state. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {object} dealer The dealer object.
         */
        SET_DEALER(state, dealer) {
            state.dealer = dealer
        },

        /**
         * Set the current dealers state. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {array} dealers The dealers array.
         */
        SET_DEALERS(state, dealers) {
            state.dealers = dealers
        },

         /**
         * Set the current dealer code state. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {array} dealerCode The dealer code.
         */
        SET_DEALER_CODE(state, dealerCode) {
            state.dealerCode = dealerCode
        }
    },
}

export default DealerModule
