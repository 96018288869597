<template>
    <transition name="fade" mode="out-in">
        <div v-if="confirmation" class="w-screen h-screen flex justify-center items-center relative z-20">
            <div class="w-screen h-screen fixed top-0 left-0 z-20"></div>
            <div class="w-128 h-64 bg-grey-900 p-6 flex flex-col justify-between relative z-24">
                <div class="w-full">
                    <div 
                        :class="{
                            'text-red-300': confirmation.level == 'danger',
                            'text-yellow-300': confirmation.level == 'warning',
                            'text-grey-300': confirmation.level == 'info',
                        }"
                        class="text-xl"
                    >Confirmation</div>
                    <div v-html="confirmation.message" class="mt-6 text-base text-grey-100"></div>
                </div>
                <div class="w-full mr-6 flex items-center justify-end">
                    <div @click="rejectConfirmation()" class="p-4 text-grey-600 hover:opacity-50 cursor-pointer">Cancel</div>
                    <div 
                        dusk="accept-confirmation"
                        @click="acceptConfirmation(confirmation)" 
                        :class="{
                            'text-red-300': confirmation.level == 'danger',
                            'text-yellow-300': confirmation.level == 'warning',
                            'text-grey-300': confirmation.level == 'info',
                        }"
                        class="p-4 hover:opacity-50 cursor-pointer"
                    >Confirm</div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
// Store components.
import { mapGetters, mapActions } from 'vuex'
export default {
    /**
     * Local State
     */
    computed: {
        /**
         * Store Getters
         */
        ...mapGetters(['confirmation']),
    },

    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Store Actions
         */
        ...mapActions(['acceptConfirmation', 'rejectConfirmation'])
    }
}
</script>