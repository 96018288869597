const ProductModule = {
    state: {
        /**
         * The current resource state.
         * 
         * @var {object}
         */
        resource: null,
    },

    getters: {
        /**
         * Get the current resource state. 
         * 
         * @return {object|null}
         */
        resource({ resource }) {
            return resource
        },
    },

    actions: {
        //
    },

    mutations: {
        /**
         * Mutate the resource state. 
         * 
         * @param {object} state The store state module.
         * @param {object|null} resource The resource state mutatation.
         * @return {void}
         */
        SET_RESOURCE(state, resource) {
            state.resource = resource
        },
    },
}

export default ProductModule