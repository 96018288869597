// Vue model
import Vue from 'vue'

// Structure
import CatalogHeader from './Structure/CatalogHeader'
import Confirmation from './Structure/Confirmation'
import Notification from './Structure/Notification'
import PageFooter from './Structure/PageFooter'
import PageHeader from './Structure/PageHeader'
import Processing from './Structure/Processing'
import SideDrawer from './Structure/SideDrawer'

// Forms
import BudgetForm from './Forms/BudgetForm'
import ShareForm from './Forms/ShareForm'
import RequestPrice from './Forms/RequestPrice'

// Elements
import SelectElement from './Elements/SelectElement'

// Help
import { TippyComponent } from "vue-tippy"

// Icons
import Icon from './Icons/Icon'
import Add from './Icons/Add'
import Alert from './Icons/Alert'
import Back from './Icons/Back'
import Catalog from './Icons/Catalog'
import CheckMixed from './Icons/CheckMixed'
import CheckOff from './Icons/CheckOff'
import CheckOn from './Icons/CheckOn'
import Close from './Icons/Close'
import Delete from './Icons/Delete'
import Done from './Icons/Done'
import Down from './Icons/Down'
import Download from './Icons/Download'
import Edit from './Icons/Edit'
import Email from './Icons/Email'
import Explore from './Icons/Explore'
import Favorite from './Icons/Favorite'
import First from './Icons/First'
import Home from './Icons/Home'
import InviteAccept from './Icons/InviteAccept'
import InviteNew from './Icons/InviteNew'
import InviteNone from './Icons/InviteNone'
import InviteSent from './Icons/InviteSent'
import Last from './Icons/Last'
import Launch from './Icons/Launch'
import Load from './Icons/Load'
import Menu from './Icons/Menu'
import Next from './Icons/Next'
import New from './Icons/New'
import Order from './Icons/Order'
import Options from './Icons/Options'
import Preview from './Icons/Preview'
import Previous from './Icons/Previous'
import Process from './Icons/Process'
import Profile from './Icons/Profile'
import Search from './Icons/Search'
import Secure from './Icons/Secure'
import Share from './Icons/Share'
import Show from './Icons/Show'
import Sort from './Icons/Sort'
import Update from './Icons/Update'
import Up from './Icons/Up'
import Website from './Icons/Website'

// Panels
import BrandsPanel from './Panels/BrandsPanel'
import ProductsPanel from './Panels/ProductsPanel'
import SearchPanel from './Panels/SearchPanel'
import EnvironmentsPanel from './Panels/EnvironmentsPanel'

// Modules
import EnvironmentListing from './Modules/EnvironmentListing'
import FavoriteManager from './Modules/FavoriteManager'
import FeaturedEnvironments from './Modules/FeaturedEnvironments'
import FeaturedPromotion from './Modules/FeaturedPromotion'
import ResourceImage from './Modules/ResourceImage'
import ProductListing from './Modules/ProductListing'

// Widgets
import DeleteWidget from './Widgets/DeleteWidget'
import FavoriteWidget from './Widgets/FavoriteWidget'
import FeatureWidget from './Widgets/FeatureWidget'
import ImageWidget from './Widgets/ImageWidget'
import PerPageWidget from './Widgets/PerPageWidget'
import RatingWidget from './Widgets/RatingWidget'
import TipWidget from './Widgets/TipWidget'

// Structure
Vue.component('catalog-header', CatalogHeader)
Vue.component('confirmation', Confirmation)
Vue.component('notification', Notification)
Vue.component('page-header', PageHeader)
Vue.component('page-footer', PageFooter)
Vue.component('processing', Processing)
Vue.component('side-drawer', SideDrawer)

// Elements
Vue.component('select-element', SelectElement)

// Forms
Vue.component('budget-form', BudgetForm)
Vue.component('share-form', ShareForm)
Vue.component('request-price', RequestPrice)

// Help
Vue.component("tippy", TippyComponent);

// Icons
Vue.component('icon', Icon)
Vue.component('icon-alert', Alert)
Vue.component('icon-add', Add)
Vue.component('icon-back', Back)
Vue.component('icon-catalog', Catalog)
Vue.component('icon-check-mixed', CheckMixed)
Vue.component('icon-check-off', CheckOff)
Vue.component('icon-check-on', CheckOn)
Vue.component('icon-close', Close)
Vue.component('icon-delete', Delete)
Vue.component('icon-down', Down)
Vue.component('icon-download', Download)
Vue.component('icon-done', Done)
Vue.component('icon-edit', Edit)
Vue.component('icon-email', Email)
Vue.component('icon-explore', Explore)
Vue.component('icon-favorite', Favorite)
Vue.component('icon-first', First)
Vue.component('icon-home', Home)
Vue.component('icon-invite-accept', InviteAccept)
Vue.component('icon-invite-new', InviteNew)
Vue.component('icon-invite-none', InviteNone)
Vue.component('icon-invite-sent', InviteSent)
Vue.component('icon-last', Last)
Vue.component('icon-launch', Launch)
Vue.component('icon-load', Load)
Vue.component('icon-menu', Menu)
Vue.component('icon-next', Next)
Vue.component('icon-new', New)
Vue.component('icon-order', Order)
Vue.component('icon-options', Options)
Vue.component('icon-preview', Preview)
Vue.component('icon-previous', Previous)
Vue.component('icon-process', Process)
Vue.component('icon-profile', Profile)
Vue.component('icon-search', Search)
Vue.component('icon-secure', Secure)
Vue.component('icon-share', Share)
Vue.component('icon-show', Show)
Vue.component('icon-sort', Sort)
Vue.component('icon-up', Up)
Vue.component('icon-update', Update)
Vue.component('icon-website', Website)

// Panels
Vue.component('brands-panel', BrandsPanel)
Vue.component('products-panel', ProductsPanel)
Vue.component('search-panel', SearchPanel)
Vue.component('environments-panel', EnvironmentsPanel)

// Modules
Vue.component('environment-listing', EnvironmentListing)
Vue.component('favorite-manager', FavoriteManager)
Vue.component('featured-promotion', FeaturedPromotion)
Vue.component('featured-environments', FeaturedEnvironments)
Vue.component('resource-image', ResourceImage)
Vue.component('product-listing', ProductListing)

// Widgets
Vue.component('delete-widget', DeleteWidget)
Vue.component('favorite-widget', FavoriteWidget)
Vue.component('feature-widget', FeatureWidget)
Vue.component('image-widget', ImageWidget)
Vue.component('per-page-widget', PerPageWidget)
Vue.component('rating-widget', RatingWidget)
Vue.component('tip-widget', TipWidget)
