<template>
<div>
    <select-element 
        :options="statusOptions"
        :fixed="false"
        :show="select === `status-options-${prospectId}`"
        :trigger-id="`status-options-${prospectId}`"
    ></select-element>
    <div
        v-if="selected"
        @click.stop="handler()" 
        :id="`status-options-${prospectId}`"
        :class="textColor"
        class="w-32 my-2 px-4 py-2 bg-grey-900 hover:opacity-50 flex items-center justify-between rounded cursor-pointer relative"
    >
        {{selected}}
        <icon class="fill-current " type="down" view-box="0 0 24 24" width="24" height="24" />
    </div>
</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        prospectResource: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            required: false,
            default: 'text-grey-100'
        }
    },
    /**
     * Local State
     */
    computed: {
        /**
         * The prospect id. 
         * 
         * @return {number}
         */
        prospectId() {
            if (JSON.parse(this.prospectResource)) {
                return JSON.parse(this.prospectResource).id
            }
        },

        /**
         * The selected status option. 
         * 
         * @return {string}
         */
        selected() {
            if (JSON.parse(this.prospectResource)) {
                if (this.prospect && this.prospect.id == JSON.parse(this.prospectResource).id) {
                    return this.prospect.status
                }
                return JSON.parse(this.prospectResource).status
           }
        },
        /**
         * The prospect’s status options.
        * 
        * @return {object}
        */
        statusOptions() {     
            if (this.prospectId) {
                return {
                    open: {
                        label:'Open', 
                        type:'dispatch', 
                        action:'updateProspect', 
                        params: {
                            id:`${this.prospectId}`, 
                            status:'Open'
                        },
                    },
                    qualified: {
                        label:'Qualified', 
                        type:'dispatch', 
                        action:'updateProspect', 
                        params: {
                            id:`${this.prospectId}`, 
                            status:'Qualified'
                        },
                    },
                    closed: {
                        label:'Closed', 
                        type:'dispatch', 
                        action:'updateProspect', 
                        params: {
                            id:`${this.prospectId}`, 
                            status:'Closed'
                        },
                    },
                }    
            }
        },
        /**
        * Store Getters
        */
        ...mapGetters(['prospect', 'status', 'select'])
    },
    /**
     * Events
     */
    watch: {
        /**
         * Update the prospect when status changes. 
         * 
         * @param {sting} status The current status. 
         * @param {sting} previous The previous status. 
         * @return {void}
         */
        status(status, previous) {
            // Declare and assign prospect to variable.
            let prospect = JSON.parse(this.prospectResource)
            // Is current prospect; Had a previous status; Status has changed.
            if (this.prospect.id == prospect.id && ! previous && status != previous) {
                // Update prospect.
                this.updateProspect({id: prospect.id, status})
            }
        },
    },

    /**
     * Non Reactive Properties
     */
    methods: {
        async handler() {
            await this.setProspect(JSON.parse(this.prospectResource))
            await this.setSelect(`status-options-${this.prospectId}`)
        },
        /**
         * Store Actions
         */
        ...mapActions(['updateProspect']),
        /**
         * Store Mutations
         */
        ...mapMutations({
            setProspect: 'SET_PROSPECT',
            setStatus: 'SET_STATUS', 
            setSelect:'SET_SELECT',
        })
    },
}
</script>