/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'

const NavigationMixins = {
    /** Local State */
    computed: {
        /** Store Getters */
        ...mapGetters([
            'panel'
        ])
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Handle environment navigation click. 
         * 
         * @return void
         */
        async showEnvironments() {
            await window.scrollTo({
                top: 0,
                behavior: "smooth",
            })
            await this.setPanel('environments')
        },
        /**
         * Handle environment navigation click. 
         * 
         * @return void
         */
        showProducts() {
            this.setPanel('products')
        },
        /**
         * Handle brands navigation click. 
         * 
         * @return void
         */
        showBrands() {
            this.setPanel('brands')
        },
        /**
         * Handle budget navigation click. 
         * 
         * @return void
         */
        showBudget() {
            this.setForm('budget')
        },
        /**
         * Handle resources navigation click. 
         * 
         * @return void
         */
        showResources() {
            this.setPanel(null)
        },

        /** Store Mutations */
        ...mapMutations({
            setPanel: 'SET_PANEL',
            setForm: 'SET_FORM',
        })
    }
}

export default NavigationMixins