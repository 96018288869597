const ProspectModule = {
    state: {
        /**
         * The prospect state.
         * 
         * @var {object, null}
         */
        prospect: null,
        /**
         * The status state.
         * 
         * @var {string, null}
         */
        status: null,
    },
    getters: {
        /**
         * Get the prospect state.
         * 
         * @param {object} prospect The current prospect state.
         * @return {object} 
         */
        prospect({ prospect }) {
            return prospect
        },
        /**
         * Get the status state.
         * 
         * @param {string} status The current status state.
         * @return {object} 
         */
        status({ status }) {
            return status
        },
    },
    actions: {
        /**
         * Update a user’s prospect.
         * 
         * @param {object} commit The store commit object.
         * @param {object} params The update params 
         */
        async updateProspect({ commit, dispatch }, input) {
            if (input && input.id) {
                await commit('SET_PROCESSING', true)
                await axios
                    .patch(`/dashboard/prospects/${input.id}`, input)
                    .then(() => console.log(response.data))
                    .catch(errors => console.log(errors))
                    
                dispatch('routeTo', '/dashboard/prospects')
            }
        },

        /**
         * Delete the user’s prospect.
         * 
         * @param {object} commit The store commit object
         * @param {string} id The prospect id to be deleted.
         */
        async deleteProspect({ commit }, id) {
            await commit('SET_PROCESSING', true)
            axios 
                .delete(`/dashboard/prospects/${id}`)
                .then(() => {
                    // create hyperlink to avoid browser blocked reload.
                    let link = document.createElement('a')
                    link.setAttribute("href", "/dashboard/prospects")
                    link.classList.add("hidden")
                    link.click()
                })
                .catch(errors => console.log(errors))
                .then(() => location.reload())
        },
    },
    mutations: {
        /**
         * 
         * @param {object} state The store state object.
         * @param {object, null} prospect The current prospect state.
         */
        SET_PROSPECT(state, prospect) {
            state.prospect = prospect
        },
        /**
         * 
         * @param {object} state The store state object.
         * @param {string, null} status The current status state.
         */
        SET_STATUS(state, status) {
            state.status = status
        },
    },
}

export default ProspectModule