import Axios from "axios"

const PromotionModule = {
    state: {},
    getters: {},
    actions: {
         /**
         * Delete given promotion.
         * 
         * @param {object} commit The store commit object
         * @param {string} id The promotion id to be deleted.
         */
        async deletePromotion({ commit }, id) {
            await commit('SET_PROCESSING', true)
            await axios
                .delete(`/promotions/${id}`)
                .then(response => {
                    // create hyperlink to avoid browser blocked reload.
                    let link = document.createElement('a')
                    link.setAttribute("href", "/promotions")
                    link.classList.add("hidden")
                    link.click()
                })
                .catch(errors => console.log(errors))
                .then(() => location.reload())
        }
    },
    mutations: {}
}

export default PromotionModule