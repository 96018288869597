<template>
    <div v-if="isPaginated" class="h-100 flex items-center">
        <div class="mx-3 text-xs text-grey-600 font-medium leading-loose">Items Per Page</div>
        <div class="relative">
            <select name="sort" v-model="perPage" class="block appearance-none w-auto my-2 pr-10 pl-4 py-2 bg-grey-300 text-grey-600 hover:opacity-50 rounded">
                <!-- <option value="24" class="text-grey-600">24</option> -->
                <option value="48" class="text-grey-600">48</option>
                <option value="96" class="text-grey-600">96</option>
                <!-- <option value="1000" class="text-grey-600">Show All</option> -->
            </select>
            <div class="mr-4 pointer-events-none text-grey-600 flex items-center absolute inset-y-0 right-0">
                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Local State */
    data() {
        return {
            perPage: null,
        }
    },
    computed: {
        /**
         * The current page is paginated.
         * 
         * @return {boolean}
         */
        isPaginated() {
            return window.location.pathname.includes('/list') && window.location.pathname.includes('/products')
        },
    },

    /** Life Cycle Events */
    mounted() {
        let params = (new URL(document.location)).searchParams
        this.perPage = params.get('perPage') ?? 96
    },

    /** Events */
    watch: {
        /**
         * Update the perPage state when local value changes.
         * 
         * @param {number} sort The local perPage value.
         * @return {void}
         */
        perPage: {
            deep: true,
            handler() {
                let params = (new URL(document.location)).searchParams

                if (this.isPaginated && params && this.perPage !== params.get('perPage')) {
                    let sort = params.get('sort') ?? 'rating'
                    let order = params.get('order') ?? 'desc'
                    let perPage = this.perPage ?? 96
                    let page = params.get('page') ?? 1
                    window.location = `${window.origin}${window.location.pathname}?order=${order}&sort=${sort}&perPage=${perPage}&page=${page}`
                }
            }
        },        
    },
}
</script>