<template>
    <div 
        @mouseover="active = true" 
        @mouseout="active = false" 
        @click="routeTo('/dashboard/prospects')"
        class="w-full h-full"
    >
        <div  
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            content="<span class='text-blue-200'>Manage</span> your prospects"
            class="h-full p-6 bg-grey-900 hover:bg-yellow-300 flex flex-col justify-end rounded cursor-pointer"
        >
            <div
                class="w-full h-full"
            ></div>
            <div :class="{'text-grey-100': active, 'text-yellow-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">Prospects</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">Add and Develop Sales Leads</div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions } from 'vuex'
export default {

    /**
     * Local State
     */
    data() {
        return {
            active: false,
        }
    },
    
    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Store Actions
         */
        ...mapActions(['routeTo'])
    },
}
</script>