var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"notification","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"w-screen flex justify-center fixed bottom-0 z-24"},[_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/4 xl:w-1/5 text-grey-100 leading-normal tracking-normal flex flex-col items-center bg-darken-80 rounded-t"},[_c('div',{staticClass:"w-full pt-2 px-2 flex justify-end"},[_c('div',{staticClass:"w-10 h-10 flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer",attrs:{"dusk":"close-notification"},on:{"click":function($event){return _vm.setDuration(0)}}},[_c('icon',{attrs:{"type":"close","on":"click","mutator":"SET_NOTIFICATION","params":{type: null, title:null, message:null, duration:0},"view-box":"0 0 24 24","width":"24","height":"24"}})],1)]),_vm._v(" "),(_vm.notification && _vm.notification.title)?_c('div',{staticClass:"w-full px-4 py-2 text-xl",class:{
                    'text-grey-900': _vm.notification && _vm.notification.type == 'info',
                    'text-blue-200': _vm.notification && _vm.notification.type == 'primary',
                    'text-green-300': _vm.notification && _vm.notification.type == 'success',
                    'text-yellow-400': _vm.notification && _vm.notification.type == 'warning',
                    'text-red-400': _vm.notification && _vm.notification.type == 'danger',
                },domProps:{"innerHTML":_vm._s(_vm.notification.title)}}):_vm._e(),_vm._v(" "),(_vm.notification && _vm.notification.message)?_c('div',{staticClass:"w-full px-4",domProps:{"innerHTML":_vm._s(_vm.notification.message)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full h-2 mt-12 relative bottom-0",class:{
                    'bg-grey-900': _vm.notification && _vm.notification.type == 'info',
                    'bg-blue-300': _vm.notification && _vm.notification.type == 'primary',
                    'bg-green-300': _vm.notification && _vm.notification.type == 'success',
                    'bg-yellow-400': _vm.notification && _vm.notification.type == 'warning',
                    'bg-red-400': _vm.notification && _vm.notification.type == 'danger',
                }})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }