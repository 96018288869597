<template>
    <div 
        @mouseover=" ! isAdmin ? active = true : null" 
        @mouseout=" ! isAdmin ? active = false : null" 
        class="w-full h-1/2 bg-grey-900 rounded"
        style="min-height: 160px"
    >
        <div 
            :class="{'cursor-pointer hover:bg-blue-300': ! isAdmin}" 
            @click="! isAdmin ? downloadSpecifications(userDealerId): null"
            class="h-full p-6 flex flex-col justify-end "
        >
            <div v-if="isAdmin" class="w-full flex text-grey-100">
                <div class="w-full flex flex-col">
                    <!-- <label for="status" class="text-xs text-grey-600">Select Dealer</label>  -->
                    <div class="w-full relative">    
                        <select 
                            v-model="dealerId" 
                            @change="downloadSpecifications(dealerId)"
                            v-tippy="{ 
                                theme: 'translucent',  
                                arrow: true,
                                touch: 'false',
                                placement: 'top',
                            }"
                            :content="tips.admin"
                            name="dealer_id" 
                            id="dealer_id" 
                            class="block appearance-none w-full px-2 py-1 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" 
                        >
                            <option :value="null">Select Dealer…</option>
                            <option
                                v-for="dealer in parsedDealers"
                                :key="dealer.id"
                                :value="dealer.id"
                            >{{dealer.store_name}} ({{dealer.store_number}}) - {{dealer.store_location}}</option>
                        </select>
                        <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                            <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-else
                class="w-full h-full"
            ></div>
            <div 
                v-if="! isAdmin"
                v-tippy="{ 
                    theme: 'translucent',  
                    arrow: true,
                    touch: 'false',
                    placement: 'top',
                }"
                :content="tips.user"
                :class="{'text-blue-200': ! active, 'text-grey-100': active}"
                class="h-full text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4"
            >Discounts</div>
            <div v-else class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-100 relative z-4" >Discounts</div>
            <div v-if="updatedAt" class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100 flex">{{updatedAt}}</div>
            <div v-else class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100 flex">Download Vendor Discounts</div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Dependencies
import moment from 'moment'

export default {

    props:{
        isAdmin: {
            type: Boolean|String,
            required: false,
            default: false,
        },
        dealers: {
            type: String, // json encoded from controller
            required: false,
            default: JSON.stringify([]),
        },
        lastUpdated: {
            type: String,
            required: false,
            default: null,
        }
    },

    /**
     * Local State
     */
    data() {
        return {
            tips: {
                admin: "<span class='text-blue-200'>Select</span> a dealer to download PDF", 
                user: "<span class='text-blue-200'>Download</span> Vendor discounts and specifications information"
            },
            dealerId: null,
            active: false,
            moment,
        }
    },
    computed: {
        /**
         * The user’s dealer id.
         * 
         * @var {integer}
         */
        userDealerId() {
            if (this.dealer) {
                return this.dealer.id
            }
        },

        /**
         * The user’s dealer store name.
         * 
         * @var {string}
         */
        userDealerStore() {
            if (this.dealer) {
                return this.dealer.store_name
            }
            return 'Your'
        },

        /**
         * The parsed collection of dealers.
         * 
         * @var {object}
         */
        parsedDealers() {
            if (this.dealers) {
                return JSON.parse(this.dealers)
            }
        },

        /**
         * Human readable update date.
         * 
         * @var {string}
         */
        updatedAt() {
            if (this.lastUpdated) {
                return `Last updated on ${this.lastUpdated}`
            }
        },

        /**
         * Store Getters
         */
        ...mapGetters(['dealer'])
    },
    
    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Download current specifications.
         * 
         * @param {number} id The dealer id.
         * @return {void}
         */
        downloadSpecifications(id) {
            // console.log({id:this.userDealerId, dealer:this.dealer})
            this.setNotification({
                type: 'primary',
                title: 'Downloading…',
                message: 'Please wait while we process your request.',
                duration: 5000
            })

            setTimeout(() => {
                window.location = '/specifications/dealers/' + id
            }, 3000)
        },

        /**
         * Store Actions
         */
        ...mapActions(['routeTo']),

        /**
         * Store Mutations
         */
        ...mapMutations({setNotification:'SET_NOTIFICATION'}),
    },
}
</script>