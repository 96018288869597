import store from '../../store'

const LinkModule = {
    state: {
        /**
         * The app’s link category state.
         * 
         * @var {string}
         */
        linkCategory: null,
        /**
         * The app’s link reordering state.
         * 
         * @var {boolean}
         */
        reordering: false,
    },

    getters: {
        /**
         * Get the app’s current link category state.
         * 
         * @param {object} store.param The linkCategory param
         * @return {string}
         */
        linkCategory({ linkCategory }) {
            return linkCategory
        },

        /**
         * Get the app’s current reordering state.
         * 
         * @param {object} store.param The reordering param
         * @return {boolean}
         */
        reordering({ reordering }) {
            return reordering
        },
    },

    actions: {
        /**
         * Delete resource link.
         * 
         * @param {object} commit The store commit object
         * @param {string} id The link id to be deleted.
         */
        async deleteLink({ commit }, id) {
            await commit('SET_PROCESSING', true)
            axios 
                .delete(`/dashboard/links/${id}`)
                .then(() => {
                    // create hyperlink to avoid browser blocked reload.
                    let link = document.createElement('a')
                    link.setAttribute("href", `/dashboard/links`) // ?order=${store.getters.sortOrder}&direction=${store.getters.sortDirection}&category=${state.linkCategory}
                    link.classList.add("hidden")
                    link.click()
                })
                .catch(errors => console.log(errors))
                .then(() => location.reload())
        },
    },

    mutations: {
        /**
         * Set the app’s link category state.
         * 
         * @param {object} state The store.state object.
         * @param {bool} linkCategory The linkCategory state.
         * @return void
         */
        SET_LINK_CATEGORY(state, linkCategory) {
            state.linkCategory = linkCategory
        },
        
        /**
         * Set the app’s reordering state.
         * 
         * @param {object} state The store.state object.
         * @param {bool} reordering The reordering state.
         * @return void
         */
        SET_REORDERING(state, reordering) {
            state.reordering = reordering
        },
    },
}

export default LinkModule