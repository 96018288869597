<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Interface */
    props: [
        'visitorEmail',
        'visitorType',
        'visitorName',
        'visitorId',
        'isMobile',
        'isDesktop',
    ],

    /** Local State */
    computed: {
        /** Store Getters */
        ...mapGetters(['freeze'])
    },

    /** Lifecycle Events */
    mounted() {
        // Initialize the service.
        this.initialize()
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Initialize the service.
         * 
         * @return {void}
         */
        async initialize() {
            await this.setVisitorEmail(this.visitorEmail)
            await this.setVisitorName(this.visitorName)
            await this.setVisitorType(this.visitorType)
            await this.setVisitorId(this.visitorId)
            await this.setIsMobile(this.isMobile)
            await this.setIsDesktop(this.isDesktop)
        },

        /** Store Mutations */
        ...mapMutations({
            setVisitorEmail: 'SET_VISITOR_EMAIL',
            setVisitorName: 'SET_VISITOR_NAME',
            setVisitorType: 'SET_VISITOR_TYPE',
            setVisitorId: 'SET_VISITOR_ID',
            setIsMobile: 'SET_IS_MOBILE',
            setIsDesktop: 'SET_IS_DESKTOP',
        })
    },
}
</script>