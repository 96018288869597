<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
export default {
    /** Interface */
    props: [
        'favorites',
    ],

    /** Local State */
    computed: {
        /** Store Getters  */
        ...mapGetters(['freeze'])
    },

    /** Lifecycle Events */
    mounted() {
        // Initialize the service.
        this.initialize()
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Initialize the service.
         * 
         * @return {void}
         */
        async initialize() {
            await this.setFavorites(this.favorites ? JSON.parse(this.favorites): null)
        },

        /** Store Mutations */
        ...mapMutations({
            setFavorites: 'SET_FAVORITES',
        })
    },
}
</script>