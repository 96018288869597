<template>
<div 
    id="reordering-toggle"
    @click="active = ! active" 
    class="m-4 flex items-center justify-between cursor-pointer"
>
    <div        
        :class="{
            'bg-grey-600 justify-start': ! active,
            'bg-blue-100 justify-end': active,
        }"
        class="w-10 h-6 p-1 rounded-full flex"
    >
        <div 
            :class="{
                'bg-grey-800': ! active,
                'bg-blue-300': active,
            }"
            class="w-4 h-4 rounded-full"
        ></div>
    </div>
    <div class="ml-2 text-xs text-grey-300">
        Reorder Links: <span v-if="active">ON</span><span v-else>OFF</span>
    </div>
</div>
    
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {

    data() {
        return {
            active: false,
        }
    },
    computed: {
        /**
         * Store Getters
         */
        ...mapGetters(['reordering'])
    },
    /**
     * Events
     */
    watch: {
        active(state, oldState) {
            if (this.reordering != state) {
                this.setReordering(state)
            }
        },
    },
    /**
     * Lifecycle Events
     */
    mounted() {
        this.$nextTick(() => {
            if (this.reordering) {
                this.active = this.reordering
            }
        })
    },
    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Store Mutations
         */
        ...mapMutations({
            setReordering: 'SET_REORDERING'
        })
    }
}
</script>