<template>
    <transition name="panel" mode="out-in">
        <div 
            v-show="panel === 'environments'" 
            @click="setPanel(null)" 
            class="w-screen min-h-screen md:min-h-48 mt-24 leading-normal bg-neutral-10 border-t border-neutral-30 flex flex-col md:flex-row justify-start md:justify-between shadow-md absolute top-0 z-16"
        >
            <!-- Close button for mobile -->
            <div class="w-screen p-1 flex md:hidden justify-end">
                <div @click="setPanel(null)" class="w-10 h-10 flex justify-center items-center rounded-full cursor-pointer">
                    <icon type="close" on="click" mutator="SET_PANEL" :params="null" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>
            <!-- Panel columns -->
            <div class="w-screen md:w-1/4 min-h-48 px-6 py-4 text-lg md:text-xl text-grey-600 hidden md:flex"><!-- Ad Copy --></div>
            <div id="environment-navigation" class="w-screen md:w-3/4 min-h-48 px-6 py-4 flex flex-wrap">
                <div id="environments-header" class="w-full text-lg md:text-xl text-grey-600 tracking-normal">Explore Environments</div>
                <div class="flex-shrink h-auto md:h-48 flex flex-col flex-wrap leading-loose">
                    <a 
                        v-for="(navigation ,index) in environmentsNavigation" 
                        @click="setProcessing(true)"
                        :key="index"
                        v-text="navigation"
                        :href="`/environments/${navigation}/list`" 
                        :alt="navigation"
                        target="_self"
                        class="ml-2 mr-12 text-sm md:text-base text-grey-600 hover:opacity-50"
                    ></a>
                </div>
            </div>
            <!-- <div class="w-screen md:w-1/4 min-h-48 px-6 py-4 hidden md:flex">
                <div class="text-lg md:text-xl text-grey-600 tracking-normal">Recently Added</div>
            </div> -->
        </div>
    </transition>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'

export default {
    /** Local State */
    computed: {
        /**
         * The environment navigation.
         * 
         * @return {array}
         */
        environmentsNavigation() {
            // Navigation must be loaded.
            if (this.navigation) {
                // Assign parsed navigation to new variable.
                let navigation = JSON.parse(this.navigation)

                // Return navigation array
                return navigation ? navigation.environments : []
            }
            // Return empy array
            return []
        },

        /** Store Getters */
        ...mapGetters(['panel', 'navigation'])
    },

    /** Non-Reactive Properties */
    methods: {
        
        /** Store Mutations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING',
            setPanel:'SET_PANEL',
        })
    },
}
</script>