<template>
    <div
        @mouseover="active = true"
        @mouseout="active = false"
        @click="redirectToMrl()"
        v-tippy="{
            theme: 'translucent',
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>Go</span> to My Resource Library"
        class="w-full h-full"
        style="min-height: 160px"
    >
        <div class="h-full mb-2 p-6 bg-grey-900 hover:bg-teal-300 flex flex-col justify-end rounded cursor-pointer">
            <div :class="{'text-grey-100': active, 'text-teal-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">MRL</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">Go to My Resource Library</div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    /** Local State */
    data() {
        return {
            active: false,
        }
    },
    /** Non Reactive Properties */
    methods: {
        /** Redirect to MRL-CONNEXIONS PORTAL */
        redirectToMrl() {
            return window.open(`https://www.myresourcelibrary.com/project/38d0aec9-5017-49db-b043-ed41de37db75`)
        },
        /** Store Actions */
        ...mapActions(['redirectTo']),
    },
}
</script>
