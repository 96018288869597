<template>
    <div
        @mouseover="active = true"
        @mouseout="active = false"
        v-tippy="{
            theme: 'translucent',
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>Build</span> a budget"
        class="w-full h-1/2"
        style="min-height: 160px"
    >

        <div  @click="redirectToliftoff" class="w-full h-full mb-2 p-0 bg-grey-900 hover:bg-green-300 flex rounded cursor-pointer">
            <div class="p-6 flex flex-col justify-end">
                <div :class="{'text-grey-100': active, 'text-green-200': ! active}" class="text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl relative z-4 whitespace-nowrap">Budget <span class="hidden lg:inline-block">Builder</span> 2.0</div>
                <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">Build and Share a Budget</div>
            </div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions, mapGetters } from 'vuex'
import PrimaryButton from '../Elements/PrimaryButton.vue';
import SecondaryButton from '../Elements/SecondaryButton.vue';
export default {
    components: {
        SecondaryButton,
        PrimaryButton,
    },

    /** Local State */
    data() {
        return {
            active: false,
            dealerCode: null,
            legacyUrl: null,
            showRedirectToLiftoffModal: false,
        }
    },
    computed: {
        /** Return the user’s name */
        userName() {
           if (this.user) {
               return this.user.name
           }
        },
        /** Return the user employer’s company name */
        companyName() {
            if (this.user && this.user.employer) {
                return this.user.employer.company_name
            }
        },
        /** Return the username for liftoff */
        emailHandle() {
            if (this.user && this.user.email) {
                let split = this.user.email.split('@')
                return split.length ? split[0] : null
            }
        },
        /** Return the email domain */
        emailDomain() {
            if (this.user && this.user.email) {
                let split = this.user.email.split('@')
                return split.length ? split[1] : null
            }
        },
        /** Store Getters */
        ...mapGetters(['user']),
    },
    /** Events */
    watch: {
        /** Fetch dealer code when user changes */
        user() {
            this.fetchDealerCode()
            // this.fetchConfirmationPayload()
        }
    },
    /** Life Cycle Events */
    mounted() {
        this.$nextTick(() => {
            this.fetchDealerCode()
            // this.fetchConfirmationPayload()
        })
    },
    /** Non Reactive Properties */
    methods: {
        /** Return the liftoff uri with query */
        redirectToliftoff() {
            return this.routeTo('/dashboard/budget/select')

        },
        /** Get and set the dealer registration code for liftoff */
        fetchDealerCode() {
            if (this.user && this.user.employer) {
                axios
                    .get(`/api/dealers/${this.user.employer.id}/registration-code`)
                    .then(response => this.dealerCode = response.data)
                    .catch(errors => console.log(errors))
            }
        },
        /** Store Actions */
        ...mapActions(['redirectTo', 'routeTo']),

    },
}
</script>
