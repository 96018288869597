import store from '../../store'

const LogModule = {
    state: {},
    getters: {},
    actions: {
        /**
         * Process a log entry delete request.
         * 
         * @param {object} store.commit The commit module of vue store
         * @param {object} params The delete params
         * @return void
         */
        deleteLogEntry({commit}, params) {
            let form = document.querySelector(`form[id="${params.formId}"]`)

            if (form) {
                form.submit()
            } else {
                commit('SET_NOTIFICATION', {
                    type: 'danger',
                    title: 'Error',
                    message: 'Unable to delete the log entry',
                    duration: 8000,
                })
            }
        }
    },
    mutations: {}
} 

export default LogModule