<template>
    <div v-if="form === 'share'" class="w-screen h-screen p-24 flex items-center justify-center fixed top-0 z-12">
        <div v-show=" ! processing" class="w-screen h-screen bg-grey-900 opacity-75 absolute top-0 z-12"></div>
        <div class="w-full min-h-64 bg-grey-100 rounded shadow relative top-0 z-12">
            <div class="w-full p-6 text-2xl text-grey-600 flex justify-between">
                <div>Share Favorites</div>
                <div @click="close()" class="cursor-pointer">
                    <icon type="close" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>

            <!-- Compose Message -->
            <div class="w-full px-12 pt-8 flex flex-col">
                <div class="flex items-center">
                    <div class="w-full flex items-center">
                        <div v-if="visitorType === 'guest'" class="w-full flex">
                            <div class="flex-1 mr-2 flex flex-col">
                                <div class="flex">
                                    <label for="sender_name" class="text-xs text-grey-600">Your Name</label> 
                                    <div v-if=" ! input.sender_name" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                                    <div v-if="input.sender_name && ! validateName(input.sender_name)" class="ml-1 text-xs text-red-200"><small>Names must use letters only.</small></div>
                                </div>
                                <input 
                                    v-model="input.sender_name"
                                    id="sender_name" 
                                    type="text" 
                                    class="w-full py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                                    name="sender_name"
                                    placeholder="Your name" 
                                    required
                                >
                            </div>
                            <div class="flex-1 ml-2 flex flex-col">
                                <div class="flex">
                                    <label for="sender_email" class="text-xs text-grey-600">Your Email</label> 
                                    <div v-if="! input.sender_email" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                                    <div v-if="input.sender_email && ! validateEmail(input.sender_email)" class="ml-1 text-xs text-red-200"><small>This does not appear to be a valide email address.</small></div>
                                </div>
                                <input 
                                    v-model="input.sender_email"
                                    id="sender_email" 
                                    type="email" 
                                    class="w-full mr-2 py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                                    name="sender_email"
                                    placeholder="Your email" 
                                    required
                                >
                            </div>
                        </div>
                        <div v-if="visitorType === 'prospect'" class="w-full flex">
                            <input v-model="input.sender_name" type="hidden" name="sender_name" value="visitorName">
                            <input v-model="input.sender_email" type="hidden" name="sender_email" value="visitorEmail">
                        </div>
                        <div v-if="visitorType === 'user'" class="w-full flex">
                            <div class="flex flex-col">
                                <label for="sender_name" class="text-xs text-grey-600">Your Name</label> 
                                <input v-model="input.sender_name" type="text" name="sender_name" value="visitorName" class="mr-2 py-1 bg-transparent border-b border-neutral-50 disabled" disabled>
                            </div>
                            <div class="flex flex-col">
                                <label for="sender_email" class="text-xs text-grey-600">Your Email</label> 
                                <input v-model="input.sender_email" type="text" name="sender_email" value="visitorEmail" class="mr-2 py-1 bg-transparent border-b border-neutral-50 disabled" disabled>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full mt-4 flex flex-col">
                    <div class="flex">
                        <label for="to_emails" class="text-xs text-grey-600">Send To </label>
                        <div v-if=" ! input.to_emails || ! input.to_emails.length" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                        <div v-if="input.to_emails && input.to_emails.length && ! validEmails(input.to_emails)" class="ml-1 text-xs text-red-200"><small>One of the email addresses you input does not appear to be valid.</small></div>
                    </div>
                    <div class="flex items-center">
                        <div v-if="visitorType === 'guest' && parsedRecipients" class="flex-shrink relative">
                            <select v-model="to_select" name="to_select" id="to_select" class="block appearance-none w-auto my-2 pl-4 pr-8 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" >
                                <option :value="null">Select&hellip;</option>
                                <option v-for="(recipient, index) in parsedRecipients" :key="index" :value="recipient.email" v-text="recipient.name"></option>
                                <option value="other">Other&hellip;</option>
                            </select>
                            <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                            </div>
                        </div>

                        <div v-if="visitorType === 'prospect' && parsedRecipients" class="flex-shrink relative">
                            <select v-model="to_select" name="to_select" id="to_select" class="block appearance-none w-auto my-2 pl-4 pr-8 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" >
                                <option :value="parsedRecipients.email" v-text="parsedRecipients.name"></option>
                                <option value="other">Other&hellip;</option>
                            </select>
                            <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                            </div>
                        </div>

                        <div v-if="visitorType === 'user' && parsedRecipients" class="flex-shrink relative">
                            <select v-model="to_select" name="to_select" id="to_select" class="block appearance-none w-auto my-2 pl-4 pr-8 py-2 bg-grey-800 hover:opacity-50 text-grey-100 hover:text-grey-300 rounded" >
                                <option :value="null">Select&hellip;</option>
                                <option v-for="(recipient, index) in parsedRecipients" :key="index" :value="recipient.email" v-text="recipient.name"></option>
                                <option value="other">Other&hellip;</option>
                            </select>
                            <div class="pointer-events-none pr-2 text-grey-100 flex items-center absolute inset-y-0 right-0">
                                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
                            </div>
                        </div>

                        <input 
                            v-if=" ! parsedRecipients || otherRecipients"
                            v-model="toEmailsInput" 
                            id="other-emails" 
                            type="text" 
                            :class="{'ml-4': otherRecipients}"
                            class="w-full py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                            name="to_emails"
                            placeholder="Separate multiple emails with a comma." 
                        >
                    </div>
                </div>
            </div>

            <div v-if="visitorType === 'user'" class="w-full mt-4 px-12 flex flex-col">
                <!-- <label for="body" class="w-6 pr-2 pt-12 text-sm text-grey-200 flex justify-end ">Message</label> -->
                <label for="body" class="text-xs text-grey-600">Message <small class="text-indigo-200"><em>(Optional)</em></small></label>
                <div class="w-full mt-2 flex flex-col">
                    <tip-tap dusk="tip-tap-component" :message="input.message" @update-body="setMessage"></tip-tap>
                    <!-- <input type="hidden" name="body" v-model="input.message" /> -->
                </div>
            </div>

            <div v-else class="w-full mt-4 px-12 flex flex-col">
                <!-- <label for="body" class="w-6 pr-2 pt-12 text-sm text-grey-200 flex justify-end ">Message</label> -->
                <label for="body" class="text-xs text-grey-600">Message <small class="text-indigo-200"><em>(Optional)</em></small></label>
                <textarea 
                    v-model="input.message" 
                    rows="3" 
                    name="body" 
                    class="py-1 text-grey-600 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200"
                ></textarea>
            </div>

            <div class="w-full px-8 py-6 flex justify-end items-center">
                <div @click="close()" class="py-2 px-4 text-grey-600 hover:opacity-50 rounded cursor-pointer">Cancel</div>
                <div 
                    @click="readyToShare ? submitShareForm() : null" 
                    :class="{
                        'opacity-50 cursor-default': ! readyToShare, 
                        'hover:opacity-50 cursor-pointer': readyToShare, 
                    }"
                    class="py-2 px-4 text-blue-200 " 
                >Share</div>
            </div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */ 
import { mapActions, mapGetters, mapMutations } from 'vuex'
/** Composition */
import ValidationMixins from '../../mixins/validation-mixins'
export default {
    /** Composition */
    mixins: [ValidationMixins],

    /** Local State */
    data() {
        return {
            to_select: null,
            toEmailsInput: null,
            input: {
                prospect_id: null,
                store_number: null,
                site_key: null,
                sender_name: null,
                sender_email: null,
                sender_type: null,
                to_emails: null,
                message: this.shareMessage(),
                shareable_ids: null,
                shareable_type: null,
            },
        }
    },
    computed: {
        /**
         * Parse the json value of recipients. 
         * 
         * @return {void}
         */
        parsedRecipients() {
            if (this.shareRecipients) {
                return JSON.parse(this.shareRecipients)
            }
            return null
        },

        /**
         * Show the text input when other option is selected.
         * 
         * @return {void}
         */
        otherRecipients() {
            return this.to_select == 'other'
        },

        /**
         * Show that the form is ready to submit.
         * 
         * @return {boolean} True of false
         */
        readyToShare() {

            // console.log({
            //     validEmail: this.validEmails(this.input.to_emails),
            //     validSender: this.input.sender_email,
            //     validName: this.input.sender_name,
            //     shareable_ids: this.input.shareable_ids,
            //     shareable_ids_LENGTH: this.input.shareable_ids.length,
            // })

            if (
                this.validEmails(this.input.to_emails) 
                && this.validateEmail(this.input.sender_email) 
                && this.validateName(this.input.sender_name)
                && this.input.shareable_ids 
                && this.input.shareable_ids.length
            ) {
                return true
            }
            return false
        },

        /** Store Getters */
        ...mapGetters([
            'shareRecipients',
            'shareableType',
            'visitorEmail',
            'visitorName',
            'visitorType',
            'shareables',
            'processing',
            'visitorId',
            'selected',
            'dealer', 
            'form',
        ])
    },

    /** Events */
    watch: {

        /**
         * Set the to_emails input array,
         * 
         * @param {array|string} emails
         * @return {void}
         */
        toEmailsInput(emails) {
            let compacted = _.replace(emails, ' ', '')
            this.input.to_emails = compacted.split(',')
        },

        /**
         * Make selected "to_emails" value when an email is selected.
         * 
         * @param {string} selected
         * @return {void}
         */
        to_select(selected) {
            if (! selected) {
                return 
            }

            if (selected !== 'other') {
                this.input.to_emails = [selected]
            }

            if (selected !== 'other' && this.visitorType === 'user') {
                this.setProspectId(selected)
            }
        },

        /**
         * Sync shareable ids with input.
         * 
         * @return {void}
         */
        selected(selected) {
            this.input.shareable_ids = selected
        },

        /**
         * Initialize the form.
         * 
         * @return {void}
         */
        form(form, oldForm) {
            if (form === 'share') {
                this.initializeShareForm()
            }

            if (form !== 'share' && oldForm === 'share') {
                this.close()
            }
        }
    },

    /** Non-Reactive Properties */
    methods: {
        initializeShareForm() {
            this.$nextTick(() => {
                this.input.prospect_id = this.visitorType == 'prospect' ? this.visitorId : null,
                this.input.store_number = this.dealer ? this.dealer.store_number :'C999'
                this.input.site_key = this.dealer ? this.dealer.site_key :'default'
                this.input.sender_name = this.visitorName
                this.input.sender_email = this.visitorEmail 
                this.input.sender_type = this.visitorType

                this.input.shareable_type = this.shareableType
                this.input.shareable_ids = this.selected
                
                if (this.visitorType === 'prospect' && this.parsedRecipients && this.parsedRecipients.email) {
                    this.to_select = this.parsedRecipients.email
                }

                if ( ! this.input.message || this.input.message == '') {
                    this.setMessage(this.shareMessage())
                }
            })
        },

        setMessage(message) {
            console.log({message})
            this.input.message = message
        },

        /**
         * Return the dealer name for the share message. 
         * 
         * @return {string}
         */
        shareMessage() {
            let storeName = this.dealer && this.dealer.store_name
                ? this.dealer.store_name
                : "Connexions"
            
            return `Check out some of my favorite office products from ${storeName}.`
        },

        /**
         * Set the prospect_id when a prospect recipient is selected by user.
         * 
         * @param {string} email The prospect’s email
         * @return {void} 
         */
        setProspectId(email) {
            if (this.parsedRecipients) {
                let recipient = this.parsedRecipients.filter(recipient => recipient.email === email)                
                this.input.prospect_id = _.head(recipient).id
            }
        },

        /**
         * Validate array of emails input by visitor.
         */
        validEmails(emails) {
            if (emails && emails.length) {
                // if (emails.includes(',')) {
                let invalid = _.filter(emails, email => {
                    return ! this.validateEmail(_.trim(email))
                })
                // console.log(invalid)
                return invalid && invalid.length ? false : true
            }
            return this.validateEmail(emails)
            // return false
        },

        /**
         * Process the share form.
         * 
         * @return {void}
         */
        async submitShareForm() {
            // const el = document.querySelector('input[name="body"]')

            // if (el) {
            //     this.input.message = el.value
            // }

            await this.setProcessing(true)
            await this.storeShare(this.input)
        },

        /**
         * Set the shareable type input.
         * 
         * @return {void}
         */
        // setShareableType() {
        //     if (this.shareables && this.shareables.length) {
        //         let shareable = _.head(this.shareables)
        //         let shareableType = shareable && shareable.group && shareable.group.name
        //                 ? 'App\\Services\\Product\\Models\\Product'
        //                 : 'App\\Services\\Product\\Models\\Environment'
        //         if (shareable && shareable.favoriteable) {
        //             shareableType = 'App\\Services\\Dashboard\\Models\\Favorite'
        //         }
        //         return shareableType
        //     }
        // },

        /**
         * Reset the form.
         * 
         * @return {void}
         */
        clearInput() {
            this.to_select = null
            this.input.to_emails = null
            // this.input.message = this.shareMessage()
            this.input.shareable_ids = null
            this.input.shareable_type = null
        },

        /**
         * Close the form.
         * 
         * @return {void}
         */
        async close() {
            await this.clearInput()
            await this.setForm(null)
        },

        /** Store Actions */
        ...mapActions(['storeShare']),

        /** Store Mutrations */
        ...mapMutations({
            setProcessing: 'SET_PROCESSING',
            setForm: 'SET_FORM',
        })
    }

}
</script>