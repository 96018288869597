<template>
     <div :class="classes">{{rating}}</div>
</template>
<script>
export default {
    /** Interface */
    props: {
        rating: {
            type: String,
            required: false,
            default: 'NR',
        }, 
        classes: {
            type: String,
            required: false,
            default: "w-8 h-8 mr-1 text-sm text-grey-600 font-sm tracking-normal rounded-full bg-transparent flex items-center justify-center"
        },
    }
}
</script>