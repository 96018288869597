import Vue from 'vue'

require('./bootstrap')
require('./services')
require('./filters')
require('./env')
require('./Public')
require('./Dashboard')
require('./Specification')
require('./Auth')

import "tailwindcss/tailwind.css"

import store from './store'

const methods = {}

// Mount the Vue Model
const app = new Vue({
    store,
    methods,
}).$mount('#app');
