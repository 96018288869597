var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'translucent',  
            arrow: true,
            touch: 'true',
            placement: 'top',
            trigger: 'click',
            maxWidth: 480,
        }),expression:"{\n            theme: 'translucent',  \n            arrow: true,\n            touch: 'true',\n            placement: 'top',\n            trigger: 'click',\n            maxWidth: 480,\n        }"}],staticClass:"w-24 h-24 m-2 p-4 rounded shadow hover:opacity-50 hover:shadow-none bg-cover bg-center bg-no-repeat cursor-pointer",style:(`background-image: url('${_vm.card}'); background-color: white`),attrs:{"content":_vm.content}})])
}
var staticRenderFns = []

export { render, staticRenderFns }