const FavoriteModule = {
    state: {
        /**
         * The current favorites state.
         * 
         * @var {object}
         */
        favorites: null,
    },
    getters: {
        /**
         * Get the current favorites state
         * 
         * @param {object|null} favorites The store favorites state.
         * @return {object|null}
         */
        favorites({ favorites }) {
            return favorites
        },
    },
    actions: {
        /**
         * Share favorites.
         * 
         * @param {object} commit The store commit object.
         * @param {object} params The share params.
         */
        shareFavorites({ commit }, params) {
            console.log({params})
        },

        /**
         * Persist a visitor favorite to the repository.
         * 
         * @param {object} commit The store commit object.
         * @param {object} params The end point required params [favoriteable_id, favoriteable_type].
         */
        async addFavorite({ commit, dispatch }, params) {
            await commit('SET_PROCESSING', true)
            await axios 
                .post(`/api/favorites/add`, params)
                .then(response => commit('SET_FAVORITES', response.data))
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Favorite added.',
                    duration: 5000,
                }))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn(errors))
        },

        /**
         * Remove a visitor favorite from the repository.
         * 
         * @param {object} commit The store commit object.
         * @param {object} params The end point required params [favoriteable_id, favoriteable_type].
         */
        async removeFavorite({ commit, dispatch }, params) {
            await commit('SET_PROCESSING', true)
            await axios 
                .post(`/api/favorites/remove`, params)
                .then(response => commit('SET_FAVORITES', response.data))
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Favorite removed.',
                    duration: 5000,
                }))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => console.warn(errors))
        },

        /**
         * Delete array of favorites.
         * 
         * @param {object} commit The store’s commit object
         * @param {object} params The ids to delete.
         */
        batchRemoveFavorites({ commit }, params) {
            commit('SET_PROCESSING', true)
            axios
                .post('/api/favorites/batch', params)
                .then(response => {
                    commit('SET_FAVORITES', response.data)
                    if (response.data.length < 1) {
                        let link = document.createElement('a')
                        link.setAttribute("href", `/`)
                        link.classList.add("hidden")
                        link.click()
                    }
                })
                 .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Favorites removed.',
                    duration: 5000,
                }))
                .then(() => commit('SET_PROCESSING', false))
                .catch(errors => commit('SET_NOTIFICATION', {
                    type: 'danger',
                    title: 'Error',
                    message: _.head(errors.ids) /* 'Unable to complete your request.' */,
                    duration: 5000,
                }))
                .then(() => commit('SET_PROCESSING', false))
        },
    },
    mutations: {
        /**
         * Mutate the favorites state. 
         * 
         * @param {object} state The state object from the store.
         * @param {object|null} favorites The mutation.
         * @return {void}
         */
        SET_FAVORITES(state, favorites) {
            state.favorites = favorites
        }
    }
}

export default FavoriteModule