<template>
    <div v-if="searchResults && links && (links.next || links.prev)" class="w-full text-title relative bottom-0">
        <ul class="panel-element w-full h-auto list-reset no-underline flex items-center justify-center">
            <li 
                v-if="links.prev" 
                @click="search(index, 1)" 
                class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 hover:opacity-50 bg-tansparent hover:bg-darken-10 flex items-center justify-center cursor-pointer"
            >
                <icon class="panel-element fill-current" type="first" view-box="0 0 24 24" width="24" height="24" />
            </li>
            <li 
                v-if="links.prev" 
                @click="search(index, previousPage)" 
                class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 hover:opacity-50 hover:bg-darken-10 bg-tansparent flex items-center justify-center cursor-pointer"
            >
                <icon class="panel-element fill-current" type="previous" view-box="0 0 24 24" width="24" height="24" />
            </li>
            <li v-if="totalPages > perPage" class="flex">
                <div v-for="(page, i) in currentBatch" :key="i" >
                    <div 
                        @click="search(index, page)" 
                        :class="{
                            'text-grey-600 hover:opacity-50 hover:bg-darken-10 bg-tansparent cursor-pointer': page != currentPage,
                            'text-grey-600 bg-darken-10 cursor-default': page == currentPage, 
                        }"
                        class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 flex items-center justify-center"
                    >{{page}}</div>
                </div>
                <div v-if="showTotalPages" class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 flex items-center justify-center">&hellip;</div>
                <div 
                    v-if="showTotalPages"
                    @click="search(index, totalPages)" 
                    class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 hover:opacity-50 hover:bg-darken-10 bg-tansparent flex items-center justify-center cursor-pointer"
                >{{totalPages}}</div>
            </li>
            <li v-else class="panel-element flex justify-center items-center"> 
                <div v-for="page in totalPages" :key="page" >
                    <div 
                        @click="search(index, page)" 
                        :class="{
                            'text-grey-600 hover:opacity-50 hover:bg-darken-10 bg-tansparent cursor-pointer': page != currentPage,
                            'text-grey-600 bg-darken-10 cursor-default': page == currentPage, 
                        }"
                        class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 flex items-center justify-center"
                    >{{page}}</div>
                </div>
            </li>
            <li 
                v-if="links.next"
                @click="search(index, nextPage)" 
                class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 hover:opacity-50 bg-tansparent hover:bg-darken-10 flex items-center justify-center cursor-pointer" 
            >
                <icon class="panel-element fill-current" type="next" view-box="0 0 24 24" width="24" height="24" />
            </li>
            <li 
                v-if="currentPage < totalPages" 
                @click="search(index, totalPages)" 
                class="panel-element xs:w-8 md:w-10 xs:h-8 md:h-10 xs:px-2 md:px-3 xs:py-2 md:py-2 text-grey-600 hover:opacity-50 bg-tansparent hover:bg-darken-10 flex items-center justify-center cursor-pointer" 
            >
                <icon class="panel-element fill-current" type="last" view-box="0 0 24 24" width="24" height="24" />
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        index: {
            type: String,
            required: true,
        },
        content: {
            type: Array,
            required: false,
        },
        links: {
            type: Object,
            required: false,
        }, 
        meta: {
            type: Object,
            required: false,
        },
    },

    /**
     * Local State
     */
    computed: {
        /**
         * The total number of search results pages. 
         * 
         * @return {number}
         */
        totalPages() {
            if (this.meta && this.meta.last_page) {
                return this.meta.last_page
            }
            return 1
        },

        /**
         * The current search results page. 
         * 
         * @return {number}
         */
        currentPage() {
            if (this.meta && this.meta.current_page) {
                return this.meta.current_page
            }
            return 1
        },
        /**
         * The previous search results page. 
         * 
         * @return {number}
         */
        previousPage() {
            if (this.links && this.links.prev) {
                return Number(_.last(_.split(this.links.prev, 'page=')))
            }
            return 1
        },
        /**
         * The previous search results page. 
         * 
         * @return {number}
         */
        nextPage() {
            if (this.links && this.links.next) {
                return Number(_.last(_.split(this.links.next, 'page=')))
            }
            return 1
        },

        /**
         * The number of hits per page. 
         * 
         * @return {number}
         */
        perPage() {
            if (this.meta && this.meta.per_page) {
                return this.meta.per_page
            }
            return 5
        },

        /**
         * Return the search result pages in batches of 5. 
         * 
         * @return {array}
         */
        pageBatches() {
            // let perPage = this.meta && this.meta.per_page ? this.meta.per_page : 5
            // for (let i = 0; i < this.totalPages; i++) {
            //     //array.slice(0, n)
            // }
            
            if (this.content && this.content.length) {
                let batches = []
                let batch = []
                let increment = 1
                let perPage = this.meta && this.meta.per_page ? this.meta.per_page : 5
                // let pages = Number(this.totalPages/perPage)
                let remainder = Number(this.totalPages%perPage)
                let decrement = Number(this.totalPages - remainder)
                
                for (decrement; decrement > 0; decrement--) {
                    console.log({batch})
                    batch.push(increment)

                    if (increment % perPage === 0) {
                        batches.push(batch)
                        batch = []
                    } 
                    
                    increment++
                }

                batch = []

                for (let i = 0; i < remainder; i++) {
                    batch.push(increment)
                    increment++
                }
                batches.push(batch)
            
                return batches
            }
        },

        /**
         * The current page batch. 
         * 
         * @return {array}
         */
        currentBatch() {
            return _.head(_.filter(this.pageBatches, batch => {
                return batch.includes(this.currentPage)
            }))
        },

        /**
         * Show the total page count in pagination. 
         * 
         * @return {bool}
         */
        showTotalPages() {
            return (
                this.pageBatches && this.pageBatches.length > 1
                && Number(this.totalPages - this.perPage) > 0
                && ! this.currentBatch.includes(this.totalPages)
            )
        },
        
        /**
         * State Getters
         */
        ...mapGetters(['searchResults'])
    },

    /**
     * Non-Reactive Properties
     */
    methods: {

        /**
         * Emit search request 
         * 
         * @param {Number} page 
         * @return void
         */
        search(index, page) {
            this.$emit('search', {index, page})
        }
    },
}
</script>