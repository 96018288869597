<template>
    <div id="indicator-bar" class="h-auto relative top-0">
        <select-element 
            dusk="resource-options-component"
            :options="selectOptions" 
            :fixed="false"
            :show="select === 'resource-options'"
            trigger-id="resource-options"
        ></select-element>
        <div 
            :class="{'mt-12 md:mt-8 fixed top-0 ': scrolling}" 
            class="w-full bg-white border-t border-b border-neutral-30 flex justify-between opacity-75"
        >
            <div class="flex-1 flex items-center">
                <div 
                    dusk="back-icon" 
                    v-if="isDetail" 
                    @click="back" 
                    v-tippy="{ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: 'false',
                        placement: 'top',
                    }"
                    content="<span class='text-blue-100'>Go</span> back"
                    class="ml-4 text-grey-600 hover:opacity-50 cursor-pointer"
                >
                    <icon type="back" :params="true" view-box="0 0 24 24" width="24" height="24" />
                </div>
                <div 
                    v-if="title"
                    v-html="title"
                    class="px-4 py-2 text-2xl text-grey-600 tracking-normal"
                ></div>
                <div 
                    v-if="isDetail" 
                    @click.stop="setSelect('resource-options')" 
                    v-tippy="{ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: 'false',
                        placement: 'top',
                    }"
                    content="More Information"
                    id="resource-options" 
                    class="text-grey-600 flex items-center justify-center hover:opacity-50 cursor-pointer"
                >
                    <icon dusk="resource-options-trigger" type="down" on="click" mutator="SET_SELECT" params="resource-options" view-box="0 0 24 24" width="24" height="24" />
                    <div class="text-xs font-medium leading-tight text-grey-600">Information</div>
                </div>
            </div>
            
            <sort-widget dusk="sort-widget-component"></sort-widget>

            <div class="px-8 flex-1 flex items-center justify-end">
                <per-page-widget dusk="per-page-widget-component"></per-page-widget>
                <a 
                    v-if="inCurrentCatalog(resource)"
                    href="/catalog"
                    target="_blank"
                    class="w-10 h-10 text-grey-700 flex items-center justify-center hover:opacity-50 cursor-pointer"
                >
                    <icon type="catalog" view-box="0 0 24 24" width="20" height="20" class="fill-current"/>
                </a>
                <favorite-widget 
                    v-if="isDetail" 
                    dusk="favorite-widget-component"
                    :id="favoriteId" 
                    inactive="text-grey-700 bg-transparent"
                    active="text-grey-100 bg-red-200"
                ></favorite-widget>
                <div 
                    v-if="isDetail" 
                    @click.stop="setForm('share')"
                    v-tippy="{ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: 'false',
                        placement: 'top',
                    }"
                    content="<span class='text-blue-100'>Share</span> this item"
                    id="resource-options" 
                    class="w-10 h-10 text-grey-700 flex items-center justify-center hover:opacity-50 cursor-pointer"
                >
                    <icon dusk="share-trigger" type="share" view-box="0 0 24 24" width="24" height="24" class="fill-current"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */ 
import { mapGetters, mapMutations } from 'vuex'
/** Composition */
import CatalogMixins from '../../../mixins/catalog-mixins'
/** Import Dependencies */
import SortWidget from '../../Widgets/SortWidget'

export default {
    /** Composition */
    mixins: [CatalogMixins],
    /** Template Dependencies */
    components: {SortWidget},
    /** Interface */
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        }
    },

     /** Local State */
    data() {
        return {
            selectOptions: {
                overview: {
                    label:'Overview',
                    type:'dispatch',
                    action:'scrollTo',
                    params:'overview-section',
                },
                features: {
                    label: 'Features',
                    type: 'dispatch',
                    action: 'scrollTo',
                    params: 'features-section',
                },
            },
            showShareForm: false,
            sort: 'vendor',
            order: 'asc',
        }
    },
    computed: {
        /**
         * The current page is a product detail page.
         * 
         * @return {boolean}
         */
        isDetail() {
            return window.location.pathname.includes('/show')
        },

        /**
         * Return the favorite id from blade view $selected param.
         * 
         * @return {number|null}
         */
        favoriteId() {
            if (this.selected) {
                return this.selected.length
                    ? _.head(this.selected)
                    : this.selected
            }
            return null
        },

        /** Store Getters */
        ...mapGetters([
            'shareableType',
            'scrolling', 
            'selected', 
            'resource',
            'select',
            'panel', 
        ])
    },

    /** Events */
    watch: {
        sort(sort) {
            if (window.location.pathname && window.location.pathname.includes('list')) {
                const url = new URL(window.location.origin + window.location.pathname + `?sort=${sort}&order=${this.order}`)
                let el = document.createElement('a')
                el.classList.add('hidden', 'w-0', 'h-0', 'absolute', 'bottom-0', 'right-0')
                el.setAttribute('href', url.href)
                el.click()
            }
        },

        order(order) {
            if (window.location.pathname && window.location.pathname.includes('list')) {
                window.location = window.location.pathname + "?sort=" + this.sort + "&order=" + order
            }
        },
    },

    /** Non Reactive Properties */
    methods: {
        /**
         * Navigate back one page in browser history. 
         * 
         * @return {void}
         */
        back() {
            return window.history.go(-2)
        },

        /**  Store Mutations */
         ...mapMutations({
             setSelect: 'SET_SELECT',
             setForm: 'SET_FORM',
        })
    }
}
</script>