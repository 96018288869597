<template>
    <transition name="panel" mode="out-in">
        <div 
            v-show="panel === 'brands'" 
            @click="setPanel(null)" 
            id="brand-panel"
            class="w-screen min-h-48 mt-0 md:mt-24 bg-neutral-10 border-t border-neutral-30 flex flex-col md:flex-row justify-between shadow-md absolute top-0 z-16"
        >
            <div class="w-screen p-1 flex md:hidden justify-end">
                <div @click="setPanel(null)" class="w-10 h-10 flex justify-center items-center rounded-full cursor-pointer">
                    <icon type="close" on="click" mutator="SET_PANEL" :params="null" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>
            <div class="w-screen md:w-1/4 min-h-48 px-6 py-4 text-lg md:text-xl text-grey-600 hidden md:flex"></div>
            <div id="brands-navigation" class="w-screen md:w-3/4 min-h-48 px-6 py-4 flex flex-wrap">
                <div id="brands-header" class="w-full text-lg md:text-xl text-grey-600 tracking-normal">Explore Brands</div>
                <div class="flex-shrink h-auto md:h-60 flex flex-col flex-wrap leading-loose">
                    <div
                        v-for="(vendor ,index) in sortedVendors" 
                        :key="index"
                    >
                        <a 
                            v-if="vendor.type == 'Manufacturer' || vendor.type == 'manufacturer' || vendor.company_name == 'MRL'"
                            v-text="vendor.company_name"
                            :href="`/vendors/${vendor.id}`" 
                            :alt="vendor.company_name"
                            target="_self"
                            class="ml-2 mr-12 text-sm md:text-base text-grey-600 hover:opacity-50"
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'

export default {
    /** Local State */
    computed: {
        /**
         * Return sorted vendors collection.
         * 
         * @return {array}
         */
        sortedVendors() {
            return _.sortBy(this.vendors, 'company_name')
        },

        /** Store Getters */
        ...mapGetters(['panel', 'vendors'])
    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        /**  Store Mutations */
        ...mapMutations({setPanel:'SET_PANEL'})
    },
}
</script>