<template>
    <a 
        @mouseover="active = true" 
        @mouseout="active = false" 
        href="/dashboard/links?category=sales and training"
        target="_self"
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>View</span> sales and training resources"
        class="w-full h-full"
        style="min-height: 160px"
    >
        <div class="h-full mb-2 p-6 bg-grey-900 hover:bg-teal-300 flex flex-col justify-end rounded cursor-pointer">
            <div :class="{'text-grey-100': active, 'text-teal-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">Sales</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">Sales and Training Resources</div>
        </div>
    </a>
</template>
<script>
// Store Components
import { mapActions } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        tickets: {
            type: String,
            required: false,
            default: null,
        },
    },

    /**
     * Local State
     */
    data() {
        return {
            active: false,
        }
    },
    
    /**
     * Non-Reactive Properties
     */
    methods: {
        /**
         * Determine if the user has any tickets.
         * 
         * @return {boolean}
         */
        hasTickets() {
            return JSON.parse(this.tickets) ? true : false
        },
        /**
         * Handle card routing.
         * 
         * @return {void}
         */
        supportRoute() {
            if (this.hasTickets()) {
                this.routeTo('/dashboard/tickets')
            } else {
                this.routeTo('/dashboard/tickets/create')
            }
        },
        /**
         * Store Actions
         */
        ...mapActions(['routeTo'])
    },
}
</script>