var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4 flex items-center justify-between cursor-pointer",attrs:{"id":"reordering-toggle"},on:{"click":function($event){_vm.active = ! _vm.active}}},[_c('div',{staticClass:"w-10 h-6 p-1 rounded-full flex",class:{
            'bg-grey-600 justify-start': ! _vm.active,
            'bg-blue-100 justify-end': _vm.active,
        }},[_c('div',{staticClass:"w-4 h-4 rounded-full",class:{
                'bg-grey-800': ! _vm.active,
                'bg-blue-300': _vm.active,
            }})]),_vm._v(" "),_c('div',{staticClass:"ml-2 text-xs text-grey-300"},[_vm._v("\n        Reorder Links: "),(_vm.active)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OFF")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }