<template>
    <div v-if="isListing" class="h-100 flex items-center">
        <div class="mx-3 text-xs text-grey-600 font-medium leading-loose">Sort By</div>
        <div class="relative">
            <select name="sort" v-model="localSort" class="block appearance-none w-auto my-2 pr-10 pl-4 py-2 bg-grey-300 text-grey-600 hover:opacity-50 rounded">
                <!-- <option value="list_price" class="text-grey-600">Price</option> -->
                <option value="vendor" class="text-grey-600">Manufacturer</option>
                <!-- <option value="group" class="text-grey-600">Series</option> -->
                <!-- <option value="list_price" class="text-grey-600">Price</option> -->
                <option value="rating" class="text-grey-600">Rating</option>
            </select>
            <div class="mr-4 pointer-events-none text-grey-600 flex items-center absolute inset-y-0 right-0">
                <icon type="down" view-box="0 0 24 24" width="24" height="24"/>
            </div>
        </div>
        <!-- <div v-if="orderText" @click="toggleOrder" class="flex items-center cursor-pointer"> 
            <div class="mx-3 text-xs text-grey-600 font-medium leading-loose">{{orderText}}</div>
            <div 
                class="transform" 
                :class="{
                    '-rotate-180': localOrder === 'asc', 
                    'rotate-0': localOrder === 'desc'
                }"
                :style="localOrder === 'asc' ? 'transform: scaleY(-1)' : null"
            >
                <icon type="order" view-box="0 0 24 24" width="18" height="18" class="fill-current text-grey-600" />
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    /** Local State */
    data() {
        return {
            localSort: null,
            localOrder: null,
        }
    },
    computed: {
        /**
         * The current page is a product listing page.
         * 
         * @return {boolean}
         */
        isListing() {
            return window.location.pathname.includes('/list') /* && window.location.pathname.includes('/products') */
        },

        /**
         * Return the order text.
         * 
         * @return {string}
         */
        orderText() {
            // if (this.localSort === 'list_price' /* || this.localSort === 'rating' */) {
            //     return this.localOrder == 'desc'
            //         ? 'Highest to Lowest'
            //         : 'Lowest to Highest'
            // }
            return null;
            // if (this.localSort !== 'list_price' && this.localSort !== 'rating') {
            //     return this.localOrder == 'desc'
            //         ? 'Z to A'
            //         : 'A to Z'
            // }
        },
    },

    /** Life Cycle Events */
    mounted() {
        let params = (new URL(document.location)).searchParams
        this.localSort = params.get('sort') ?? 'rating'
        this.localOrder = params.get('order') ?? 'desc'
    },

    /** Events */
    watch: {
        /**
         * Update the sort state when local value changes.
         * 
         * @param {string} sort The local sort value.
         * @return {void}
         */
        localSort: {
            deep: true,
            handler() {
                let params = (new URL(document.location)).searchParams

                if (this.isListing && this.localSort !== params.get('sort')) {
                    let sort = this.localSort ?? 'rating'
                    let order = params.get('order') ?? 'desc'
                    let perPage = params.get('perPage') ?? 12
                    let page = params.get('page') ?? 1
                    window.location = `${window.origin}${window.location.pathname}?order=${order}&sort=${sort}&perPage=${perPage}&page=${page}`
                }
            }
        },
        /**
         * Update the order state when local value changes.
         * 
         * @return {void}
         */
        localOrder: {
            deep: true,
            handler() {
                let params = (new URL(document.location)).searchParams

                if (this.isListing && window.location.pathname.includes('/list') && this.localOrder !== params.get('order')) {
                    let sort = params.get('sort') ?? 'rating'
                    let order = this.localOrder ?? 'desc'
                    let perPage = params.get('perPage') ?? 12
                    let page = params.get('page') ?? 1
                    window.location = `${window.origin}${window.location.pathname}?order=${order}&sort=${sort}&perPage=${perPage}&page=${page}`
                }
            }
        },
    },

    /** Non Reactive Properties */
    methods: {
        /**
         * Toggle the sort direction.
         * 
         * @return {void}
         */
        toggleOrder() {
            return this.localOrder === 'desc'
                ? this.localOrder = 'asc'
                : this.localOrder = 'desc'
        },
    }
}
</script>