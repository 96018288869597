<template>
    <div class="w-full carousel">
        <div 
            v-for="(environment, index) in environments" 
            :key="index" 
            class="card bg-neutral-20"
        >
            <div class="max-w-sm m-2 bg-white rounded overflow-hidden shadow flex flex-col justify-between">
                <a :href="`/environments/${environment.id}/show`" :title="environment.model_number">
                    <div 
                        :id="`environment-card-${environment.id}`" 
                        class="w-64 h-48 flex items-center justify-center bg-grey-300 bg-cover bg-center bg-no-repeat overflow-hidden" 
                        :style="`background-image: url('${environment.img}')`"
                    ></div>
                </a>
                <div class="w-64 h-32 px-6 py-4 bg-white flex flex-col justify-between">
                    <div class="flex flex-col">
                        <div class="text-xs text-grey-600 font-medium leading-tight">{{environment.vendor}}</div>
                        <div class="text-base text-grey-600 font-medium leading-tight">{{environment.group}}</div>
                        <p class="mt-3 text-grey-600 text-xs leading-tight">{{environment.shortDescription}}</p>
                    </div>                        
                </div>
            </div>
        </div>      
    </div>    
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    import Flickity from 'flickity'

    import flickityOptions from '../../../vendor/flickety/flickety-options'

    export default {
        /** Interface */
        props: {
            environments: {
                type: Array,
                required: false,
                default: null
            }
        },
        /** Local State */
        data() {
            return {
                flickityOptions,
                flickity: null,
            }
        },
        computed: {
            /** Store Getters */
            ...mapGetters(['isMobile'])
        },

        /** Events */
        watch: {
            /**
             * Render carousel when environments load.
             */
            environments() {
                this.initializeFlickety()
            },
        },

        /** Lifecycle Events */
        mounted() {
            this.initializeFlickety()
        },

        /** Non-Reactive Properties */
        methods: {

            /**
             * Initialize Flickety Object
             * 
             * @return void
             */
            initializeFlickety() {
                this.$nextTick(() => {
                    // console.log('initializeFlickety')
                    // Create Flickity Object and assign to data
                    this.flickity = new Flickity('.carousel', this.flickityOptions)
                })
            },
        }
    }
// 
</script>
