<template functional>
<div v-show="duplicateCollection">
    <div 
        v-for="product in duplicateCollection" 
        :key="product.id" 
        class="max-w-sm m-2 bg-white rounded overflow-hidden shadow flex flex-col justify-between relative"
    >
        <a :href="`/products/${product.id}/show`" :title="modelNumber(product)">
            <div 
                :id="`products-card-${product.id}`" 
                class="w-64 h-48 flex items-center justify-center bg-white bg-center bg-no-repeat overflow-hidden bg-contain" 
                :style="`background-image: url('${product.thumb}');`"
            ></div>
        </a>
        <a 
            v-if="inCurrentCatalog(product)"
            href="/catalog"
            target="_blank"
            class="w-8 h-8 mt-4 mr-4 bg-grey-100 text-grey-600 hover:opacity-50 cursor-pointer absolute right-0 top-0 flex items-center justify-center rounded-full"
        >
            <tip-widget :content="`<span class='text-blue-100'>See</span> this product in Idea Book`">
                <icon type="catalog" view-box="0 0 24 24" height="18" width="18" class="fill-current" />
            </tip-widget>                
        </a>

        <div class="w-64 h-full px-6 py-4 bg-white flex flex-col justify-between">
            <!-- <div 
                v-if="duplicateCount(product)" 
                v-html="duplicateCount(product)"
                @click="showDuplicates(product)"
                :id="`product-${product.id}`"
                class="m-4 px-3 py-1 text-xs text-grey-100 bg-grey-700 opacity-75 absolute top-0 left-0 z-12 rounded-full flex items-center justify-center cursor-pointer"
            ></div> -->
            
            <div class="flex flex-col">
                <div @click="routeToVendor(vendorName(product))" class="text-xs text-grey-600 font-medium leading-tight cursor-pointer hover:opacity-50">{{vendorName(product)}}</div>
                <div class="text-base text-grey-700 font-medium leading-tight">{{groupName(product)}}</div>
                <p class="mb-3 text-grey-600 text-xs leading-tight">{{product.shortDescription}}</p>
                <!-- <div class="mb-2 text-grey-600 text-xs">List Price: <span class="line-through" style="text-decoration-thickness:0.12rem; text-decoration-color:rgba(38, 132, 255, 0.5);">{{listPrice(product)}}</span></div> -->
            </div>
            <div class="mb-3 pt-0 flex">
                <favorite-widget 
                    dusk="favorite-widget-component"
                    :id="product.id" 
                ></favorite-widget>
                <rating-widget
                    dusk="rating-widget-component"
                    :rating="rating(product)"
                ></rating-widget>
                <div v-for="(feature, index) in product.features"  :key="index">
                    <feature-widget
                        dusk="feature-widget-component"
                        :feature="feature"
                    ></feature-widget>
                </div>                            
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    /** Interface */
    props: {
        duplicateCollection: {
            type: Array,
            required: false,
            default: null,
        }
    }
}
</script>
