<template>
<div class="w-screen">
    <div v-if="sideDrawer" class="w-screen h-screen bg-grey-700 fixed top-0 left-0 z-16"></div>
    <transition name="drawer" mode="out-in">
        <div v-if="sideDrawer" @click="setSideDrawer(false)" id="side-drawer" class="w-drawer min-h-screen pt-2 md:pt-4 pl-3 md:pl-4 pr-4 bg-neutral-30 relative z-20">
            <div class="w-10 h-10 bg-grey-700 flex items-center justify-center rounded-full cursor-pointer">
                <icon type="back" on="click" mutator="SET_SIDE_DRAWER" :params="false" view-box="0 0 24 24" width="24" height="24" />
            </div>
            <div dusk='side-drawer-nav' class="w-full pt-12 px-6 text-lg leading-loose flex flex-col">
                <div @click="showEnvironments" class="flex items-center cursor-pointer hover:text-grey-400">Environments</div>
                <div @click="showProducts" class="flex items-center cursor-pointer hover:text-grey-400">Products</div>
                <div @click="showBrands" class="flex items-center cursor-pointer hover:text-grey-400">Brands</div>
                <div @click="showBudget" class="flex items-center cursor-pointer hover:text-grey-400">Budget</div>
                <div @click="showResources" class="flex items-center cursor-pointer hover:text-grey-400">Resources</div>
            </div>
        </div>
    </transition>
</div>
</template>
    <script>
    // Store params
    import { mapGetters, mapMutations } from 'vuex'
    // Mixins
    import NavigationMixins from '../../mixins/navigation-mixins'

    export default {

        /**
         * Composition
         */
        mixins: [NavigationMixins],

        /**
         * Local State
         */
        computed: {
            /**
             * Store Getters
             */
            ...mapGetters(['sideDrawer']),
        },


        /**
         * Lifecycle Events
         */
        watch: {
            sideDrawer(sideDrawer) {
                if (sideDrawer) {
                    window.scrollTo({
                        top:0,
                        left:0,
                        behavior: "smooth",
                    })
                }
            }
        },
        /**
         * Lifecycle Events
         */
        mounted() {         
            window.scrollTo({
                top:0,
                left:0,
                behavior: "smooth",
            })
        },

        /**
         * Non Reactive Properties
         */
        methods: {
            /**
             * Handle global keydown events 
             * 
             * @return void
             */
            // handleKeydown(event) {
            //     if (event.keyCode == 27) {
            //         this.setSideDrawer(false)
            //     }
            // },

            /**
             * Store Mutators
             */
            ...mapMutations({setSideDrawer: 'SET_SIDE_DRAWER'})
        }
    }
</script>