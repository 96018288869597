var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"relative"},[_c('select-element',{attrs:{"options":_vm.userOptions,"fixed":false,"show":_vm.select === 'dashboard-options',"trigger-id":"dashboard-options"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.name)?_c('div',{staticClass:"w-screen p-4 text-grey-100 bg-grey-900 flex items-center justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: false,
                        placement: 'top',
                    }),expression:"{ \n                        theme: 'translucent',  \n                        arrow: true,\n                        touch: false,\n                        placement: 'top',\n                    }"}],staticClass:"hover:opacity-50 cursor-pointer flex items-center",attrs:{"content":"<span class='text-blue-200'>Go</span> to the dashboard"},on:{"click":function($event){return _vm.routeTo('/dashboard')}}},[_c('icon',{staticClass:"fill-current mr-2",attrs:{"text-grey-100":"","type":"home","view-box":"0 0 24 24","width":"20","height":"20"}}),_vm._v(" "),_c('div',{staticClass:"text-lg hidden md:block"},[_vm._v("Dashboard")])],1),_vm._v(" "),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: false,
                        placement: 'bottom',
                    }),expression:"{ \n                        theme: 'translucent',  \n                        arrow: true,\n                        touch: false,\n                        placement: 'bottom',\n                    }"}],staticClass:"mx-6 hover:opacity-50 cursor-pointer flex items-center",attrs:{"content":"<span class='text-blue-100'>Go</span> to Public Website"},on:{"click":function($event){return _vm.routeTo('/')}}},[_c('icon',{staticClass:"fill-current mr-2",attrs:{"type":"website","viewBox":"0 0 24 24","width":"20","height":"20"}}),_vm._v(" "),_c('div',{staticClass:"text-lg hidden md:block"},[_vm._v("Website")])],1),_vm._v(" "),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: false,
                        placement: 'bottom',
                    }),expression:"{ \n                        theme: 'translucent',  \n                        arrow: true,\n                        touch: false,\n                        placement: 'bottom',\n                    }"}],staticClass:"hover:opacity-50 cursor-pointer flex items-center",attrs:{"content":"<span class='text-blue-100'>Update</span> Your Profile"},on:{"click":function($event){return _vm.routeTo(_vm.profileUrl)}}},[_c('icon',{staticClass:"fill-current mr-2",attrs:{"type":"profile","viewBox":"0 0 24 24","width":"20","height":"20"}}),_vm._v(" "),_c('div',{staticClass:"text-lg hidden md:block"},[_vm._v("Profile")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex items-center text-grey-100"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                        theme: 'translucent',  
                        arrow: true,
                        touch: false,
                        placement: 'bottom',
                    }),expression:"{ \n                        theme: 'translucent',  \n                        arrow: true,\n                        touch: false,\n                        placement: 'bottom',\n                    }"}],staticClass:"flex items-center text-lg hover:opacity-50 cursor-pointer",attrs:{"content":"User options","id":"dashboard-options"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelect('dashboard-options')}}},[_vm._v("\n                    "+_vm._s(_vm.name)+"\n                    "),_c('icon',{staticClass:"fill-current",attrs:{"type":"down","view-box":"0 0 24 24","width":"24","height":"24"}})],1)])]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }