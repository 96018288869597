<template>
    <div class="container mx-auto flex justify-center">
        <div class="w-full max-w-3xl">
            <h1 class="text-2xl pt-6">Specification Request for {{vendor.company_name ?? 'Vendor'}}</h1>
            <p class="text-sm text-grey-300 pb-6 max-w-xl">This form will dispatch an email request to the chosen recipient requesting that they review and approve their vendor specifications.</p>
            <form @submit.prevent="sendRequest" class="mt-6">
                <label class="block text-sm text-grey-500">Reviewer <span class="text-blue-300">(required)</span></label>
                <select v-model="form.reviewer_id" class="w-full max-w-3xl mt-2 px-3 py-2 bg-grey-700 rounded text-white">
                    <option :value="null">Select...</option>
                    <option v-for="reviewer in reviewers" :key="reviewer.id" :value="reviewer.id">{{ reviewer.name }}</option>
                    <option :value="user.id">Send to self</option>
                </select>
                <label class="mt-6 block text-sm text-grey-500">Message <span class="text-blue-300">(required)</span></label>
                <textarea v-model="form.message" name="message" id="message" rows="5" class="w-full max-w-3xl mt-2 p-2.5 text-white bg-grey-700 rounded"/>

                <div class="mt-6 flex justify-end gap-x-2">
                    <a href="/dashboard/vendors" class="bg-grey-600 hover:bg-grey-700 text-white py-2 px-4 rounded">Cancel</a>
                    <button :type="[ready ? 'submit' : 'button']" :class="[ready ? 'opacity-100' : 'opacity-25', 'px-4 py-2 bg-blue-500 text-white rounded']" >Send</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapActions } from "vuex";
import ValidationMixins from "../mixins/validation-mixins";

export default {
    /** Composition */
    mixins: [ValidationMixins],
    /** Props */
    props: {
        user: {
            type: Object,
            required: true
        },
        vendor: {
            type: Object,
            required: true
        },
        reviewers: {
            type: Array,
            required: true
        }
    },
    /** Local State */
    data() {
        return {
            form: {
                user_id: this.user.id,
                vendor_id: this.vendor.id,
                reviewer_id: null,
                message: 'It’s time to review specification information for ' + this.vendor.company_name + '.'
            }
        }
    },
    computed: {
        ready() {
            return this.form.reviewer_id !== null && this.form.message !== '';
        }
    },
    methods: {
        async sendRequest() {
            await this.reviewRequest(this.form);
        },

        /** Store Actions */
        ...mapActions([
            'reviewRequest',
        ]),
    }
}
</script>
