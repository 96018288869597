<template>
    <div class="w-full flex justify-center flex-wrap">
        <div 
            v-for="(product, index) in parsedCollection" 
            :key="index" 
            class="py-1"
        >
            <div 
                :class="{
                    'hidden': isDuplicate(product) && ! showing.includes(product.id),
                    'disabled opacity-50': showing && showing.length && ! showing.includes(product.id),
                }" 
                class="h-full m-1 max-w-sm bg-white rounded overflow-hidden shadow flex flex-col justify-between relative "
                @click="saveScrollLocation"
            >
                <a 
                    :href="showing && showing.length && ! showing.includes(product.id) ? null : `/products/${product.id}/show`" 
                    class="hover:opacity-50"
                >
                    <tip-widget 
                        v-if="! showing.length || (showing.length && showing.includes(product.id))"
                        :content="tippyContent(product)"
                    >
                        <div 
                            :id="`products-card-${product.id}`" 
                            class="w-64 h-48 flex items-center justify-center bg-white bg-center bg-no-repeat overflow-hidden bg-contain" 
                            :style="`background-image: url('${product.thumb}');`"
                        ></div>
                    </tip-widget>
                    <div v-else>
                        <div 
                            :id="`products-card-${product.id}`" 
                            class="w-64 h-48 flex items-center justify-center bg-white bg-center bg-no-repeat overflow-hidden bg-contain" 
                            :style="`background-image: url('${product.thumb}');`"
                        ></div>
                    </div>
                </a>
                <a 
                    v-if="inCurrentCatalog(product) && (! showing.length || showing.includes(product.id))"
                    :href="showing && showing.length && ! showing.includes(product.id) ? null : '/catalog'"
                    target="_blank"
                    class="w-8 h-8 mt-4 mr-4 text-grey-100 bg-blue-200 opacity-75 hover:opacity-50 cursor-pointer absolute right-0 top-0 flex items-center justify-center rounded-full"
                >
                    <tip-widget :content="`Idea Book (p${product.catalog.page})`">
                        <icon type="catalog" view-box="0 0 24 24" height="18" width="18" class="fill-current" />
                    </tip-widget>                
                </a>

                <div class="w-64 h-full p-4 flex flex-col justify-between">
                    <div 
                        @click.stop="showing.length ? showing = [] : showDuplicates(product)"
                        class="absolute top-0 left-0 z-12"
                    >
                        <tip-widget 
                            v-if="duplicateIndicator(product) && ! isDuplicate(product) && ( ! showing.length || showing.includes(product.id))" 
                            :content="`<span class='text-blue-100'>${showing.length ? 'Hide</span> additional' : 'Show</span> all'} ${product.group ? product.group.name : 'similar'} items`"
                        >
                            <div v-html="duplicateIndicator(product)"></div>
                        </tip-widget>
                    </div>
                
                    <div class="flex flex-col">
                        <div @click="routeToVendor(vendorName(product))" class="text-xs text-grey-600 font-medium leading-tight cursor-pointer hover:opacity-50">{{vendorName(product)}}</div>
                        <div class="text-base text-grey-700 font-medium leading-tight">{{groupName(product)}}</div>
                        <div class="mt-2 text-grey-600 text-xs leading-tight">{{product.shortDescription}}</div>
                        <!-- <div class="mt-2 mb-3 text-grey-600 text-xs">List Price: <span class="line-through" style="text-decoration-thickness:0.12rem; text-decoration-color:rgba(38, 132, 255, 0.5);">{{listPrice(product)}}</span></div> -->
                    </div>
                    <div class="h-8 mb-3 pt-0 flex">
                        <favorite-widget
                            v-show=" ! showing.length || showing.includes(product.id)"  
                            dusk="favorite-widget-component"
                            :id="product.id" 
                        ></favorite-widget>
                        <rating-widget
                            v-show=" ! showing.length || showing.includes(product.id)" 
                            dusk="rating-widget-component"
                            :rating="rating(product)"
                        ></rating-widget>
                        <div v-for="(feature, index) in product.features"  :key="index">
                            <feature-widget
                                v-if=" ! showing.length || showing.includes(product.id)"
                                dusk="feature-widget-component"
                                :feature="feature"
                            ></feature-widget>
                        </div>                            
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { mapMutations } from 'vuex'
/** Composition */
import ProductMixins from '../../mixins/product-mixins'
import CatalogMixins from '../../mixins/catalog-mixins'
/** Template Dependencies */
import DuplicateProducts from './snippets/DuplicateProducts.vue'

export default {
    /** Composition */
    mixins: [ProductMixins, CatalogMixins],
    
    /** Interface */
    props: {
        collection: {
            type: String,
            required: false,
            default: null,
        },
        groupCounts: {
            type: String|Array,
            required: false,
            default: null,
        },
        duplicates: {
            type: String|Array,
            required: false,
            default: null,
        }
    },
    /** Template Dependencies */
    components: {DuplicateProducts},

    /** Local State */
    data() {
        return {
            // filteredCollection: null,
            duplicateCollection: null,
            showing: [],
        }
    },
    computed: {
        /**
         * Return the parsed collection from props String.
         * 
         * @return {array}
         */
        parsedCollection() {
            if (this.collection) {
                return JSON.parse(this.collection)
            }
        },
    },

    /** Events */
    watch: {

        /**
         * Respond to changes in duplicate collection.
         */
        duplicateCollection: {
            deep: true,
            handler(collection) {
                // Empty the showing array.
                this.showing = []
                // Loop duplicates.
                _.each(collection, duplicate => {
                    // Push product id to showing array.
                    this.showing.push(duplicate.id)
                })
            }
        },
    },

    /** Lifecycle Evenmts */
    mounted() {
        let offset = parseInt(sessionStorage.getItem('pageYOffset'))

        if (offset > 0) {
            this.$nextTick(() => window.scrollTo(0, offset))
        }
    },

    /** Non Reactive Properties */
    methods: {
        /**
         * Return the visitor to the product card they were 
         * viewing when returning back from "show" page.
         * 
         * @return {void}
         */
        saveScrollLocation() {
            // Clear the current session key
            sessionStorage.removeItem('pageYOffset')
            // Save the current Y position to session
            sessionStorage.setItem('pageYOffset', window.pageYOffset)
        },

        /**
         * Return product group’s duplicate count.
         * 
         * @param {object} product
         * @return {number} The number of duplicates.
         */
        duplicateCount(product) {
            if (product.group && this.groupCounts) {
                let duplicates =  _.filter(JSON.parse(this.groupCounts), (count, name) => {
                    return product.group && product.group.name == name
                })
                return duplicates && duplicates.length 
                    ? duplicates[0] 
                    : 0
            }
        },

        /**
         * Return duplicate indicator for a given product.
         * 
         * @param {object} product
         * @return {string|null} The indicator (html or null)
         */
        duplicateIndicator(product) {          
            // Assign duplicates to variable.  
            let duplicates = this.duplicateCount(product)
            // The given product group has duplicates.
            if (duplicates) {
                // Duplicate are showing.
                return this.showing && this.showing.length 
                    // Return close indicator.
                    ?'<div class="w-8 h-8 m-4 text-2xl text-grey-100 bg-blue-200 opacity-75 hover:opacity-50 rounded-full flex items-center justify-center cursor-pointer">&times;</div>'
                    // Return show indicator.
                    :`<div class="w-8 h-8 m-4 text-sm text-grey-100 bg-blue-200 opacity-75 hover:opacity-50 rounded-full flex items-center justify-center cursor-pointer">+${duplicates}</div>`
            }
            // Product group has no duplicates.
            return null
        },

        /**
         * Determine if given product is part of duplicate group.
         * 
         * @param {object} product
         * @return {boolean} True or false
         */
        isDuplicate(product) {
            // Requires duplicates.
            if (this.duplicates) {
                // Filter duplicates array.
                let match = _.filter(JSON.parse(this.duplicates), (duplicate) => {
                    // Match ids.
                    return product.id == duplicate.id
                })
                // Duplicate array contains given product.
                return match && match.length
            }
            // Not a duplicate.
            return false
        },

        /**
         * Create collection of duplicate product ids from given product group.
         * 
         * @param {object}
         * @return {void}
         */
        async showDuplicates(product) {
            // Show app is procesing.
            await this.setProcessing(true)
            // Self starting function.
            await (() => { 
                // Requires duplicates array, and products array.
                if (this.duplicates && this.parsedCollection) {
                    // Filter thru duplicates and product collection.
                    let duplicates = _.filter(_.concat(this.parsedCollection, JSON.parse(this.duplicates)), (duplicate) => {
                        // Group has duplicate products.
                        return product.group && duplicate.group && product.group.name == duplicate.group.name
                    })
                    // Add unique products to duplicates collection.
                    this.duplicateCollection = _.uniqBy(duplicates, 'id')
                }
            })()
            // Show procesing is complete.
            await this.setProcessing(false)
        },

        /**
         * Generate content for each product card tool tip.
         * 
         * @param {object} product The product object
         * @return {string}
         */
        tippyContent(product) {
            return `<span class='text-blue-100'>View&nbsp;</span>This Model<br><small>(${product.model_number})</small>`
        },

        /** Store Mutations */
        ...mapMutations({setProcessing: 'SET_PROCESSING'})
    }
}
</script>