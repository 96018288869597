const InquiryModule = {
    state: {},

    getters: {},

    actions: {
        /**
         * Persist a new price request to the database.
         * 
         * @param {object} commit The store commit module.
         * @param {object} params The price request params.
         */
        submitInquiry({ commit }, params) {
            commit('SET_PROCESSING', true)

            axios 
                .post('/api/inquiries', params)
                .then(response => commit('SET_MARGINS', response.data))
                .then(() => commit('SET_PROCESSING', false))
                .then(() => commit('SET_NOTIFICATION', {
                    type: 'success',
                    title: 'Success',
                    message: 'Your request has been sent.', 
                    duration: 5000,
                }))
                .catch(errors => {
                    commit('SET_PROCESSING', false)

                    let message = errors.response.status === 419
                        ? "Session Expired. Please reload the page and try again."
                        : "The system is down for a short period of maintenance. Please try again later."

                    commit('SET_NOTIFICATION', {
                        type: 'warning',
                        title: 'Unable to Complete',
                        message, 
                        duration: 5000,
                    })
                })
        },
    },

    mutations: {}
}

export default InquiryModule