<template>
    <transition name="select" mode="out-in">
        <div
            v-show="show && positioned"            
            :id="`${select}-select`"
            :class="{
                'fixed': fixed, 
                'fixed mt-8': scrolling && isSubhead, 
                'absolute': ! fixed && ! scrolling
            }"
            class="w-32 bg-neutral-20 rounded shadow-lg top-0 z-20 cursor-pointer"
            :style="style"
        >
            <div 
                v-for="(option, key, index) in options"
                :key="key"
                @click="optionHandler(option)"
                v-html="option.label"
                :id="`${key}-option`"
                :class="hoverClasses(index, options)"
                class="w-full px-4 py-2 text-base text-grey-600 hover:bg-grey-400 flex items-center justify-between cursor-pointer"
            ></div>
        </div>
    </transition>
</template>
<script>
/** Vuex Store Modules */
import { mapGetters, mapMutations, mapActions } from 'vuex'
/** Store Object */
import store from '../../store'
export default {
    /** Interface */
    props:{
        options: {
            type: Object,
            required: true,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false,
        },
        triggerId: {
            type: String,
            required: false,
        },
    },
    /** Local State */
    data() {
        return {
            positioned: false,
            style: null,
        }
    },
    computed: {
        /**
         * Show/hide this select
         */
        show() {
            return this.select && this.select == this.triggerId
        },
        /**
         * Add top margin to subheads.
         * 
         * @return {boolean}
         */
        isSubhead() {
            if (this.triggerId.includes('resource')) {
                return true
            }
            return false
        },

        /** Store Getters */
        ...mapGetters(['scrolling', 'select', 'selectOptions', 'panel'])
    },

    /** Events */
    watch: {
        /**
         * Process menu request when trigger element is clicked. 
         * 
         * @param {string} id 
         * @return void
         */
        select(select) {
            if (select) {
                this.$nextTick(async () => {
                    // Position the select element.
                    await this.positionSelect()
                    // Show the positioned select with options.
                    this.positioned = true
                })
            } else {
                this.positioned = false
            }
        },

        /**
         * Close the select when panel is opened.
         * 
         * @param {string, null} panel The currently opened panel.
         * @return {void}
         */
        panel(panel) {
            // Panel is being shown.
            if (panel) {
                // Close the select menu.
                this.setSelect(null)
            }
        },
    },
    /** Lifecycle Events */
    mounted() {
        document.addEventListener('click', this.closeSelect )
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Hide the select when user clicks any other element.
         * 
         * @param {object} event The click event.
         * @return void
         */
        closeSelect(event) {
            if (
                this.triggerId 
                && event.target.id == this.triggerId 
                || (
                    event.target.parentNode 
                    && event.target.parentNode.id == this.triggerId
                )
            ) {
                return 
            }
            return this.setSelect(null)
        },
        
        /**
         * Round top and bottom options. 
         * 
         * @param {number} index The current option index 
         * @param {collection} options The collection of select options.
         * @return {string} 
         */
        hoverClasses(index, options) {
            // Declare and assign variables.
            let top = ''
            let bottom = ''
            // First option.
            if (index == 0) {
                top = ' rounded-t'
            }
            // Last option.
            if (index + 1 == _.size(options)) {
                bottom = ' rounded-b'
            }
            // Return classes.
            return top + bottom
        },
        /**
         * Handle the option action. 
         * 
         * @param {object} option
         * @return void
         */
        async optionHandler(option) {
            if (option.type && option.type == 'dispatch') {
                await store.dispatch(option.action, option.params)
            }
            if (option.type && option.type == 'commit') {
                await store.commit(option.action, option.params)
            }
            await this.setSelect(null)
            await this.setSelectOptions(null)
            this.positioned = false
        },

        /**
         * Position the active select element.
         * 
         * @return {void}
         */
        positionSelect() { 
            if (this.triggerId && this.triggerId !== undefined) {
                // Declare position variables.
                let left
                let top  
                let bottom     
                // Fetch and assign the trigger element.
                let trigger = document.querySelector(`div[id="${this.triggerId}"]`)
                // Assign the bounding for trigger element.
                let bounding = trigger ? trigger.getBoundingClientRect() : null
                // Fetch and assign the select element.
                // let select = document.getElementById(`${this.select}-select`)
                if (trigger && bounding) {
                    let adjustment = this.scrolling ? 28 : 124
                    // Set the bounding bottom.    
                    bottom = this.isSubhead /* document.getElementById('indicator-bar') */
                        ? (bounding.bottom - adjustment)  
                        : (bounding.bottom + 4)  
                    // Set menu y-axis.
                    top = this.fixed
                        ? bounding.bottom /* - window.scrollY */
                        : bottom /* + window.scrollY */
                    // Centerline of viewable area.
                    let centerline = window.innerWidth / 2
                    // Determine select position within viewable area.
                    let handing = bounding.left < centerline/2
                        ? 'left'
                        : 'right'  
                    // Set select x-axis.
                    if (handing === 'left') {
                        left = Math.round(bounding.left)
                    } else {
                        left = Math.round(bounding.right - 128)
                    }
                    this.style = "top:"+top+"px; left:"+left+"px;"
                }
            }            
        },

        /** Store Actions */
        ...mapActions(['updateProspect']),

        /** Store Mutations */
        ...mapMutations({
            setSelect: 'SET_SELECT',
            setSelectOptions: 'SET_SELECT_OPTIONS'
        })
    },
}
</script>