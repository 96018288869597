const AppMixins = {
    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Format text to title case..
         * 
         * @param {string} value The text to convert to title case.
         * @return {string}
         */
        title(value) {
            if (value.includes("é")) {
                let array = value.split(' ')
                array = _.map(array, word => {
                    return _.upperFirst(word) // This preserves special characters in word like “é”.
                })
                return array.join(' ')
            }
            return _.startCase(value) // This strips out all special characters.
        },

        /**
         * Get the kebab case of param.
         *
         * @param {string} value The text to convert to kebab case.
         * @return {string}
         */
        kebab(value) {
            return _.kebabCase(value)
        },
    }
}

export default AppMixins