import BudgetModule from './modules/BudgetModule'
import DealerModule from './modules/DealerModule'
import FavoriteModule from './modules/FavoriteModule'
import GroupModule from './modules/GroupModule'
import InquiryModule from './modules/InquiryModule'
import LinkModule from './modules/LinkModule'
import LogModule from './modules/LogModule'
import MapModule from './modules/MapModule'
import PricingModule from './modules/PricingModule'
import PromotionModule from './modules/PromotionModule'
import ProspectModule from './modules/ProspectModule'
import ResourceModule from './modules/ResourceModule'
import SearchModule from './modules/SearchModule'
import ShareModule from './modules/ShareModule'
import SpecificationModule from './modules/SpecificationModule'
import UiModule from './modules/UiModule'
import UserModule from './modules/UserModule'
import VendorModule from './modules/VendorModule'
import VisitorModule from './modules/VisitorModule'

import Vue  from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        /**
         * The vistor’s uuid
         *
         * @var string
         */
        uuid: null,
    },
    getters: {
        /**
         * Get visitor’s uuid.
         *
         * @return string
         */
        uuid({uuid}) {
            return uuid
        },
    },
    actions: {
        //
    },
    mutations: {
        /**
         *
         * @param {object} state The store.state
         * @param {string} uuid The visitor’s uuid
         */
        SET_UUID(state, uuid) {
            state.uuid = uuid
        }
    },
    modules: {
        BudgetModule,
        DealerModule,
        FavoriteModule,
        GroupModule,
        InquiryModule,
        LinkModule,
        LogModule,
        MapModule,
        PricingModule,
        ProspectModule,
        PromotionModule,
        ResourceModule,
        SearchModule,
        ShareModule,
        SpecificationModule,
        UiModule,
        UserModule,
        VendorModule,
        VisitorModule,
    }
})

export default store
