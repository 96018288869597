<template>
    <transition name="fade">
        <div 
            v-if="show"
            v-tippy="{ 
                theme: 'translucent',  
                arrow: true,
                touch: 'false',
                placement: 'top',
            }"
            :content="content"
            :style="style"
            :class="classes" 
        >
            <slot></slot>
        </div>
    </transition>
</template>
<script>

import moment from 'moment'
/** Composition */
import ValidationMixins from '../../mixins/validation-mixins'

export default {
    /** Composition */
    mixins: [ValidationMixins],
    /** Interface */
    props: {
        feature: {
            type: Object,
            required: false,
            default: null 
        },
        classes: {
            type: String,
            required: false,
            default: "w-8 h-8 bg-cover bg-center bg-no-repeat",
        },
    },

    /** Local state */
    computed: {
        /**
         * Determine if icon is ready to be shown.
         * 
         * @return {boolean}
         */
        show() {
            // Feature object exists.
            if (! this.feature) {
                return false
            }
            // Icon file exists.
            if ( ! this.urlExists("/storage/" + this.feature.icon_path)) {
                return false
            }
            // Feature is not scheduled out.
            if (
                this.feature.start_at 
                && moment(this.feature.start_at).isAfter(moment())
            ) {
                return false
            }
            // Feature is not expired.
            if (
                this.feature.expires_at 
                && moment(this.feature.expires_at).isBefore(moment())
            ) {
                return false
            }
            // Show criteria is met.
            return true
        },
        /**
         * Set the html content for the feature icon.
         * 
         * @return {string}
         */
        content() {
            let name
            let description
            
            name = this.feature.name && this.feature.description !== this.name 
                ? `<div class="mb-1 font-semibold">${this.feature.name}</div>` 
                : ''

            description = this.feature.description 
                ? `<div>${this.feature.description}</div>` 
                : ''

            return name + description
        },

        /**
         * Set the background image for the feature icon.
         * 
         * @return {string}
         */
        style() {
            if (this.feature.icon_path) {
                return `background-image: url('/storage/${this.feature.icon_path}')`
            }
        },
    },
}
</script>