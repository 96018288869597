
const SearchModule = {
    state: {
        /**
         * The app’s search results state.
         * 
         * @var {bool}
         */
        searchResults: null,

        /**
         * The app’s sort state.
         * 
         * @var {string}
         */
        sort: null,

        /**
         * The app’s order state.
         * 
         * @var {string}
         */
        order: null,
    },

    getters: {
        /**
         * Get the current search results. 
         * 
         * @param {object} searchResults The searchResults param from store.state object 
         */
        searchResults({ searchResults }) {
            return searchResults
        },

        /**
         * Get the app’s current sort state.
         * 
         * @param {string} sort The sort value from store state.
         * @return {string} The current sort state.
         */
        sort({ sort }) {
            return sort
        },

        /**
         * Get the app’s current order state.
         * 
         * @param {string} order The order value from store state.
         * @return {string} The current order state.
         */
        order({ order }) {
            return order
        },
    },

    actions: {
        //
    },

    mutations: {
        /**
         * Set the search results for view. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {object} searchResults The search results from Algolia api. Nullable.
         */
        SET_SEARCH_RESULTS(state, searchResults) {
            state.searchResults = searchResults
        },

        /**
         * Set the sort state.
         * 
         * @param {object} state The store state module.
         * @param {string} sort The sort state.
         */
        SET_SORT(state, sort) {
            state.sort = sort
        },

        /**
         * Set the order state.
         * 
         * @param {object} state The store state module.
         * @param {string} order The order state.
         */
        SET_ORDER(state, order) {
            state.order = order
        }
    },
}

export default SearchModule
