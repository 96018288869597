var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-element w-full flex justify-center"},[_c('div',{staticClass:"panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2",class:{
            'border-grey-600 cursor-default': _vm.searchTab === 'environments',
            'border-transparent cursor-pointer': _vm.searchTab !== 'environments'
        },attrs:{"dusk":"environments-search-tab"},on:{"click":function($event){_vm.searchTab = 'environments'}}},[_vm._v("Environments")]),_vm._v(" "),_c('div',{staticClass:"panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2",class:{
            'border-grey-600 cursor-default': _vm.searchTab === 'products',
            'border-transparent cursor-pointer': _vm.searchTab !== 'products'
        },attrs:{"dusk":"product-search-tab"},on:{"click":function($event){_vm.searchTab = 'products'}}},[_vm._v("Products")]),_vm._v(" "),_c('div',{staticClass:"panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2",class:{
            'border-grey-600 cursor-default': _vm.searchTab === 'groups',
            'border-transparent cursor-pointer': _vm.searchTab !== 'groups'
        },attrs:{"dusk":"collection-search-tab"},on:{"click":function($event){_vm.searchTab = 'groups'}}},[_vm._v("Collections")]),_vm._v(" "),_c('div',{staticClass:"panel-element h-10 px-2 md:px-4 text-grey-600 hover:text-grey-600 border-b-2",class:{
            'border-grey-600 cursor-default': _vm.searchTab === 'vendors',
            'border-transparent cursor-pointer': _vm.searchTab !== 'vendors'
        },attrs:{"dusk":"brand-search-tab"},on:{"click":function($event){_vm.searchTab = 'vendors'}}},[_vm._v("Brands")])])
}
var staticRenderFns = []

export { render, staticRenderFns }