<template>
    <div 
        :id="`draggable-link-${link.id}`"
        v-if="link"
        :class="{
            'rounded-t': index === 0, 
            'rounded-b': index + 1 === linksCount,
            'cursor-move': reordering && manager == 1 && linkCategory != 'new'
        }"
        class="w-full px-6 py-4 text-base bg-grey-900 border-b border-grey-800 flex" 
    > 
        <div class="w-1/4 lg:w-1/3 flex items-center relative">
            <div v-if="link.new" class="-ml-8 text-green-300 absolute left-0" title="Recently added link">
                <icon dusk="icon-new" type="new" view-box="0 0 24 24" width="24" height="24"/>  
            </div>
            <div v-if=" ! link.new && link.updated && manager == 1" class="-ml-8 text-grey-600 absolute left-0" title="Recently updated link">
                <icon dusk="icon-update" type="update" view-box="0 0 24 24" width="24" height="24"/>  
            </div>

            <div class="flex-shrink text-grey-100 hover:opacity-50">
                <a 
                    :id="`edit-link-${link.id}`"
                    v-if="manager == 1"
                    class="hover:opacity-50 flex items-center" 
                    :href="`/dashboard/links/${link.id}/edit`" 
                    target="_self" 
                    :title="`Review and edit ${link.name}`"
                >
                    <tip-widget content="<span class='text-blue-100'>Update</span> Resource">
                        {{link.order}} &ndash; {{link.name}}
                    </tip-widget>
                </a>
                <span v-else :id="`static-link-${link.id}`">{{link.name}}</span>
            </div>
        </div>

        <div class="w-3/4 lg:w-2/3 flex items-center">
            <div class="flex-grow text-grey-300 flex">
                {{link.description}} – {{createdAt}}
            </div>
            <a 
                v-if="mediaUrl"
                :href='mediaUrl'
                target="_blank"
                class="flex-shrink pr-4 text-grey-100 flex justify-center hover:opacity-50" 
            >
                <tip-widget content="<span class='text-blue-100'>Download</span> Resource">
                    <div>Download</div>
                </tip-widget>
            </a>
            <a 
                v-if=" ! mediaUrl && link.url"
                :href='link.url'
                target="_blank"
                class="flex-shrink pr-4 text-grey-100 flex justify-center hover:opacity-50" 
            >
                <tip-widget content="<span class='text-blue-100'>Visit</span> Link">
                    <div>Visit</div>
                </tip-widget>
            </a>

            <div v-if=" ! mediaUrl && ! link.url" class="pr-4 text-grey-300">&ndash;</div>    

            <span v-if="manager == 1" class="flex items-center">
                <div class="w-24 text-grey-300 flex justify-center">
                    {{link.hits}}
                </div>
                <div class="w-8 text-grey-100 flex justify-end">
                    <delete-widget 
                        :id="`delete-link-${link.id}`"
                        dusk="delete-widget-element"
                        :params="deleteParams"
                        :button="false"
                        tip="<span class='text-red-200'>DELETE</span> Resource"
                        classes="hover:opacity-50 cursor-pointer"
                    ></delete-widget>            
                </div> 
            </span>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    /**
     * Interface
     */
    props: {
        link: {
            type: String|null,
            required: false
        }, 
        mediaUrl: {
            type: String|null,
            required: false
        },
        index: {
            type: Number|String|null,
            required: true
        },
        linksCount: {
            type: Number|String|null,
            required: true
        },
        manager: {
            type: Boolean|String,
            required: false,
            default: false,
        }
    },
    /**
     * Local State
     */
    computed: {
        deleteParams() {
            if (this.link) {
                return JSON.stringify({
                    level: 'danger',
                    message: 'Permanently delete link? This action cannot be undone.',
                    action: 'deleteLink',
                    params: this.link.id
                })
            }
        },
        createdAt() {
            if (this.link && this.link.created_at) {
                return moment(this.link.created_at).format('ll')
            }
            return ''
        },
        /**
         * Store Getters
         */
        ...mapGetters(['linkCategory', 'reordering'])
    },
}
</script>