<template>
    <div class="panel-element w-full flex flex-col items-center">
        <div class="mx-2 mt-6 mb-2 flex items-center justify-center">
            <icon class="text-grey-600" type="search" view-box="0 0 24 24" width="24" height="24" />
            <input
                dusk="algolia-search-input"
                v-model="query"
                id="algolia-search-input"
                name="query"
                class="panel-element w-full search-input-dark text-lg text-grey-600 bg-transparent relative"
                placeholder="What would you like to find?"
                tabindex="1"
                autocomplete="on"
                spellcheck="false"
                dir="auto"
            >
        </div>

        <transition name="fade">
            <div
                v-if="content && content.length"
                class="flex justify-center"
            ><!-- content && content.hits && content.hits.length -->
                <div class="w-full flex flex-col justify-space">
                    <search-results
                        dusk="search-results-component"
                        :content="content"
                        :model-index="modelIndex"
                    ></search-results>

                    <search-pagination
                        dusk="search-pagination-component"
                        :index="modelIndex"
                        :content="content"
                        :links="links"
                        :meta="meta"
                        @search="search"
                    ></search-pagination>
                </div>
            </div>
            <!-- No results -->
            <div v-else class="w-full h-12 flex items-center justify-center">
                <div v-if="searchResults" class="text-title text-grey-600">No results for {{query}} within {{modelIndex}}</div>
            </div>
        </transition>
    </div>

</template>

<script>
    // Vuex store
    import { mapActions, mapMutations, mapGetters } from 'vuex'

    // Template Dependencies
    import SearchPagination from './snippets/SearchPagination'
    import SearchResults from './snippets/SearchResults'

    export default {

        /**
         * Template Dependencies
         */
        components: {SearchResults, SearchPagination},

        /**
         * Interface
         */
        props: {
            /**
             * Algolia index to use for search.
             */
            modelIndex: {
                type: String,
                required: true
            },
        },

        /**
         * Local State
         */
        data() {
            return {
                query: null,
                content: null,
                links: null,
                meta: null,
            }
        },
        computed: {
            /**
             * Store Getters
             */
            ...mapGetters(['searchResults'])
        },

        /**
         * Events
         */
        watch: {
            modelIndex() {
                this.query = null
                this.content = null
                this.setSearchResults(false)
            },
            /**
             * Handle new queries.
             *
             * @param {string} query
             * @param {string} previousQuery
             * @return void
             */
            async query(query, previousQuery) {
                if (query && query !== '') {
                    await this.search({
                        index:this.modelIndex,
                        page:1
                    })
                    await this.setSearchResults(true)
                } else {
                    this.query = null
                    this.content = null
                    this.setSearchResults(false)
                }
            },
        },

        /**
         * Lifecycle Events
         */
        mounted() {
            this.setSearchResults(false)
            // Focus the search input.
            document.getElementById('algolia-search-input').focus()
        },

        /**
         * Non-Reactive Properties
         */
        methods: {
            /**
             * Perform search for given index.
             *
             * @url https://www.algolia.com/doc/api-reference/api-parameters/#search
             * @param {string} model
             * @param {number} page
             * @return void
             */
            search(input) {
                // Show app as processing
                this.setProcessing(true)

                // Hit the app search api.
                axios.get('/api/search', {
                    params: {
                        i:input.index,
                        q:this.query,
                        page: input.page ? input.page : 1
                    }
                })
                    .then(response => {
                        this.content = response.data.data
                        this.links = response.data.links
                        this.meta = response.data.meta
                    })
                    .then(() => this.setProcessing(false))
                    .catch(errors => console.warn(errors))
                    .then(() => this.setProcessing(false))
            },
            /**
             * Store Mutations
             */
            ...mapMutations ({
                setSearchResults: 'SET_SEARCH_RESULTS',
                setShowSearch: 'SET_SHOW_SEARCH',
                setProcessing: 'SET_PROCESSING',
            })
        },
    }
</script>

<style lang="sass">
    input[id="algolia-search-input"]
        background: 0 0

    .search-input-dark
        -webkit-text-fill-color: theme('colors.grey.600')
        color: theme('colors.grey.600')

    .search-input-light
        -webkit-text-fill-color: theme('colors.grey.600')
        color: theme('colors.grey.600')

    .search-input-dark::placeholder
        -webkit-text-fill-color: theme('colors.grey.400')
        color: theme('colors.grey.400')

    .search-input-light::placeholder
        -webkit-text-fill-color: theme('colors.grey.400')
        color: theme('colors.grey.400')

    .hit-text__item
        max-width: 60ch
        font-size: theme('fontSize.base')
        font-weight: theme('fontWeight.normal')

    .hit-text__wrapper > .hit-text__item:nth-child(1)
        font-size: theme('fontSize.2xl')
        font-weight: theme('fontWeight.semibold')

    .hit-text__wrapper > .hit-text__item:nth-child(3)
        margin: 2px 0 0 0
        font-size: theme('fontSize.2xl')
        // font-weight: theme('fontWeight.semibold')

    .search-on
        // animation-duration: .2s
        animation-name: expand
        width: 8em
        transition: all .2s ease-in-out
        // width: 8em

    .search-off
        // animation-duration: .2s
        animation-name: contract
        width: 10em
        transition: all 0s ease-in-out
        // width: 10em

    @keyframes contract
        from
            width: 10em
        to
            width: 8em

    @keyframes expand
        from
            width: 8em
        to
            width: 10em

</style>
