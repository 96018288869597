<template>
    <div 
        @mouseover="active = true" 
        @mouseout="active = false" 
        @click="routeTo('/dashboard/links?category=breaking news')"
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>View</span> breaking news"
        class="w-full h-full"
        style="min-height: 160px"
    >
        <div class="h-full p-6 bg-grey-900 hover:bg-teal-300 flex flex-col justify-end rounded cursor-pointer">
            <div :class="{'text-grey-100': active, 'text-teal-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">Breaking News</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">And Additional Resources</div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions } from 'vuex'
export default {

    /**
     * Local State
     */
    data() {
        return {
            active: false,
        }
    },
    
    /**
     * Non Reactive Properties
     */
    methods: {
        /**
         * Store Actions
         */
        ...mapActions(['routeTo'])
    },
}
</script>