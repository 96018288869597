<template>
    <div class="w-full px-6 py-4 border-t-2 border-grey-800 bg-grey-900 rounded">
        <div class="w-full flex justify-center items-center">

            <div class="mx-2 text-base text-grey-100">Amount to Finance</div>
                <tip-widget 
                    trigger="click"
                    content="Enter Amount to Lease <span class='text-blue-200'>($5,000 min)</span>"
                >
                    <input 
                        v-model="amount"
                        type="text" 
                        id="amount" 
                        name="amount" 
                        placeholder=""
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        class="text-xl text-white text-center bg-transparent border-b border-grey-700 focus:border-blue-300 hover:border-blue-300"
                    >
                </tip-widget>
            <div class="px-8 text-xs leading-normal text-grey-600" style="max-width: 60ch">
                $1 Purchase Option. $150 lease documentation fee required in advance. Payments do not include applicable taxes and fees. 
            </div>
        </div>

        <div v-if="showLeasing" class="text-grey-100">
            <div class="container mx-auto">
                <div class="w-full py-6 flex items-center justify-center leading-normal">
                    <div class="w-48 text-center">
                        <div class="text-base">36 months</div>
                        <div class="text-2xl"><strong v-text="leases[36].payment"></strong></div>
                    </div>
                    <div class="w-48 text-center">
                        <div class="text-base">48 months</div>
                        <div class="text-2xl"><strong v-text="leases[48].payment"></strong></div>
                    </div>
                    <div class="w-48 text-center">
                        <div class="text-base">60 months</div>
                        <div class="text-2xl"><strong v-text="leases[60].payment"></strong></div>
                    </div>
                </div>
                <div class="my-6 text-sm flex flex-col" style="max-width: 60ch">
                    <div class="my-1 text-xl font-medium">Get Started Today!</div>
                    <div>Univest Captial offers capital leasing environments that help manage cash flow, preserve lines of credit, and stay current with changing technology.</div> 
                </div> 
                
                <div class="w-full flex items-center justify-center "> 
                    <div class="w-full flex flex-col justify-center">
                        <p>Steve Morgan</p>
                        <address>Senior Relationship Manager</address>
                        <address>phone: 612-867-1813</address>
                        <address>fax: 866-575-7617  </address> 
                        <a target="_top" href="mailto:morgans@univest.net" class="no-underline hover:opacity-50 active">morgans@univest.net</a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
/** Vuex Store */
import { mapActions } from 'vuex'
/** Dependencies */
import TipWidget from '../../Public/Widgets/TipWidget.vue'

export default {
    /** Dependencues */
    components: { TipWidget },

    /** Local State */
    data() {
        return {
            showLeasing: false,
            amount: null,
            intAmount: 0,
            leases: {
                '36': null,
                '48': null,
                '60': null
            },
            rates: null,
            duration: null,
            notification: null,
        }
    },

    /** Events */
    watch: {
        amount(value) {
            let removeDollar = value.toString().replace('$', "")
            let removeComma = removeDollar.replace(/,/g, "")
            let clean = removeComma.replace(/(\.[0-9]*?)0+/g, "")
            this.intAmount = parseInt(clean)
        },

        intAmount(value) {
            if (value > 4999) {
                this.loadLeases()
            } else {
                this.showLeasing = false
            }
        },
    },

    /** Lifecycle Events */
    async mounted() {
        await this.loadLeases()
        let amountInput = document.querySelector('input[name="amount"]')
        await amountInput.addEventListener('blur', this.addFormat)
        await amountInput.addEventListener('focus', this.removeFormat)
        await window.scrollTo(0,0)
        await amountInput.click()
    },

    /** Non Reactive Properties */
    methods: {
        addFormat(event) {
            if (event && event.target && event.target.value) {
                let formatted = '$' + parseInt(event.target.value).toFixed(2)
                this.amount = formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },

        removeFormat(event) {
            if (event && event.target && event.target.value) {
                let clean = event.target.value.toString().replace(/,/g, "")
                clean = clean.replace('.00', '')
                this.amount = clean.replace('$', '')
            }
        },
        
        async loadLeases() {

            if (this.intAmount >= 5000) {

                let $this = this

                await axios
                    .get('/api/leasing/config')
                    .then(response => {
                        this.rates = response.data.rates
                        this.duration = response.data.duration
                        this.notification = response.data.notification

                        console.log({
                            rates: this.rates,
                            responesRates: response.data.rates,
                        })
                    })
                    .catch(errors => console.warn({errors}))

                
                await _.each(this.rates, function (rates, months) {
                    $this.leases[months] = $this.createLease($this.intAmount, months, rates)   
                })

                this.showLeasing = true
            }
        },

        /**
         * Return the monthly lease payment amount as an object.
         * 
         * @param {number} amount The amount of the lease.
         * @param {number} months The length of the lease in months.
         * @param {array} months The array of lease rates.
         * @return {object}
         */
        createLease(amount, months, rates) {
            let rate = this.rateFactor(amount, rates)
            
            let payment = parseFloat(amount * rate).toFixed(2)
            payment = '$' + payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            
            return { payment }
        },

        /**
         * Return the rate factor for a given lease amount.
         * 
         * @param {number} amount The amount of the lease.
         * @param {array} rates The array of lease rates.
         * @return {number}
         */
        rateFactor(amount, rates) {
            if (rates.length && rates.length === 4) {
                let rate = rates[0]

                if (parseInt(amount) > 9999) {
                    rate = rates[1]
                }

                if (parseInt(amount) > 24999) {
                    rate = rates[2]
                }

                if (parseInt(amount) > 74999) {
                    rate = rates[3]
                }

                return rate
            }
        },

        /** Store Actions */
        ...mapActions(['routeTo'])
    },
}
</script>