<template>
    <div class="container mx-auto flex justify-center flex-wrap">
        <div 
            v-for="environment in filteredCollection" 
            :key="environment.id" 
            class="max-w-sm m-2 bg-white rounded overflow-hidden shadow flex flex-col justify-between relative"
            @click="saveScrollLocation"
        >
            <a :href="`/environments/${environment.id}/show`" :title="modelNumber(environment)">
                <div 
                    :id="`environments-card-${environment.id}`" 
                    class="w-64 h-48 flex items-center justify-center bg-white bg-center bg-no-repeat overflow-hidden bg-cover" 
                    :style="`background-image: url('${environment.thumb}');`"
                ></div>
            </a>

            <a 
                v-if="inCurrentCatalog(environment)"
                href="/catalog"
                target="_blank"
                class="w-8 h-8 mt-4 mr-4 bg-grey-100 text-grey-600 hover:opacity-50 cursor-pointer absolute right-0 top-0 flex items-center justify-center rounded-full"
            >
                <tip-widget content="Idea Book">
                    <icon type="catalog" view-box="0 0 24 24" height="18" width="18" class="fill-current" />
                </tip-widget>                
            </a>

            <div class="w-64 h-full px-6 py-4 bg-white flex flex-col justify-between">
                <div class="flex flex-col">
                    <div @click="routeToVendor(vendorName(environment))" class="text-xs text-grey-600 font-medium leading-tight cursor-pointer hover:opacity-50">{{vendorName(environment)}}</div>
                    <div class="text-base text-grey-700 font-medium leading-tight">{{groupName(environment)}}</div>
                    <p class="mt-2 text-grey-600 text-xs leading-tight">{{environment.shortDescription}}</p>
                    <!-- <div class="mt-2 mb-3 text-grey-600 text-xs">List Price: <span class="line-through" style="text-decoration-thickness:0.12rem; text-decoration-color:rgba(38, 132, 255, 0.5);">{{listPrice(environment)}}</span></div> -->
                </div>
                <div class="mb-3 pt-0 flex">
                    <favorite-widget 
                        dusk="favorite-widget-component"
                        :id="environment.id" 
                    ></favorite-widget>
                    <rating-widget
                        dusk="rating-widget-component"
                        :rating="rating(environment)"
                    ></rating-widget>
                    <div v-for="(feature, index) in environment.features"  :key="index">
                        <feature-widget
                            dusk="feature-widget-component"
                            :feature="feature"
                        ></feature-widget>
                    </div>                            
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/** Vuex Store */
import { mapGetters, mapMutations } from 'vuex'
/** Composition */
import CatalogMixins from '../../mixins/catalog-mixins'
import PricingMixins from '../../mixins/pricing-mixins'
import ProductMixins from '../../mixins/product-mixins'

export default {
    /** Composition */
    mixins: [
        CatalogMixins, PricingMixins, ProductMixins
    ],

    /** Interface */
    props: {
        collection: {
            type: String,
            required: false,
            default: null,
        },
        order: {
            type: String,
            required: false,
            default: 'desc',
        },
        sort: {
            type: String,
            required: false,
            default: 'rating',
        },
        perPage: {
            type: String|Number,
            required: false,
            default: 48,
        },
    },

    /** Local State */
    computed: {
        /**
         * Sort the environment collection by give attribute.
         * 
         * @return {void}
         */
        filteredCollection() {
            if (this.collection && this.sort && this.order) {
                
                let parsedCollection = JSON.parse(this.collection)

                let order = this.order

                switch(this.sort) {
                    case 'list_price':
                        return _.orderBy(parsedCollection, ['list_price', 'group'], [order, 'desc'])
                    case 'vendor':
                        return _.orderBy(parsedCollection, ['vendor', 'group'], [order, 'asc'])
                    case 'group':
                        return _.orderBy(parsedCollection, ['group', 'created_at'], [order, 'asc'])
                    case 'rating':
                        return _.orderBy(parsedCollection, ['rating', 'group'], ['desc', 'asc'])
                    default:
                        return _.orderBy(parsedCollection, ['created_at', 'group'], [order, 'asc'])
                }
            }
        }, 
    },

    /** Lifecycle Evenmts */
    mounted() {
        let offset = parseInt(sessionStorage.getItem('pageYOffset'))

        if (offset > 0) {
            this.$nextTick(() => window.scrollTo(0, offset))
        }
    },

    /** Non Reactive Properties */
    methods: {
        /**
         * Return the visitor to the product card they were 
         * viewing when returning back from "show" page.
         * 
         * @return {void}
         */
        saveScrollLocation() {
            // Clear the current session key
            sessionStorage.removeItem('pageYOffset')
            // Save the current Y position to session
            sessionStorage.setItem('pageYOffset', window.pageYOffset)
        },

        /**
         * Return readable rating for the rating widget.
         * 
         * @param {number} rating The rating id
         * @return {string}
         */
        readableRating(rating) {
            switch (rating) {
                case 'Basic': 
                    return '$'
                case 'Upgrade':
                    return '$$'
                case 'Premium':
                    return '$$$'
                case 1: 
                    return '$'
                case 2:
                    return '$$'
                case 3:
                    return '$$$'
                default:
                    return 'NR'          
            }
        },
    }
}
</script>