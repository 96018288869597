<template>
<div class="relative top-0">
    <select-element 
        :options="guestOptions" 
        :fixed="true" 
        :show="select === 'guest-options'"
        trigger-id="guest-options"
    ></select-element>
    <select-element 
        :options="userOptions"
        :fixed="true"
        :show="select === 'user-options'"
        trigger-id="user-options"
    ></select-element> 
    <div class="w-screen flex flex-col items-center bg-grey-700 fixed top-0 z-18">
        <div dusk="header-container" class="w-screen h-12 md:h-8 px-4 py-1 flex items-center justify-between text-grey-100 text-xs md:text-sm">
            <div dusk="side-drawer-trigger" class="flex items-center justify-center md:hidden w-10 h-10 rounded-full cursor-pointer">
                <icon type="menu" on="click" mutator="SET_SIDE_DRAWER" :params="true" view-box="0 0 24 24" width="24" height="24" />
            </div>
            <div dusk="contact-info" class="tracking-wide flex items-center">
                <div v-if="phone" class="flex items-center">
                    <div v-html="phone" class="hover:opacity-50"></div>
                    <div v-if="email" class="px-2 md:px-4">|</div>
                </div>
                <div v-html="email" class="hover:opacity-50"></div>
            </div>

            <!-- <div dusk="header-nav" v-show="scrolling" class="flex justify-center items-center">
                <div  
                    @click.stop="showEnvironments"
                    @mouseenter.stop="showEnvironments"
                    @touchstart="showEnvironments"
                    class="mx-2"
                >Environments</div>
                <div @click="showProducts" class="mx-2">Products</div>
                <div @click="showBrands" class="mx-2">Brands</div>
                <div @click="showBudget" class="mx-2">Budget</div>
                <div @click="showResources" class="mx-2">Resources</div>
            </div> -->

            <div dusk="action-icons" class="flex justify-end items-center">
                <!-- <div @click="setPanel('explore')" class="flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer">
                    <icon type="explore" on="click" mutator="SET_PANEL" params="explore" view-box="0 0 24 24" width="24" height="24" class="mx-1" />
                </div> -->
                <div 
                    dusk="favorite-icon" 
                    v-if="hasFavorites()"
                    @click.stop="routeTo('/favorites')"  
                    class="flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer"
                >
                    <tip-widget
                        content="<span class='text-blue-100'>View</span> your favorites"
                        position="bottom"
                    >
                        <icon type="favorite" view-box="0 0 24 24" width="24" height="24" class="mx-1"/>
                    </tip-widget>
                </div>
                <div
                    dusk="search-icon" 
                    v-if=" ! isNationalAccount"
                    @click.stop="setPanel('search')" 
                    class="flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer"
                >
                    <tip-widget
                        content="<span class='text-blue-100'>Search</span> the website"
                        position="bottom"
                    >
                        <icon type="search" on="click" mutator="SET_PANEL" params="search" view-box="0 0 24 24" width="24" height="24" class="mx-1" />
                    </tip-widget>
                </div>
                <!-- <a v-if="user" class="hover:opacity-50" href="/dashboard" title="Go to dashboard">
                    <div class="text-blue-100">{{user.name}}</div>
                </a> -->
                 <div 
                    dusk="user-options-trigger" 
                    v-if="user && auth" 
                    @click.stop="setSelect('user-options')" 
                    id="user-options" 
                    class="text-sm flex items-center hover:opacity-50 cursor-pointer"
                >
                    <tip-widget
                        content="User options"
                        position="bottom"
                    >
                        <span v-if="isMobile">
                            <icon type="options" on="click" mutator="SET_SELECT" params="guest-options" view-box="0 0 24 24" width="24" height="24" class="mx-1" />
                        </span>
                        <span v-else>{{user.name}}</span>
                    </tip-widget>
                    <icon v-if="isDesktop" class="fill-current " type="down" view-box="0 0 24 24" width="24" height="24" />
                </div>
                <div 
                    dusk="guest-options-trigger" 
                    v-else 
                    @click.stop="setSelect('guest-options')" 
                    id="guest-options" 
                    class="flex justify-center items-center rounded-full hover:opacity-50 cursor-pointer"
                >
                    <tip-widget
                        content="Visitor options"
                        position="bottom"
                    >
                        <icon type="options" on="click" mutator="SET_SELECT" params="guest-options" view-box="0 0 24 24" width="24" height="24" class="mx-1" />
                    </tip-widget>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
/** Vuex Store */
import { mapMutations, mapGetters, mapActions } from 'vuex'
/** Composition */
import NavigationMixins from '../../../mixins/navigation-mixins'

export default {

    /** Composition */
    mixins: [NavigationMixins],

    /** Interface */
    props: {
        phone: {
            type: String,
            required: false,
            default: null,
        }, 
        email: {
            type: String,
            required: false,
            default: null,
        },
    },
    
    /** Local State */ 
    computed: {
        /**
         * Determine if current dealer is a national account.
         * 
         * @return {boolean}
         */
        isNationalAccount() {
            if (this.dealer && this.dealer.custom_properties) {
                return this.dealer.custom_properties.national == 'true' || this.dealer.custom_properties.national == true
            }
            return false
        },
        /**
         * The user’s options.
        * 
        * @return {object}
        */
        userOptions() {
            if (this.user) {
                let url = this.user.profile 
                    ? `/dashboard/profiles/${this.user.profile.id}/edit` 
                    : '/dashboard/profiles/create'
                        
                return {
                    dashboard: {
                        label:'Dashboard', 
                        type:'dispatch', 
                        action:'routeTo', 
                        params: '/dashboard',
                    },
                    profile: {
                        label:'Profile', 
                        type:'dispatch', 
                        action:'routeTo', 
                        params: url,
                    },
                    logout: {
                        label:'Logout', 
                        type:'dispatch', 
                        action:'routeTo', 
                        params:'/logout',
                    },
                }      
            }  
            return {}          
        },
        guestOptions() {
            if (this.isNationalAccount) {
               return  {
                   login: {
                    label:'Login',
                    type:'dispatch',
                    action:'routeTo',
                    params:'/login',
                }
               }
            }

            return {
                login: {
                    label:'Login',
                    type:'dispatch',
                    action:'routeTo',
                    params:'/login',
                },
                register: {
                    label: 'Register',
                    type: 'dispatch',
                    action: 'routeTo',
                    params: '/register',
                },
            }
        },

        /**  Store Getters */
        ...mapGetters([
            'favorites',
            'scrolling', 
            'isDesktop',
            'isMobile',
            'select', 
            'dealer',
            'user', 
            'auth',
        ])
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Determine if visitor has favorites.
         * 
         * @return {boolean} True or False
         */
        hasFavorites() {
            if (this.favorites) {
                return this.favorites.length
            }
            return false
        },

        /** Store Actions */
        ...mapActions(['routeTo']),

        /** Store Mutations */
         ...mapMutations({
             setPanel: 'SET_PANEL',
             setSelect: 'SET_SELECT',
         })
    },
}
</script>