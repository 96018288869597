const UserModule = {
    state: {
        /**
         * The app’s user state.
         * 
         * @var {object|null}
         */
        user: null,
        /**
         * The app’s auth state. 
         * 
         * @var {boolean}
         */
        auth: false,
    },

    getters: {
        /**
         * Get the current user state. 
         * 
         * @param {object} user The user param from store.state object 
         */
        user({ user }) {
            return user
        },
        /**
         * Get the current auth state. 
         * 
         * @param {boolean} auth The auth param from store state object 
         */
        auth({ auth }) {
            return auth
        },
    },

    actions: {
        /**
         * Delete the user.
         * 
         * @param {object} commit The store commit object
         * @param {string} id The prospect id to be deleted.
         */
        async deleteUser({ commit }, id) {
            await commit('SET_PROCESSING', true)
            axios 
                .delete(`/dashboard/users/${id}`)
                .then(() => {
                    // create hyperlink to avoid browser blocked reload.
                    let link = document.createElement('a')
                    link.setAttribute("href", "/dashboard/users")
                    link.classList.add("hidden")
                    link.click()
                })
                .catch(errors => console.log(errors))
                .then(() => location.reload())
        },
    },

    mutations: {
        /**
         * Mutate the user state. 
         * 
         * @param {object} state The vuex store.state object.
         * @param {object} user The user object.
         */
        SET_USER(state, user) {
            state.user = user
        },
        /**
         * Mutate the auth state. 
         * 
         * @param {object} state The vuex store state object.
         * @param {boolean} auth The auth state.
         */
        SET_AUTH(state, auth) {
            state.auth = auth
        },
    },
}

export default UserModule
