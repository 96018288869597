import { mapActions, mapGetters } from 'vuex'
const ProductMixins = {

    computed: {
        /** State Getters */
        ...mapGetters(['vendors'])
    },
    /** Non Reactive Properties */
    methods: {
        /**
         * Route to vendor page.
         * 
         * @param {string} name The vendor company_name attribute
         * @return {void}
         */
        routeToVendor(name) {
            if (this.vendors) {
                let vendor = this.vendors
                    ? _.filter(this.vendors, vendor => {
                        return vendor.company_name == name
                    })
                    : null

                return vendor && vendor.length
                    ? this.routeTo(`/vendors/${vendor[0].id}`)
                    : null
            }
        },

        /**
         * Return the product vendor name.
         * 
         * @param {object} product The product model
         * @return {string} The group name
         */
        vendorName(product) {
            if (product) {
                return product.vendor && product.vendor.company_name
                    ? product.vendor.company_name
                    : product.vendor
            }
            return null
        },

        /**
         * Return the product group name.
         * 
         * @param {object} product The product model
         * @return {string} The group name
         */
        groupName(product) {
            if (product) {
                return product.group && product.group.name
                    ? product.group.name
                    : product.group
            }
            return null
        },

        /**
         * Return the product list price.
         * 
         * @param {object} product The product object
         * @return {string} The list price
         */
        listPrice(product) {
            if (product) {
                return product.list_price && product.list_price !== ''
                    ? '$'+product.list_price 
                    : null
            }
            return null
        },

        /**
         * Return the product sell price.
         * 
         * @param {object} product The product object
         * @return {string} The sell price
         */
        sellPrice(product) {
            if (product) {
                return product.sell_price && product.sell_price !== ''
                    ? '$'+product.sell_price 
                    : null
            }
            return null
        },

        /**
         * Return the product group name.
         * 
         * @param {object} product The product model
         * @return {string} The group name
         */
        modelNumber(product) {
            if (product) {
                return product.model_number && product.model_number !== ''
                    ? product.model_number 
                    : null
            }
            return null
        },

        /**
         * Return the product description.
         * 
         * @param {object} product The product model
         * @return {string} The description
         */
        description(product) {
            if (product) {
                return product.description && product.description !== ''
                    ? product.description 
                    : null
            }
            return null
        },

        /**
         * Return the product rating.
         * 
         * @param {object} product The product model
         * @return {string} The rating
         */
        rating(product, symbol = true) {
            let rating = null
            
            if (product) {
                switch (product.rating) {
                    case ('Basic'):
                        return symbol ? '$' : product.rating
                    case ('Upgrade'):
                        return symbol ? '$$' : product.rating  
                    case ('Premium'):
                        return symbol ? '$$$' : product.rating
                    case 1: 
                        return symbol ? '$' : 'Basic'
                    case 2:
                        return symbol ? '$$' : 'Upgrade'
                    case 3:
                        return symbol ? '$$$' : 'Premium'
                    default: 
                        break      
                } 
            }
            return rating
        },

        /**
         * Return a comma separated list of categories for the given product.
         * 
         * @param {object} product 
         * @return {string} The categories as a string.
         */
        categories(product) {
            let categories = []
            
            if (product && product.categories) {               
    
                _.each(product.categories, category => {
                    categories = _.concat(categories, _.startCase(category.name.en))
                })
                
                if (categories.length == 1) {
                    return _.head(categories)
                }
    
                if (categories.length == 2) {
                    return _.join(categories, ' and ') 
                }
    
                if (categories.length > 2) {                
                    let last = categories.pop()
                    return _.join(categories, ', ') + ', and ' + last
                }
            }
            
            return categories
        },

        /**
         * Return a comma separated list of applications for the given product.
         * 
         * @param {object} product 
         * @return {string} The applications as a string.
         */
        applications(product) {
            let applications = []

            if (product && product.applications) {               
    
                _.each(product.applications, application => {
                    applications = _.concat(applications, _.startCase(application.name.en))
                })
    
                if (applications.length == 1) {
                    return _.head(applications)
                }
    
                if (applications.length == 2) {
                    return _.join(applications, ' and ') 
                }
    
                if (applications.length > 2) {                
                    let last = applications.pop()
                    return _.join(applications, ', ') + ', and ' + last
                }
            }
            
            return applications
        },

        /** Store Actions */
        ...mapActions(['routeTo'])
    },


}

export default ProductMixins