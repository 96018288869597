<template><!--  redirectToliftoff()--> <!-- routeTo('/budgets') -->
    <div
        @mouseover="active = true"
        @mouseout="active = false"
        @click="routeTo('/dashboard/users')"
        v-tippy="{
            theme: 'translucent',
            arrow: true,
            touch: 'false',
            placement: 'top'
        }"
        content="<span class='text-indigo-200'>Manage</span> Dashboard Users"
        class="flex-1 w-full bg-grey-900 hover:bg-indigo-300 rounded cursor-pointer"
    >
        <div class="h-full px-6 flex items-center">
            <div class="h-full flex flex-col justify-center">
                <span :class="{'text-grey-100': active, 'text-indigo-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-tight">Users</span>
                <span class="text-xs md:text-sm lg:text-base xl:text-xl text-grey-100 leading-tight">Manage Dashboard Users</span>
            </div>
        </div>
    </div>
</template>
<script>
// Store Components
import { mapActions, mapGetters } from 'vuex'
export default {

    /** Local State */
    data() {
        return {
            active: false,
            dealerCode: null,
        }
    },
    computed: {
        /** Return the user’s name */
        userName() {
           if (this.user) {
               return this.user.name
           }
        },
        /** Return the user employer’s company name */
        companyName() {
            if (this.user && this.user.employer) {
                return this.user.employer.company_name
            }
        },
        /** Return the username for liftoff */
        emailHandle() {
            if (this.user && this.user.email) {
                let split = this.user.email.split('@')
                return split.length ? split[0] : null
            }
        },
        /** Return the email domain */
        emailDomain() {
            if (this.user && this.user.email) {
                let split = this.user.email.split('@')
                return split.length ? split[1] : null
            }
        },
        /** Store Getters */
        ...mapGetters(['user']),
    },
    /** Events */
    watch: {
        /** Fetch dealer code when user changes */
        user() {
            this.fetchDealerCode()
        }
    },
    /** Life Cycle Events */
    mounted() {
        this.$nextTick(() => {
            this.fetchDealerCode()
        })
    },
    /** Non Reactive Properties */
    methods: {
        /** Get and set the dealer registration code for liftoff */
        fetchDealerCode() {
            if (this.user && this.user.employer) {
                axios
                    .get(`/api/dealers/${this.user.employer.id}/registration-code`)
                    .then(response => this.dealerCode = response.data)
                    .catch(errors => console.log(errors))
            }
        },
        /** Store Actions */
        ...mapActions(['routeTo']),
    },
}
</script>
