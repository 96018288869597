<template>
    <div 
        @mouseover="active = true" 
        @mouseout="active = false" 
        @click="routeTo(`/dashboard/profiles/${user.profile.id}/edit`)"
        class="w-full h-full"
        v-tippy="{ 
            theme: 'translucent',  
            arrow: true,
            touch: 'false',
            placement: 'top',
        }"
        content="<span class='text-blue-200'>Manage</span> your user profile"
    >
        <div class="h-full mb-2 p-6 bg-grey-900 hover:bg-teal-300 flex flex-col justify-end rounded cursor-pointer">
            <div :class="{'text-grey-100': active, 'text-teal-200': ! active}" class="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative z-4">Profile</div>
            <div class="text-xs md:text-sm lg:text-base xl:text-xl relative z-4 text-grey-100">Update Your Profile</div>
        </div>
    </div>
</template>
<script>
/** Store Components */ 
import { mapActions, mapGetters } from 'vuex'
export default {    
    /**
     * Local State
     */
    data() {
        return {
            active: false,
        }
    },
    computed: {
        /** Store Getters */
        ...mapGetters(['user'])
    },

    /**
     * Non-Reactive Properties
     */
    methods: {
        /** Store Actions */
        ...mapActions(['routeTo'])
    },
}
</script>