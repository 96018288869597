/** Dependencies */
import moment from 'moment'

const CatalogMixins = {
    /** Local State */
    computed: {
        /**
         * The current catalog year.
         * 
         * @return {number}
         */
        currentIdeaBook() {
            if (moment().format('M') == 12) {
                return moment().add(1, 'month').format('Y')
            } 
            return moment().format('Y')
        },
    },

    /** Non Reactive Properties */
    methods: {
        /**
         * Determine if resource is in current catalog. 
         * 
         * @return {boolean}
         */
        inCurrentCatalog(resource) {
            if (
                resource 
                && resource.catalog 
                && resource.catalog.page 
                && resource.catalog.year == this.currentIdeaBook
            ) {
                return true
            }
            return false
        },
    },
}

export default CatalogMixins