<template>
<div class="relative">
    <div 
        v-tippy="{
            theme: 'translucent',  
            arrow: true,
            touch: 'true',
            placement: 'top',
            trigger: 'click',
            maxWidth: 480,
        }"
        :content="content"
        :style="`background-image: url('${card}'); background-color: white`"
        class="w-24 h-24 m-2 p-4 rounded shadow hover:opacity-50 hover:shadow-none bg-cover bg-center bg-no-repeat cursor-pointer"
    ></div>
</div>
</template>
<script>
export default {
    /** Interface */
    props: ['card', 'image'],

    /** Local State */
    computed: {
        content() {
            if (this.image) {
                return `<img class="p-2" src="${this.image}">`
            }
            return 'Full size image unavailable.'
        },
    },
}
</script>