<template>
    <div class="w-screen flex flex-col items-center bg-grey-700 fixed top-0 z-16">
        <div dusk="header-container" class="w-screen px-4 py-1 flex items-center justify-between text-grey-100 text-xs md:text-sm">

            <a href="/" :title="`${dealerObject.store_name} home page`" target="_self">
                <div v-if="dealerObject" v-text="dealerObject.store_name" class="text-lg text-grey-100"></div>
            </a>
            
            <div dusk="contact-info" class="ml-4 tracking-wide flex items-center">
                <div v-if="dealerObject && dealerObject.phone && dealerObject.phone != '0'" v-html="dealerObject.phone" class="hover:text-blue-300"></div>
                <div v-if="dealerObject && dealerObject.email && dealerObject.phone && dealerObject.phone != '0'" class="px-2 md:px-4">|</div>
                <div v-if="dealerObject && dealerObject.email" v-html="dealerObject.email" class="hover:text-blue-300"></div>
            </div>
        </div>
    </div>
</template>
<script>
// Store params
import { mapMutations, mapGetters, mapActions } from 'vuex'
// Composition
import NavigationMixins from '../../mixins/navigation-mixins'
export default {

    /**
     * Composition
     */
    mixins: [NavigationMixins],

    /**
     * Interface
     */
    props: {
        dealer: {
            type: String,
            required: false,
            default: null,
        }
    },
    
    /**
     * Local State
     */
    computed: {

        dealerObject() {
            if (this.dealer) {
                return JSON.parse(this.dealer)
            }
        },

        /**
         * Store Getters
         */
        ...mapGetters(['scrolling'])
    },

    /**
     * Non-Reactive Properties
     */
    methods: {

        /**
         * Store Mutations
         */
         ...mapMutations({
             setPanel: 'SET_PANEL',
             setSelect: 'SET_SELECT',
         })
    },
}
</script>