<template>
    <div v-if="form === 'budget'" class="w-screen h-screen p-0 lg:p-24 flex items-center justify-center fixed top-0 z-12">
        <div v-show=" ! processing" class="w-screen h-screen bg-grey-900 opacity-75 absolute top-0 z-12"></div>
        <div class="w-full min-h-64 bg-grey-100 rounded shadow relative top-0 z-12">
            <div class="w-full p-6 text-2xl text-grey-600 flex justify-between">
                <div class="w-full flex flex-col">
                    <div>Instant Budget</div>
                    <div class="text-xs" style="max-width: 45ch">
                        <em class="font-medium">Will Not Exceed</em> packages are comprised of commercial-grade furniture and include solutions for Private Offices, Open Areas, and Support Areas. 
                    </div>
                </div>
                <div @click="close()" class="cursor-pointer">
                    <icon type="close" view-box="0 0 24 24" width="24" height="24" />
                </div>
            </div>

            <div class="w-full flex flex-wrap">
                <div class="w-full px-6 py-2 flex flex-col justify-around relative">
                    <input 
                        v-model="occupancy"
                        @keypress="numericOnly()"
                        ref="occupancy" 
                        type="text" 
                        class="h-12 md:h-18 text-5xl md:text-8xl text-center text-grey-600 bg-transparent border-transparent focus:border-transparent relative" 
                        name="occupancy"
                        style="outline:none"
                    >
                    <label for="occupancy" class="w-full mb-6 text-sm md:text-lg font-medium text-grey-600 flex justify-center">Occupancy</label>
                    <transition name="panel" mode="out-in">
                        <div v-if="budgetPackages" class="w-full flex flex-col md:flex-row">
                            <div class="w-full md:flex-1 my-4 md:my-0 flex flex-col items-center">
                                <div class="w-full text-2xl md:text-5xl text-grey-600 leading-tight flex items-center justify-center">{{basicTotal | currency}}</div>
                                <div class="w-full text-sm md:text-base font-medium text-grey-600 flex items-center justify-center">Basic</div>
                            </div>
                            <div class="w-full md:flex-1 my-4 md:my-0 flex flex-col items-center">
                                <div class="w-full text-2xl md:text-5xl text-grey-600 leading-tight flex items-center justify-center">{{upgradeTotal | currency}}</div>
                                <div class="w-full text-sm md:text-base font-medium text-grey-600 flex items-center justify-center">Upgrade</div>
                            </div>
                            <div class="w-full md:flex-1 my-4 md:my-0 flex flex-col items-center">
                                <div class="w-full text-2xl md:text-5xl text-grey-600 leading-tight flex items-center justify-center">{{premiumTotal | currency}}</div>
                                <div class="w-full text-sm md:text-base font-medium text-grey-600 flex items-center justify-center">Premium</div>
                            </div>
                        </div>
                    </transition>
                    <transition name="panel" mode="out-in">
                        <div
                            v-if="showBudgetRequestForm"
                            class="w-full px-6 py-12 flex items-center"
                        >
                            <div class="w-full md:flex-grow mr-2 flex flex-col">
                                <div class="w-full flex">
                                    <label for="name" class="text-xs text-grey-600">Name</label> 
                                    <div v-if=" ! name" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                                    <div v-if="name && ! validateName(name)" class="ml-1 text-xs text-red-200"><small>Names must use letters only.</small></div>
                                </div>
                                <input 
                                    v-model="name"
                                    id="name" 
                                    type="text" 
                                    class="w-full py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                                    name="name"
                                    placeholder="Your name" 
                                    required
                                >
                            </div>
                            <div class="w-full md:flex-grow ml-2 flex flex-col">
                                <div class="w-full flex">
                                    <label for="email" class="text-xs text-grey-600">Email</label> 
                                    <div v-if="! email" class="ml-1 text-xs text-red-200"><small><em>(Required)</em></small></div>
                                    <div v-if="email && ! validateEmail(email)" class="ml-1 text-xs text-red-200"><small>This does not appear to be a valide email address.</small></div>
                                </div>
                                <input 
                                    v-model="email"
                                    id="email" 
                                    type="email" 
                                    class="w-full mr-2 py-1 bg-transparent border-b border-neutral-50 focus:border-blue-200 hover:border-blue-200" 
                                    name="email"
                                    placeholder="Your email" 
                                    required
                                >
                            </div>
                            <div 
                                @click="readyToSubmit ? submitBudgetInquiry() : null" 
                                :class="{
                                    'opacity-50 cursor-default': ! readyToSubmit, 
                                    'hover:opacity-50 cursor-pointer': readyToSubmit, 
                                }"
                                v-tippy="{
                                    theme: 'translucent',
                                    arrow: true,
                                }"
                                content="``"
                                class="w-48 py-2 px-4 text-blue-200 " 
                            >Send</div>
                        </div>
                    </transition>
                    
                </div>
            </div>
            <div v-if="budgetPackages && ! showBudgetRequestForm" class="w-full px-8 py-6 flex justify-end items-center">
                <div 
                    @click="showRequestForm(validOccupancy)" 
                    :class="{
                        'opacity-50 cursor-default': ! validOccupancy, 
                        'hover:opacity-50 cursor-pointer': validOccupancy, 
                    }"
                    class="py-2 px-4 text-blue-200 " 
                >Get Budget Breakdown</div>
            </div>
        </div>        
    </div>
</template>
<script>
/** Vuex Store */
import { mapActions, mapGetters, mapMutations } from 'vuex'
/** Dependencies */
import tippy from 'tippy.js'
/** Composition */
import ValidationMixins from '../../mixins/validation-mixins'

export default {
    /** Composition */
    mixins: [ValidationMixins],

    /** Local State */
    data() {
        return {
            instance: null,
            occupancy: null,
            showBudgetRequestForm: false,
            name: null,
            email: null,
        }
    },
    computed: {
        /**
         * Show that the form is ready to submit.
         * 
         * @return {boolean} True of false
        */
        readyToSubmit() {

            return this.validateEmail(this.email) 
                && this.validateName(this.name)
                && parseInt(this.occupancy) >= 4
                && this.basicTotal 
                && this.upgradeTotal
                && this.premiumTotal
        },

        /**
         * Assemble the budget payload for the inquiry.
         * 
         * @return {object}
         */
        payload() {
            return {
                occupancy: parseInt(this.occupancy),
                basicTotal: this.basicTotal,
                upgradeTotal: this.upgradeTotal,
                premiumTotal: this.premiumTotal,
                packages: this.budgetPackages,
            }
        },

        /**
         * Return the total price of the package.
         * 
         * @return {string|null} The total price.
         */
        basicTotal() {
            if (this.budgetPackages && this.budgetPackages.basic) {
                let basic = []
                _.each(this.budgetPackages.basic, price => {
                    basic.push(price)
                })
                return _.sum(basic)
            }
        },

        /**
         * Return the total price of the package.
         * 
         * @return {string|null} The total price.
         */
        upgradeTotal() {
            if (this.budgetPackages && this.budgetPackages.upgrade) {
                let upgrade = []
                _.each(this.budgetPackages.upgrade, price => {
                    upgrade.push(price)
                })
                return _.sum(upgrade)
            }
        },

        /**
         * Return the total price of the package.
         * 
         * @return {string|null} The total price.
         */
        premiumTotal() {
            if (this.budgetPackages && this.budgetPackages.premium) {
                let premium = []
                _.each(this.budgetPackages.premium, price => {
                    premium.push(price)
                })
                return _.sum(premium)
            }
        },

        /**
         * Return the integer value of the input occupancy.
         * 
         * @return {number} 
         */
        parsedOccupancy() {
            return parseInt(this.occupancy)
        },

        /**
         * Determine if occupancy minimum is met.
         * 
         * @return {boolean} 
         */
        validOccupancy() {
            return 4 <= this.parsedOccupancy
        },

        /**
         * Return the current state of the tippy content.
         * 
         * @return {string}
         */
        content() {
            return 4 > this.parsedOccupancy
                ? 'Minimum of 4-person occupancy required.'
                : 'Enter the total number of people that this workspace will need to accommodate.'
        },

        /** Store Getters */
        ...mapGetters([
            'budgetPackages',
            'processing',
            'dealer', 
            'form', 
            'uuid', 
        ])
    },

    /** LifeCycle Events */
    watch: {

        /**
         * Focus the occupancy input.
         * 
         * @param {string} form The current form state.
         * @return {void}
         */ 
        form(form, oldForm) {
            // Form state is 'budget'.
            if (form === 'budget') {
                this.$nextTick(async () => {
                    await this.createTippyInstance()
                    await this.instance.setContent(this.content)
                    await this.$refs.occupancy.focus()
                    this.instance.show(true)
                })
            } 
            
            if (form !== 'budget' && oldForm === 'budget') {
                this.close()
            }
        },

        /**
         * Update the budget packages and tippy state when occupancy changes.
         * 
         * @param {number} occupancy The current occupancy state.
         * @return {void}
         */
        occupancy(occupancy) {
            this.$nextTick(async () => {
                // Occupancy is less than 4.
                if (! occupancy || (4 > parseInt(occupancy))) {
                    await this.setBudgetPackages(null)
                    await this.instance.setContent(this.content)
                    await this.instance.show(true)
                } 
                // Occupancy is equal to or greater than 4.
                if (4 <= parseInt(occupancy)) {
                    await this.instance.setContent(null)
                    await this.instance.hide(true)
                    await this.getBudgetPackages(parseInt(occupancy))
                }
            })
        },
    },

    /** Non Reactiove Properties */
    methods: {
        
        /**
         * Create a tippy instance for the form.
         * 
         * @return {void}
         */
        createTippyInstance() {
            this.instance = tippy(document.querySelector('input[name="occupancy"]'), {
                arrow: true,
                theme: 'translucent',
                placement: 'top',
                trigger: 'manual',
                duration: 999999,
            })
        },

        /**
         * Set the show state of the request form.
         * 
         * @return {void} 
         */
        showRequestForm(show) {
            this.showBudgetRequestForm = show
        },

        /**
         * Reset the instant budget form.
         * 
         * @return {void}
         */
        async resetForm() {
            await this.setBudgetPackages(null)
            this.occupancy = null
        },

        /**
         * Package and submit the budget inquiry.
         * 
         * @return {void}
         */
        async submitBudgetInquiry() {            
            let input = {
                name: this.name,
                email: this.email,
                store_number: this.dealer.store_number,
                payload: this.payload,
                inquiry_type: 'budget',
                path: window.location.pathname,
            }

            await this.submitInquiry(input)
            await this.close()
        },
        
        /**
         * Close the form.
         * 
         * @return {void}
         */
        async close() {
            // if (this.instance) {
                await this.instance.destroy()
            // }
            await this.resetForm()
            await this.setForm(null)
        },


        /**
         * Only allow numeric input.
         *
         * @return Boolean
         */
        numericOnly(event) {
            if (event) {
                return event.charCode >= 48 && event.charCode <= 57
            }
        },

        /** Store Actions */
        ...mapActions([
            'getBudgetPackages',
            'submitInquiry',
        ]),

        /** Store Mutations */
        ...mapMutations({
            setBudgetPackages: 'SET_BUDGET_PACKAGES',
            setProcessing: 'SET_PROCESSING',
            setForm: 'SET_FORM',
        })
    },
}
</script>