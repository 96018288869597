<template>
    <div v-if="user" class="relative">
        <select-element
            :options="userOptions"
            :fixed="false"
            :show="select === 'dashboard-options'"
            trigger-id="dashboard-options"
        ></select-element>
        <transition name="fade" mode="out-in">
            <div v-if="name" class="w-screen p-4 text-white bg-grey-800">
                <div class="container mx-auto flex items-center justify-between">
                    <div class="text-2xl">Connexions Vendor Specification Review</div>
                    <div class="flex items-center gap-x-4 text-grey-200">
                        <div>Vendor: {{vendorName}}</div>
                        <div>Reviewer: {{name}}</div>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>
<script>
// Store components
import { mapGetters, mapMutations, mapActions } from 'vuex'
/** Composition */
import SelectElement from '../../Public/Elements/SelectElement'
export default {
    /** Composition */
    components: {SelectElement},
    /** Props */
    props: {
        vendor: {
            type: Object,
            required: true
        },
    },
    /** Local State */
    computed: {
        /**
         * The user’s name.
         *
         * @return {string}
         */
        name() {
            if (this.user) {
                return this.user.name
            }
        },
        /**
         * The vendor’s name.
         *
         * @return {string}
         */
        vendorName() {
            if (this.vendor) {
                return this.vendor.company_name
            }
        },

        /**
         * The user’s current profile url.
         *
         * @return {string}
         */
        profileUrl() {
            if (this.user) {
                return this.user.profile
                    ? `/dashboard/profiles/${this.user.profile.id}/edit`
                    : '/dashboard/profiles/create'
            }
        },

        /**
         * The user’s current profile url.
         *
         * @return {string}
         */
        passwordUrl() {
            if (this.user) {
                return `/dashboard/users/${this.user.id}/security`
            }
        },
        /**
         * The user’s options.
         *
         * @return {object}
         */
        userOptions() {
            console.log()
            if (this.user) {
                return {
                    // homepage: {
                    //     label:'Public Site', type:'dispatch', action:'routeTo', params: '/',
                    // },
                    // profile: {
                    //     label:'Profile', type:'dispatch', action:'routeTo', params: this.profileUrl,
                    // },
                    // password: {
                    //     label:'Password', type:'dispatch', action:'routeTo', params: this.passwordUrl,
                    // },
                    logout: {
                        label:'Logout', type:'dispatch', action:'routeTo', params:'/logout',
                    },
                }
            }
        },

        /** Store Getters */
        ...mapGetters(['user', 'select'])
    },

    /** Non Reactive Properties */
    methods: {
        /** Store Actions */
        ...mapActions(['routeTo']),
        /** Store Mutations */
        ...mapMutations({setSelect:'SET_SELECT'})
    },
}
</script>
