<template>
    <div 
        :style="`background-image: url('${image}'); background-color: white`"
        :class="{
            'h-screen md:h-5/6 bg-cover bg-center': resourceType === 'environments', 
            'h-screen md:h-2/3 bg-contain bg-center': resourceType === 'products'
        }"
        class="w-screen mx-auto bg-no-repeat relative flex" 
    > 
    
        <request-price 
            v-if="requestingPrice" 
            :item="parsedResource" 
            :type="beingViewed"
            @close="requestingPrice = false"
        ></request-price>
        <div
            v-if="parsedResource"
            class="flex-shrink h-auto p-12 bg-white opacity-75 text-grey-700"
            style="max-width: 40ch"
        >            
            <div v-if="parsedResource.model_number" class="text-base font-medium ">{{parsedResource.model_number}}</div> 
            <div v-if="beingViewed === 'products'" class="mt-2 text-sm">{{parsedResource.description}}</div> 
            <div v-if="beingViewed === 'products'" class="mt-2 text-sm">{{parsedResource.dimension}}</div> 

            <div class="w-full pt-6 mt-10 border-t border-grey-300 flex items-center">
                <div v-if="parsedResource.list_price && parsedResource.list_price != 0" class="flex-shrink mr-4 text-grey-600">
                    <div class="text-xs font-medium leading-tight tracking-wide">List Price</div>
                    <div class="line-through" style="text-decoration-thickness:0.3rem; text-decoration-color:rgba(38, 132, 255, 0.5);"><span>$</span>{{parsedResource.list_price}}</div>
                </div>
                <div class="flex-shrink text-grey-700">
                    <div v-if="parsedResource.list_price && parsedResource.list_price != 0 && sellPrice && sellPrice != 0 && sellPrice != '$0'" class="text-sm font-medium leading-tight tracking-wide">Your Price</div>
                    <div v-if="parsedResource.list_price && parsedResource.list_price != 0 && sellPrice && sellPrice != 0 && sellPrice != '$0'" class="text-lg">{{sellPrice}}</div>
                    <div 
                        v-else 
                        @click="showRequestForPrice($event)"
                        :data-type="beingViewed" 
                        :data-id="parsedResource.id"  
                        id="request-price"
                        class="text-sm text-blue-200 hover:opacity-50 cursor-pointer"
                    >Request Price</div>
                </div>
            </div>  
            <div v-if="parsedResource.list_price && parsedResource.list_price != 0 && sellPrice && sellPrice != 0 && sellPrice != '$0'" class="w-full pt-4 flex items-center">
                <div class="flex-shrink mr-4 text-grey-600 text-xs">
                   <em>Due to market conditions, all prices are subject to change. Speak to your sales representative for the current price.</em>
                </div>
            </div>          
        </div>
        <!-- <div class="hidden md:block relative" v-if="parsedMaps && parsedMaps.length"> -->
            <div 
                v-for="(map, index) in parsedMaps" 
                :key="index" 
                :id="`map-${map.id}`" 
                class="w-6 h-6 bg-blue-200 rounded-full absolute"
            ></div>
        <!-- </div> -->
    </div>
</template>
<script>
/** Dependencies */
import tippy from 'tippy.js'
import PricingMixins from '../../mixins/pricing-mixins'
/** Vuex Store */
import { mapActions, mapGetters } from 'vuex'
export default {
    /** Dependencies */
    mixins: [PricingMixins],
    /** Interface */
    props: {
        /**
         * the resource model.
         * 
         * @var {string} resource
         */
        resource: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * The resource type.
         * 
         * @var {string} resourceType The resource type from blade view.
         */
        resourceType: {
            type: String,
            required: false,
            default: 'products',
        },
        /**
         * The model feature image.
         * 
         * @var {string} image JSON encoded object from blade view.
         */
        image: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * The array of image maps.
         * 
         * @var {string} maps JSON encoded array from blade view.
         */
        maps: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * The displayable sell price for this resource.
         * 
         * @var {string} sellPrice String from blade view.
         */
        sellPrice: {
            type: String,
            required: false,
            default: null,
        }
    },

    /** Local State */
    data() {
        return {
            /**
             * The tippy instance. 
             * 
             * @var {object|null}
             */
            tippy,
            /**
             * The amount of top padding to add to map items.
             * 
             * @var {number|null}
             */
            pad: null,
            /**
             * The current mappable item being viewed.
             * 
             * @var {object|null}
             */
            mappable: null,
        }
    },
    computed: {
        /**
         * Return the type of resource being viewed in detail page. 
         * 
         * @return {string}
         */
        beingViewed() {
            return window.location.pathname.includes('environments')
                ? 'environments'
                : 'products'
        },
        /** 
         * Parse the JSON encoded resource prop  
         * 
         * @var {array}
         */
        parsedResource() {
            /** Requires maps */
            if (this.resource) {
                return JSON.parse(this.resource)
            }
        },

        /** 
         * Parse the JSON encoded maps prop  
         * 
         * @var {array}
         */
        parsedMaps() {
            /** Requires maps */
            if (this.maps) {
                return JSON.parse(this.maps)
            }
        },

        item() {
            return this.mappable 
                ? this.mappable 
                : this.parsedResource
        },

        /** Store Getters */
        ...mapGetters(['mappables'])
    },

    /** Lifecycle Events */
    async mounted() {
        /** Image top offset for map placement.  */
        await this.getMapPad()
        /** Place the image maps.  */
        await this.placeMaps()
        /** Fetch the mappable. */
        this.$nextTick(async () => {
            if (
                this.parsedMaps 
                && this.parsedMaps.length 
                && _.head(this.parsedMaps).media_id
            ) {
                await this.fetchMappables(_.head(this.parsedMaps).media_id) 
            }
        })
    },

    /** Non-Reactive Properties */
    methods: {
        /**
         * Place the array of image maps.
         * 
         * @return {void}
         */
        placeMaps() {
            /** Requires parsed maps. */
            if (this.parsedMaps) {
                /** Loop the maps array */
                _.map(this.parsedMaps, parsedMap => {
                    /** Fetch each map element */
                    let map = document.getElementById(`map-${parsedMap.id}`)
                    /** Assign top location as percentage */
                    map.style.top = parsedMap.top + this.pad + "%"
                    /** Assign leftlocation as percentage */
                    map.style.left = parsedMap.left + "%"
                })
            }
        },
        /**
         * Determine top offset based on the image crop.
         * 
         * @return {void}
         */
        getMapPad() {
            /** Create image object */
            const img = new Image()
            /** Fetch src from media url */
            img.src = this.image
            /** Wait for the image to load out */
            img.onload = () => { 
                /** Assign window width. */ 
                let windowWidth = window.innerWidth
                /** Assign percentage of image decrease|increase to match window. */
                let ratio = (window.innerWidth * 100 / img.width) * 0.01
                /** Assign the top padding for map placement */
                let pad = Math.round((ratio * img.height - this.clientHeight)  / 2)
                /** Assign the adjusted image height */
                let height = Math.round(ratio * img.height)
                // Mutate the local state of the pad. 
                this.pad = (pad * 100) / (pad + height) 

                this.getContent() 
            }
        },

        /**
         * Construct the tippy instance for mappable item. 
         * 
         * @return {void}
         */
        getContent() {

            if (this.parsedMaps && this.parsedMaps.length) {

                _.each(this.parsedMaps, map => {

                    let element = document.querySelector(`div[id="map-${map.id}"]`)

                    let placement = (window.innerWidth / 2) > element.getBoundingClientRect().left
                        ? 'right' : 'left'
                    
                    let $this = this
                    
                    tippy(element, {
                        theme: 'translucent',  
                        arrow: true,
                        touch: 'false',
                        placement,
                        interactive: true,
                        interactiveBorder: 90,
                        allowHTML: true,
                        maxWidth: 240,
                        content: 'Loading...',
                        zIndex: 6,
                        onShow(instance) {
                            $this.fetchContent(instance, map)                                
                            $this.handleSellPricing('#request-price')    
                        }
                    });
                })
            }
        },

        /**
         * Return the content for the tippy instance.
         * 
         * @return {string|null} The html content.
         */
        fetchContent(instance, map) {
            /** Declare mappable variable */
            let mappable
            /** Loop mappables array */
            mappable = _.filter(this.mappables, m => {
                /** Filter match mappable id */
                return m.id == map.mappable_id
            })
            /** Return first match’s content */
            if (mappable && mappable.length) {
                this.mappable = mappable[0]
                return instance.setContent(mappable[0].content)
            }
            /** No mappable available. */
            this.mappable = null
            return null 
        },

        /** Store Actions */
        ...mapActions(['fetchMappables'])
    },

}
</script>