<template>
    <svg
        :ref="iconName"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        :width="width"
        :height="height"
        :viewBox="viewBox"
        :aria-labelledby="type"
        role="presentation"
    >
        <component :dusk="iconName" :is="iconName" />   
    </svg>
</template>

<script>
    import store from '../../store'
    export default {
        /**
         * Interface
         */
        props: {
            type: {
                type: String,
                default: 'delete',
            },
            viewBox: {
                type: String,
                default: '0 0 20 20',
            },
            width: {
                type: [Number, String],
                default: 20,
            },
            height: {
                type: [Number, String],
                default: 20,
            },
            on: {
                type: String,
                required: false,
                validator: on => {
                    // Valid mouse event
                    let mouseEvents = [
                        'auxclick', 'click', 'contextmenu', 'dblclick', 'mousedown', 'mouseenter', 'mouseleave', 'mousemove', 'mouseover', 'mouseout', 'mouseup', 'pointerlockchange', 'pointerlockerror' , 'select', 'wheel'
                    ]
                    return mouseEvents.includes(on)
                }
            },
            action: {
                type: String,
                required: false,
                validator: action => {
                    // Action exists in store
                    return Object.keys(store._actions).includes(action)
                }
            },
            mutator: {
                type: String,
                required: false,
                validator: mutator => {
                    // Mutatotion exists in store
                    return Object.keys(store._mutations).includes(mutator)
                }
            },
            params: {
                type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
                required: false,
            }
        },
        /**
         * Local State
         */
        data() {
            return {
                store,
            }
        },
        computed: {
            /**
             * The icon name
             * 
             * @return String
             */
            iconName() {
                return `icon-${this.type}`
            },
        },

        /**
         * Lifecycle Events
         */
        mounted() {
            let icon = this.$refs[this.iconName]
            if (icon) {
                icon.parentElement.addEventListener(`${this.on}`, this.eventHandler)
                icon.addEventListener(`${this.on}`, this.eventHandler)
            }
        },
        destroyed() {
            let icon = this.$refs[this.iconName]
            if (icon) {
                icon.parentElement.removeEventListener(`${this.on}`, this.eventHandler)
                icon.removeEventListener(`${this.on}`, this.eventHandler)
            }
        },

        /**
         * Non-Reactive Properties
         */
        methods: {
            eventHandler() {
                if (this.mutator !== undefined) store.commit(`${this.mutator}`, this.params)
                if (this.action !== undefined) store.dispatch(`${this.action}`, this.params)
            }
        }
    }
</script>
